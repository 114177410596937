import React, { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from 'components/Chakra/Modal';

type LoginRedirectModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  signOut: () => Promise<void>;
};

export const LoginRedirectModal = (props: LoginRedirectModalProps) => {
  const { isOpen, onClose, signOut } = props;

  const [isLoading, setIsLoading] = useState(false);

  const toLogin = async () => {
    setIsLoading(true);

    await signOut();

    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Session Expired</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          <Text>{`Please log in again to continue using the editor's features.`}</Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={toLogin} isLoading={isLoading} colorScheme="blue" mr={3}>
            Log in
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
