import { ColorMode } from '@chakra-ui/react';
import * as go from 'gojs';
import {
  DynPorts,
  NodeDictionaryEntry,
  Port,
  PortType,
  PORT_TYPES,
} from 'types/transparency';
// eslint-disable-next-line import/no-cycle
import {
  createNodeTypeMap,
  getContentTypeFromTypedId,
  getTypeFromTypedId,
  isInPortType,
  isOutPortType,
  isTrigPortType,
  isWellDefined,
  joinType,
  mapType,
} from 'utils/transparency/types';
import { djb2color } from './color';
import { TRANSACTIONS } from './const';
// eslint-disable-next-line import/no-cycle
import { getModelFromDiagram } from './diagram';
// eslint-disable-next-line import/no-cycle
import { ARRAYED_PORT_TOOLTIP, makePortTooltip } from './tooltip';

const $ = go.GraphObject.make;

const getPortShape = (isParamOrArg: boolean, type: string) => {
  if (isParamOrArg) return 'Diamond';

  return isTrigPortType(type) ? 'Square' : 'Circle';
};

// this port uses no bindings.  it is used for ports on fully-defined basic nodes.
// consequently, none of its properties can change after it is created.
export const makePort = (paramId: string, isArg: boolean, colorMode: ColorMode) => {
  const type = getTypeFromTypedId(paramId);

  // # this cannot be W type, and we could forbid P as well
  console.assert(type.indexOf('?') < 0); // this is a concrete type

  const isInput = !isArg && isInPortType(type);
  const isOutput = !isArg && isOutPortType(type);
  const isParam = !isArg && !isInput && !isOutput;
  const isParamOrArg = isParam || isArg;

  const shape = getPortShape(isParamOrArg, type);

  let portType: PortType;
  if (isInput) {
    portType = 'inports';
  } else if (isOutput) {
    portType = 'outports';
  } else if (isParam) {
    portType = 'parports';
  } else {
    portType = 'argports';
  }

  const port = $(go.Shape, shape, {
    portId: paramId,
    fill: djb2color(type, isParamOrArg ? 0 : 1, colorMode),
    stroke: null,
    desiredSize: new go.Size(9, 9),
    cursor: 'pointer',
    toolTip: makePortTooltip(paramId, portType),
  });

  if (isParam) {
    port.toLinkable = true;
    port.toSpot = go.Spot.Top;
    port.alignment = go.Spot.TopCenter;
  } else if (isInput) {
    port.toLinkable = true;
    port.toSpot = go.Spot.Left;
    port.alignment = go.Spot.TopLeft;
  } else if (isOutput) {
    port.fromLinkable = true;
    port.fromSpot = go.Spot.Right;
    port.alignment = go.Spot.TopRight;
  } else {
    port.fromLinkable = true;
    port.fromSpot = go.Spot.Bottom;
    port.alignment = go.Spot.BottomCenter;
  }

  return port;
};

export const makePortArrays = (data: NodeDictionaryEntry, colorMode: ColorMode) => {
  const { params, argports } = data;

  const inPorts: go.Shape[] = [];
  const outPorts: go.Shape[] = [];
  const paramPorts: go.Shape[] = [];
  const argPorts: go.Shape[] = [];

  params.forEach((paramId) => {
    const type = getTypeFromTypedId(paramId);
    const isInPort = isInPortType(type);
    const isOutPort = isOutPortType(type);

    const port = makePort(paramId, false, colorMode);

    if (isInPort) inPorts.push(port);
    else if (isOutPort) outPorts.push(port);
    else paramPorts.push(port);
  });

  argports?.forEach((argport) => {
    // const type = getTypeFromTypedId(argport);
    // console.assert(!isPortType(type));
    argPorts.push(makePort(argport, true, colorMode));
  });

  return { inPorts, outPorts, paramPorts, argPorts };
};

// When a port is held in an itemArray, it must be a GoJS Part,
// so a bare Shape does not suffice in this case.

const makeInputArrayedPort = (colorMode: ColorMode) => {
  const shape = $(
    go.Shape,
    new go.Binding('fill', 'type', (type) => djb2color(type, 0, colorMode)),
    new go.Binding('figure', 'pid', (pid) =>
      isTrigPortType(getTypeFromTypedId(pid)) ? 'Square' : 'Circle'
    ),
    {
      desiredSize: new go.Size(9, 9),
      stroke: null,
    }
  );

  const port = $(
    go.Panel,
    new go.Binding('portId', 'pid'),
    {
      toolTip: ARRAYED_PORT_TOOLTIP,

      desiredSize: new go.Size(9, 9),
      cursor: 'pointer',

      toLinkable: true,
      toSpot: go.Spot.Left,
      alignment: go.Spot.TopLeft,
    },
    shape
  );

  return port;
};

const makeOutputArrayedPort = (colorMode: ColorMode) => {
  const shape = $(
    go.Shape,
    new go.Binding('fill', 'type', (type) => djb2color(type, 0, colorMode)),
    new go.Binding('figure', 'pid', (pid) =>
      isTrigPortType(getTypeFromTypedId(pid)) ? 'Square' : 'Circle'
    ),
    {
      desiredSize: new go.Size(9, 9),
      stroke: null,
    }
  );

  const port = $(
    go.Panel,
    new go.Binding('portId', 'pid'),
    {
      toolTip: ARRAYED_PORT_TOOLTIP,

      desiredSize: new go.Size(9, 9),
      cursor: 'pointer',

      fromLinkable: true,
      fromSpot: go.Spot.Right,
      alignment: go.Spot.TopRight,
    },
    shape
  );

  return port;
};

const makeParamArrayedPort = (colorMode: ColorMode) => {
  const shape = $(
    go.Shape,
    'Diamond',
    new go.Binding('fill', 'type', (type) => djb2color(type, 0, colorMode)),
    {
      desiredSize: new go.Size(9, 9),
      stroke: null,
    }
  );

  const port = $(
    go.Panel,
    new go.Binding('portId', 'pid'),
    {
      toolTip: ARRAYED_PORT_TOOLTIP,

      desiredSize: new go.Size(9, 9),
      cursor: 'pointer',

      toLinkable: true,
      toSpot: go.Spot.Top,
      alignment: go.Spot.TopCenter,
    },
    shape
  );

  return port;
};

const makeArgArrayedPort = (colorMode: ColorMode) => {
  const shape = $(
    go.Shape,
    'Diamond',
    new go.Binding('fill', 'type', (type) => djb2color(type, 0, colorMode)),
    {
      desiredSize: new go.Size(9, 9),
      stroke: null,
    }
  );

  const port = $(
    go.Panel,
    new go.Binding('portId', 'pid'),
    {
      toolTip: ARRAYED_PORT_TOOLTIP,

      desiredSize: new go.Size(9, 9),
      cursor: 'pointer',

      fromLinkable: true,
      fromSpot: go.Spot.Bottom,
      alignment: go.Spot.BottomCenter,
    },
    shape
  );

  return port;
};

export const INPUT_ARRAYED_PORTS = {
  light: makeInputArrayedPort('light'),
  dark: makeInputArrayedPort('dark'),
};
export const OUTPUT_ARRAYED_PORTS = {
  light: makeOutputArrayedPort('light'),
  dark: makeOutputArrayedPort('dark'),
};
export const PARAM_ARRAYED_PORTS = {
  light: makeParamArrayedPort('light'),
  dark: makeParamArrayedPort('dark'),
};
export const ARG_ARRAYED_PORTS = {
  light: makeArgArrayedPort('light'),
  dark: makeArgArrayedPort('dark'),
};

/**
 * ----------------------------------------------------------------------------
 * Add and remove ports using the params property and one element of the
 * dynports property for this special node category.
 * ----------------------------------------------------------------------------
 */

export const addPorts = (node: go.GraphObject, params: string[], dynObj: DynPorts) => {
  const diagram = node.part?.diagram;
  if (!diagram) return;
  const model = getModelFromDiagram(diagram);

  // Make node type map (type parameter -> context-free type)
  const { data: nodeData } = node as go.Node;
  const typeMap = createNodeTypeMap(nodeData);

  // Set indexBase to the max # of static ports, for each porttype
  // present in dynObj. This is the base value for ${i} within the
  // dynObj patterns. This value is a function of all the port types
  // in dynObj, for the reason that ${i} must be set to the same value
  // for all the ports we create below.
  let indexBase = 0;
  Object.keys(dynObj).forEach((portType) => {
    const minPorts = minPortsOfType(params, portType as PortType);
    if (minPorts > indexBase) {
      indexBase = minPorts;
    }
  });

  model.startTransaction(TRANSACTIONS.addPorts);

  let newIndex = -1;
  Object.keys(dynObj).forEach((portType) => {
    const idPat = dynObj[portType as PortType]!;
    const ports = nodeData[portType];

    // Number of ports of this type at present
    const numPorts = ports.length;

    // Minimum number of ports of this type
    const minPorts = minPortsOfType(params, portType as PortType);

    if (numPorts < minPorts) {
      // Ill-formed node - usually indicates that the template for the node
      // was updated but the model itself was not e.g. through a fault in
      // the version numbering
      throw new Error(
        `internal: bad node data for category ${nodeData.cat} [${nodeData.key}]`
      );
    }

    // Generate a value for ${i} in the id pattern from dynports.
    // This formula must give the same value for ${i} in all the
    // patterns we generate for this addPort operation.
    const index = indexBase + (numPorts - minPorts);
    // eslint-disable-next-line no-template-curly-in-string
    const name = idPat.replaceAll('${i}', index.toString());

    const type = mapType(typeMap, getContentTypeFromTypedId(name));
    const portData = { pid: name, type };

    model.insertArrayItem(ports, -1, portData);

    // Verify that we generate the same value for ${i} for every portType
    if (newIndex >= 0 && newIndex !== index)
      throw new Error('internal: bad port index in addPorts');

    // Record the value we assigned to ${i} in newIndex
    newIndex = index;
  });

  model.commitTransaction(TRANSACTIONS.addPorts);
};

export const deletePorts = (
  node: go.GraphObject | null,
  params: string[],
  dynObj: DynPorts
) => {
  const diagram = node?.part?.diagram;
  if (!diagram) return;

  const model = getModelFromDiagram(diagram);

  const { data: nodeData } = node as go.Node;

  model.startTransaction(TRANSACTIONS.deletePorts);

  Object.keys(dynObj).forEach((portType) => {
    const ports = nodeData[portType];
    const numPorts = ports.length;
    const minPorts = minPortsOfType(params, portType as PortType);

    if (numPorts > minPorts) {
      model.removeArrayItem(ports, numPorts - 1);
    }
  });

  model.commitTransaction(TRANSACTIONS.deletePorts);
};

const minPortsOfType = (params: string[], portType: PortType) => {
  let count = 0;

  params.forEach((param) => {
    const type = getTypeFromTypedId(param);

    if (isInPortType(type)) {
      if (portType === 'inports') count++;
    } else if (isOutPortType(type)) {
      if (portType === 'outports') count++;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (portType === 'parports') count++;
    }
  });

  return count;
};

/**
 * ----------------------------------------------------------------------------
 *
 * ----------------------------------------------------------------------------
 */

/**
 * Change a single portId/type pair among the ports of a node.
 * The existing type on the port is joined with `type` to form
 * the new type, unless the result is over-defined; in that case,
 * `type` itself is used to overwrite the previous type.
 */
export const changePortType = (
  model: go.Model,
  data: go.ObjectData,
  portId: string,
  type: string
) => {
  PORT_TYPES.forEach((ports) => {
    if (data[ports]) changePortTypeInPortArray(model, data[ports], portId, type);
  });
};

const changePortTypeInPortArray = (
  model: go.Model,
  ports: Port[],
  portId: string,
  type: string
) => {
  ports.forEach((port) => {
    if (port.pid === portId) {
      // Join to the existing port type
      const join = joinType(type, port.type);

      model.setDataProperty(port, 'type', isWellDefined(join) ? join : type);
    }
  });
};

export const applyTypeMap = (
  model: go.Model,
  data: go.ObjectData,
  typeMap: Record<string, string>
) => {
  PORT_TYPES.forEach((ports) => {
    if (data[ports]) applyTypeMapToPortArray(model, data[ports], typeMap);
  });
};

const applyTypeMapToPortArray = (
  model: go.Model,
  ports: Port[],
  typeMap: Record<string, string>
) => {
  ports.forEach((port) => {
    // Start with the type suffix of pid, and apply type map to it
    const mappedType = mapType(typeMap, getContentTypeFromTypedId(port.pid));

    // Join to the existing port type
    const join = joinType(mappedType, port.type);

    model.setDataProperty(port, 'type', isWellDefined(join) ? join : mappedType);
  });
};
