import axios from 'axios';
import { removeJWTs, storeJWTs } from 'services/TokenService';
import { getRegionalApiUrl } from 'utils/url';
import { getRequestHeaders } from '../utils';

type IdentityAction = 'signup' | 'login' | 'reset' | 'confirm';

type IdentityRequestData = {
  act: IdentityAction;
  username?: string;
  password?: string;
  password2?: string;
  // eslint-disable-next-line camelcase
  new_password?: string;
  // eslint-disable-next-line camelcase
  confirmation_code?: string;
};

export type LoginRequestData = Required<
  Pick<IdentityRequestData, 'username' | 'password'>
>;
export type RegisterRequestData = Required<
  Pick<IdentityRequestData, 'username' | 'password' | 'password2'>
>;
export type VerifyRequestData = Required<
  Pick<IdentityRequestData, 'username' | 'confirmation_code'>
>;
export type ResendRequestData = Required<Pick<IdentityRequestData, 'username'>>;
export type ResetPasswordRequestData = Required<Pick<IdentityRequestData, 'username'>>;
export type ConfirmResetRequestData = Required<
  Pick<IdentityRequestData, 'username' | 'new_password' | 'confirmation_code'>
>;

type SignUpResponse = {
  CodeDeliveryDetails: {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  };
  UserConfirmed: boolean;
  UserSub: string;
};

export const identityInstance = axios.create({
  method: 'post',
  withCredentials: true,
});

/**
 * @param loginData
 * @param region - explicit `region` argument since the region may not yet exist in localStorage
 */
export const logInRequest = async (loginData: LoginRequestData, region: string) => {
  loginData.password = encodeURIComponent(loginData.password);

  const response = await identityInstance.request<void>({
    url: `${getRegionalApiUrl('IDENTITY', region)}/login`,
    data: loginData,
    headers: getRequestHeaders('unprotected'),
  });
  storeJWTs({
    idToken: response.headers.id_token,
    accessToken: response.headers.access_token,
    refreshToken: response.headers.refresh_token,
  });
};

/**
 * @param registerData
 * @param region - explicit `region` argument since the region may not yet exist in localStorage
 * @returns
 */
export const signUpRequest = async (
  registerData: RegisterRequestData,
  region: string
) => {
  registerData.password = encodeURIComponent(registerData.password);
  registerData.password2 = encodeURIComponent(registerData.password2);

  const response = await identityInstance.request<SignUpResponse>({
    url: `${getRegionalApiUrl('IDENTITY', region)}/signup`,
    data: registerData,
    headers: getRequestHeaders('unprotected'),
  });
  return response.data;
};

export const verifyRequest = async (verifyData: VerifyRequestData, region: string) => {
  await identityInstance.request<void>({
    url: `${getRegionalApiUrl('IDENTITY', region)}/confirm`,
    data: verifyData,
    headers: getRequestHeaders('unprotected'),
  });
};

export const resendCodeRequest = async (
  resendData: ResendRequestData,
  region: string
) => {
  await identityInstance.request<void>({
    url: `${getRegionalApiUrl('IDENTITY', region)}/resend`,
    data: resendData,
    headers: getRequestHeaders('unprotected'),
  });
};

export const postLogout = async () => {
  await identityInstance
    .request<void>({
      url: '/logout',
      headers: getRequestHeaders('protected'),
    })
    .catch((error) => {
      // Even if the logout API call fails, the state of the editor will have been
      // updated (cleared tokens, reset isLoggedIn), so we should just catch this
      // error and log it.
      console.error(error);
    })
    .finally(() => {
      // Remove JWTs even if request failed.
      removeJWTs();
    });
};

export const resetPasswordRequest = async (
  resetPasswordData: ResetPasswordRequestData,
  region: string
) => {
  await identityInstance.request<void>({
    url: `${getRegionalApiUrl('IDENTITY', region)}/reset`,
    data: resetPasswordData,
    headers: getRequestHeaders('unprotected'),
  });
};

export const confirmResetRequest = async (
  confirmResetData: ConfirmResetRequestData,
  region: string
) => {
  confirmResetData.new_password = encodeURIComponent(confirmResetData.new_password);

  await identityInstance.request<void>({
    url: `${getRegionalApiUrl('IDENTITY', region)}/confirmreset`,
    data: confirmResetData,
    headers: getRequestHeaders('unprotected'),
  });
};
