import * as go from 'gojs';

export const port2callSitePID = (port: go.ObjectData) => {
  const { cat, name } = port;

  switch (cat) {
    case 'inlet':
    case 'outlet':
    case 'fioutlet':
    case 'chooseoutlet': {
      return `${name}$W?`;
    }

    case 'param':
    case 'return': {
      return `${name}$?`;
    }

    default: {
      throw new Error(`internal: bad port category ${cat}`);
    }
  }
};
