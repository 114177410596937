import * as go from 'gojs';

import { theme } from 'theme';
import { CallerMap, CallGraph } from 'types/project';
import { CIRCUIT_PANEL_CATEGORIES, CoreModel, Node } from 'types/transparency';
import { addCallerToCallGraph, deleteCallerFromCallGraph } from 'utils/callgraph';
import { isValidModel, copyModelData } from 'utils/model';
// eslint-disable-next-line import/no-cycle
import { syncPull, syncPush } from 'utils/sync';

import { TRANSACTIONS } from './const';
import { getActiveModel } from './dom';
// eslint-disable-next-line import/no-cycle
import {
  canLinkPorts,
  linkedOrRelinkedListener,
  linkTemplateCategory,
  LINK_TEMPLATE_MAP,
} from './link';
// eslint-disable-next-line import/no-cycle
import { getLastInputPointAndAddOffset } from './node';
// eslint-disable-next-line import/no-cycle
import { addTemplateLazily, getBaseNodeTemplateMap } from './template-map';
// eslint-disable-next-line import/no-cycle
import { CONTEXT_MENU, makeContextMenu } from './templates';

const $ = go.GraphObject.make;

const makeBaseDiagramModel = () => {
  const model = $(go.GraphLinksModel, {
    nodeCategoryProperty: 'cat',
    linkFromPortIdProperty: 'src',
    linkToPortIdProperty: 'dst',
    linkKeyProperty: 'key',
    copyNodeDataFunction: copyModelData,
    linkCategoryProperty: (data: go.ObjectData, cat?: string) => {
      if (cat) {
        // console.log(`linkCategoryProperty ${JSON.stringify(data, null, 2)} ${cat}`);

        const activeModel = getActiveModel();

        if (!activeModel)
          throw new Error(
            'internal: linkCategoryProperty called when there is no diagram'
          );

        // data.temp is used as an addition parameter to this function,
        // to deliver the new value of data.depth or data.type.
        // see bindEdgeType() and bindEdgeDepth().

        if (typeof data.temp === 'number')
          activeModel.setDataProperty(data, 'depth', data.temp);
        else if (typeof data.temp === 'string')
          activeModel.setDataProperty(data, 'type', data.temp);

        if (linkTemplateCategory(data) !== cat)
          throw new Error('internal: bad linkTemplateCategory');
      }
      return linkTemplateCategory(data);
    },
  });

  model.undoManager.isEnabled = true;

  return model;
};

/**
 * Builds a GoJS GraphLinksModel from the Coreograph model.
 * the Coreograph model is not copied, whether by shallow
 * or deep copy.  In present usage, the CoreModel is created
 * by JSON.parse of a project in text form, loaded from local
 * or indexed storage, or received from one of our APIs.  it
 * is therefore already a fresh copy, as it were.
 */
export const coreModelToDiagramModel = (coreModel: CoreModel) => {
  if (!isValidModel(coreModel)) throw new Error('Invalid model');

  const { name, type, meta, nodes, edges, nextNodeKey, nextEdgeKey } = coreModel;

  const diagramModel = makeBaseDiagramModel();
  const { modelData } = diagramModel;

  // when the GoJS react component was being used, the editor copied
  // these arrays using slice().  that is unnecessary now that the
  // editor operates directly on the model data to effect changes to
  // the GoJS diagrams.
  diagramModel.nodeDataArray = nodes || [];
  diagramModel.linkDataArray = edges || [];

  modelData.name = name;
  modelData.type = type;

  if (meta) {
    modelData.meta = meta;

    const { threads } = meta;

    // a circuit may, but is not required to, have a threads property
    // in its meta object.  if undefined, leave it undefined.
    if (type === 'circuit' && typeof threads === 'number') {
      modelData.meta.threads = threads;
    }
  }

  modelData.nextNodeKey =
    nextNodeKey ||
    Math.max(...(coreModel.nodes?.length ? coreModel.nodes.map(({ key }) => key) : [0]));

  modelData.nextEdgeKey =
    nextEdgeKey ||
    Math.max(...(coreModel.edges?.length ? coreModel.edges.map(({ key }) => key) : [0]));

  syncPush(diagramModel);

  return diagramModel;
};

/*
 * extracts a model representation from a diagram's model data.
 * the model data is deep copied so that alterations to the
 * extracted model representation do not affect the diagram.
 * note that after this deep copy, it is not necessary to further
 * shallow- or deep-copy portions of the core model; it is already
 * a freestanding deep copy, not entangled with other state.
 */

export const diagramModelToCoreModel = (diagramModel: go.GraphLinksModel) => {
  // Make deep copy
  const {
    modelData,
    nodeDataArray: nodes,
    linkDataArray: edges,
  } = JSON.parse(diagramModel.toJson());

  const { name, type, meta, nextNodeKey, nextEdgeKey } = modelData;

  const coreModel: CoreModel = {
    name,
    type,
    nodes,
    edges,
    ...(meta != null && {
      meta: {
        ...meta,

        // if meta.threads is undefined (not a number), it should
        // be left undefined; otherwise it should be present.
        ...(['circuit'].includes(type) && {
          threads: typeof meta.threads === 'number' ? meta.threads : undefined,
        }),
      },
    }),
    nextNodeKey,
    nextEdgeKey,
  };

  if (!isValidModel(coreModel)) throw new Error('Extracted core model is invalid');
  return coreModel;
};

export const getModelFromDiagram = (diagram: go.Diagram): go.GraphLinksModel =>
  diagram.model as go.GraphLinksModel;

export const makeDiagram = (
  diagramModel: go.GraphLinksModel,
  setActivePanel: (name: string) => void,
  isReadOnly: boolean = false
) => {
  const diagram = $(go.Diagram, {
    initialContentAlignment: go.Spot.Center,
    'grid.gridCellSize': new go.Size(1, 1), // causes loc coords to be rounded to int
    'draggingTool.isGridSnapEnabled': true,
    'resizingTool.isGridSnapEnabled': true,
    'undoManager.isEnabled': true,
    'toolManager.hoverDelay': 200,
    isReadOnly,
    scrollMargin: 400,
  });
  const baseNodeTemplateMap = getBaseNodeTemplateMap(
    theme.config.initialColorMode,
    setActivePanel
  );

  // add any missing basic node categories to the node template maps
  const nodes = diagramModel.nodeDataArray as Node[] | undefined;
  nodes?.forEach(({ cat }) => {
    addTemplateLazily(cat);
  });

  diagram.nodeTemplateMap = baseNodeTemplateMap;
  diagram.linkTemplateMap = LINK_TEMPLATE_MAP;

  // Add positive key generation for nodes and links
  diagramModel.makeUniqueKeyFunction = (model, data) => {
    if (data.key === undefined || model.findNodeDataForKey(data.key) !== null) {
      let key = diagramModel.modelData.nextNodeKey;
      while (model.findNodeDataForKey(key) !== null || key < 1) key++;
      model.modelData.nextNodeKey = key + 1;
      return key;
    }
  };
  diagramModel.makeUniqueLinkKeyFunction = (model, data) => {
    if (data.key === undefined || model.findLinkDataForKey(data.key) !== null) {
      let key = diagramModel.modelData.nextEdgeKey;
      while (model.findLinkDataForKey(key) !== null || key < 1) key++;
      model.modelData.nextEdgeKey = key + 1;
      return key;
    }
  };

  diagram.model = diagramModel;
  // Add diagram context menu
  diagram.contextMenu = makeContextMenu();

  const { textEditingTool, contextMenuTool, linkingTool, relinkingTool } =
    diagram.toolManager;

  linkingTool.linkValidation = canLinkPorts;
  relinkingTool.linkValidation = canLinkPorts;

  // Set default text editor's color
  if (textEditingTool.defaultTextEditor.mainElement) {
    textEditingTool.defaultTextEditor.mainElement.style.color = '#3c3c3c';
    (textEditingTool.defaultTextEditor.mainElement as HTMLTextAreaElement).rows = 1;
  }

  // Override paste behavior to add offset
  // https://gojs.net/latest/intro/extensions.html#CommandHandler
  const { commandHandler } = diagram;
  commandHandler.pasteSelection = (pos?: go.Point) => {
    const position = pos ?? getLastInputPointAndAddOffset(commandHandler.diagram);
    go.CommandHandler.prototype.pasteSelection.call(commandHandler, position);
  };

  // Override context menu
  contextMenuTool.showContextMenu = (
    contextMenu: go.Adornment,
    obj: go.GraphObject | null
  ) => {
    const canCopy = commandHandler.canCopySelection();
    const canCut = commandHandler.canCutSelection();
    const canPaste = commandHandler.canPasteSelection();
    const canDelete = commandHandler.canDeleteSelection();

    const { model } = diagram;
    const { skipsUndoManager, modelData } = model;

    model.skipsUndoManager = true;
    model.startTransaction();
    // CONTEXT_MENU ids are used to point to "visible" property in the context menu template
    model.setDataProperty(modelData, CONTEXT_MENU.copy.id, canCopy);
    model.setDataProperty(modelData, CONTEXT_MENU.cut.id, canCut);
    model.setDataProperty(modelData, CONTEXT_MENU.paste.id, canPaste);
    model.setDataProperty(modelData, CONTEXT_MENU.delete.id, canDelete);
    model.commitTransaction();
    model.skipsUndoManager = skipsUndoManager;

    go.ContextMenuTool.prototype.showContextMenu.call(contextMenuTool, contextMenu, obj);
  };

  // Override linking tool to close any open tooltips when dragging starts
  linkingTool.doActivate = () => {
    diagram.toolManager.hideToolTip();
    go.LinkingTool.prototype.doActivate.call(linkingTool);
  };
  // Override relinking tool to close any open tooltips when dragging starts
  relinkingTool.doActivate = () => {
    diagram.toolManager.hideToolTip();
    go.RelinkingTool.prototype.doActivate.call(relinkingTool);
  };

  diagram.addDiagramListener('LinkDrawn', linkedOrRelinkedListener);
  diagram.addDiagramListener('LinkRelinked', linkedOrRelinkedListener);

  return diagram;
};

/**
 * ----------------------------------------------------------------------------
 * Helpers for the diagram model change listener
 * ----------------------------------------------------------------------------
 */
const updateCallsAfterNodeChange = (
  callGraph: CallGraph,
  callerMap: CallerMap,
  data: go.ObjectData,
  model: go.Model,
  callerName: string,
  shouldAddCaller: boolean,
  notifyCallgraphChange: () => void
) => {
  const { cat, key, name: calleeName } = data;

  if (!CIRCUIT_PANEL_CATEGORIES.includes(cat)) return;

  if (shouldAddCaller) {
    addCallerToCallGraph(callGraph, callerMap, callerName, key, calleeName);
    model.startTransaction(TRANSACTIONS.syncNode);
    syncPull(data, model, calleeName);
    model.commitTransaction(TRANSACTIONS.syncNode);
  } else {
    deleteCallerFromCallGraph(callGraph, callerMap, callerName, key, calleeName);
  }
  notifyCallgraphChange();
};

const selectDataInDiagram = (data: go.ObjectData, diagram: go.Diagram | null) => {
  if (diagram) diagram.select(diagram.findNodeForData(data));
};

export const modelChangeEventHandler = (
  event: go.ChangedEvent,
  callGraph: CallGraph,
  callerMap: CallerMap,
  isUndo: boolean,
  diagram: go.Diagram | null,
  notifyCallgraphChange: () => void
) => {
  const { change, propertyName, model } = event;
  if (!model) return;

  const callerName = model.modelData.name;

  if (change === go.ChangedEvent.Insert && propertyName === 'nodeDataArray') {
    const data = event.newValue;

    updateCallsAfterNodeChange(
      callGraph,
      callerMap,
      data,
      model,
      callerName,
      !isUndo,
      notifyCallgraphChange
    );

    if (!isUndo) {
      selectDataInDiagram(data, diagram);
    }
  } else if (change === go.ChangedEvent.Remove && propertyName === 'nodeDataArray') {
    const data = event.oldValue;

    updateCallsAfterNodeChange(
      callGraph,
      callerMap,
      data,
      model,
      callerName,
      isUndo,
      notifyCallgraphChange
    );

    if (isUndo) {
      selectDataInDiagram(data, diagram);
    }
  } else if (change === go.ChangedEvent.Property && propertyName === 'name') {
    const data = event.object;
    if (!data) return;

    const { cat, key } = data;

    if (!CIRCUIT_PANEL_CATEGORIES.includes(cat)) return;

    const oldName = isUndo ? event.newValue : event.oldValue;
    const newName = isUndo ? event.oldValue : event.newValue;

    if (oldName) {
      deleteCallerFromCallGraph(callGraph, callerMap, callerName, key, oldName);
    }
    if (newName) {
      addCallerToCallGraph(callGraph, callerMap, callerName, key, newName);
    }

    model.startTransaction(TRANSACTIONS.syncNode);
    syncPull(data, model, newName);
    model.commitTransaction(TRANSACTIONS.syncNode);

    notifyCallgraphChange();
  }
};
