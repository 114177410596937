import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useUserContext } from 'context/useUserContext';
import {
  cancelSubscription,
  createSubscription,
  getPrices,
  getSubscription,
  updateSubscription,
} from 'services/api/Payment';
import { onRequestError } from 'services/api/utils';

export const QUERY_KEYS = {
  PRICES: 'prices',
  SUBSCRIPTION: 'subscription',
} as const;

export const useSubscriptionQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIPTION],
    queryFn: getSubscription,
    retry: 3,
  });
};

export const useCreateSubscriptionMutation = () => {
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: createSubscription,
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const useUpdateSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: updateSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.SUBSCRIPTION]);
    },
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const useCancelSubscriptionMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      // Add 1s delay to wait for Stripe webhook
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.SUBSCRIPTION]);
      }, 1000);
    },
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const usePricesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.PRICES],
    queryFn: getPrices,
  });
};
