import { useMediaQuery } from '@chakra-ui/react';

export const useScreenSize = () => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [isMediumScreen] = useMediaQuery('(min-width: 48em)');

  return {
    isMobile,
    isMediumScreen,
  };
};
