import { awsCoreographRegionSchema, awsRegionSchema } from 'utils/region';
import { z } from 'zod';
import { createAxiosInstance } from '../utils';

/** Axios instance */
export const connectedModeAxiosInstance = createAxiosInstance({
  isProtected: true,
});

export const STACK_STATUS_ERROR = [
  'CREATE_FAILED',
  'DELETE_FAILED',
  'ROLLBACK_COMPLETE',
  'ROLLBACK_FAILED',
  'UPDATE_FAILED',
  'UPDATE_ROLLBACK_COMPLETE',
] as const;

export const STACK_STATUS_PROGRESS = [
  'CREATE_IN_PROGRESS',
  'DELETE_IN_PROGRESS',
  'ROLLBACK_IN_PROGRESS',
  'REVIEW_IN_PROGRESS',
  'UPDATE_COMPLETE_CLEANUP_IN_PROGRESS',
  'UPDATE_IN_PROGRESS',
  'UPDATE_ROLLBACK_IN_PROGRESS',
  'UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS',
] as const;

export const STACK_STATUS_SUCCESS = [
  'CREATE_COMPLETE',
  'DELETE_COMPLETE',
  'UPDATE_COMPLETE',
] as const;

const stackStatusSchema = z.enum([
  ...STACK_STATUS_ERROR,
  ...STACK_STATUS_PROGRESS,
  ...STACK_STATUS_SUCCESS,
]);
export type StackStatus = z.infer<typeof stackStatusSchema>;

const registerConnectedModePayloadSchema = z.object({
  accountId: z.string(),
  // coreograph backend is deployed in these regions
  region: awsCoreographRegionSchema,
});

type RegisterConnectedModePayload = z.infer<typeof registerConnectedModePayloadSchema>;

/**
 * @param data
 * @returns CloudFormation quick stack creation link for account registration
 */
export const registerConnectedMode = async (data: RegisterConnectedModePayload) => {
  const { data: dataResponse } = await connectedModeAxiosInstance.request({
    data,
    params: { act: 'register' },
  });

  const registerLink = z.string().parse(dataResponse);
  return {
    accountId: data.accountId,
    registerLink,
  };
};

const getConnectedModeDataResponseSchema = z.array(
  z.object({
    sub: z.string(),
    accountId: z.string(),
    stackArns: z.array(z.string()),
    iamArn: z.string(),
    verified: z.enum(['TRUE', 'FALSE']),
    templateHash: z.string(),
    deployBucket: z.string(),
  })
);
export type GetConnectedModeDataResponse = z.infer<
  typeof getConnectedModeDataResponseSchema
>;

export const getConnectedModeData = async () => {
  const { data } = await connectedModeAxiosInstance.request({
    params: { act: 'liststacks' },
  });
  return getConnectedModeDataResponseSchema.parse(data);
};

export const cancelConnectedModeRegistration = async (accountId: string) => {
  await connectedModeAxiosInstance.request({
    data: accountId,
    params: { act: 'cancelregister' },
  });
};

const validateStackResponseSchema = z
  .object({
    Capabilities: z.array(z.string()),
    CapabilitiesReason: z.string(),
    DeclaredTransforms: z.array(z.string()),
    Description: z.string(),
    Parameters: z.array(
      z.object({
        DefaultValue: z.string(),
        Description: z.string(),
        NoEcho: z.boolean(),
        ParameterKey: z.string(),
      })
    ),
  })
  .transform(
    ({
      Capabilities,
      CapabilitiesReason,
      DeclaredTransforms,
      Description,
      Parameters,
    }) => ({
      capabilities: Capabilities,
      capabilitiesReason: CapabilitiesReason,
      declaredTransforms: DeclaredTransforms,
      description: Description,
      parameters: Parameters.map(
        ({ DefaultValue, Description: parameterDescription, NoEcho, ParameterKey }) => ({
          defaultValue: DefaultValue,
          description: parameterDescription,
          noEcho: NoEcho,
          parameterKey: ParameterKey,
        })
      ),
    })
  );

export type ValidateStackResponse = z.infer<typeof validateStackResponseSchema>;

export const validateTemplateStack = async (data: {
  deployPackageURL: string;
  accountId: string;
}) => {
  const { data: responseData } = await connectedModeAxiosInstance.request({
    data,
    params: { act: 'validatestack' },
  });
  return validateStackResponseSchema.parse(responseData);
};

const stackParameterSchema = z.object({
  ParameterKey: z.string(),
  ParameterValue: z.string(),
});

export type StackParameter = z.infer<typeof stackParameterSchema>;

const createStackPayloadSchema = z.object({
  deployPackageURL: z.string(),
  stackName: z.string(),
  accountId: z.string(),
  region: awsRegionSchema,
  parameters: z.array(stackParameterSchema),
});

type CreateStackPayload = z.infer<typeof createStackPayloadSchema>;

/**
 * @param data
 * @returns stackArn of the newly created stack
 */
export const createStack = async (data: CreateStackPayload) => {
  const { data: responseData } = await connectedModeAxiosInstance.request({
    data,
    params: { act: 'createstack' },
  });
  return z.string().parse(responseData);
};

type UpdateStackPayload = CreateStackPayload;

/**
 * @param data
 * @returns stackArn of the newly created stack
 */
export const updateStack = async (data: UpdateStackPayload) => {
  const { data: responseData } = await connectedModeAxiosInstance.request({
    data,
    params: { act: 'updatestack' },
  });
  return z.string().parse(responseData);
};

const getStacksDataPayloadSchema = z.object({
  accountId: z.string(),
  stackArns: z.array(z.string()),
});

export type GetStacksDataPayload = z.infer<typeof getStacksDataPayloadSchema>;

const getStacksDataResponseSchema = z.array(
  z.object({
    Capabilities: z.array(z.string()),
    ChangeSetId: z.string(),
    CreationTime: z.string(),
    DeletionTime: z.string(),
    Description: z.string(),
    DisableRollback: z.boolean(),
    DriftInformation: z.object({
      LastCheckTimestamp: z.string(),
      StackDriftStatus: z.string(),
    }),
    EnableTerminationProtection: z.boolean(),
    LastUpdatedTime: z.string(),
    NotificationARNs: z.array(z.string()),
    Outputs: z.array(
      z.object({
        Description: z.string(),
        OutputKey: z.string(),
        OutputValue: z.string(),
        ExportName: z.string(),
      })
    ),
    Parameters: z.array(
      z.object({
        ParameterKey: z.string(),
        ParameterValue: z.string(),
        ResolvedValue: z.string(),
        UsePreviousValue: z.boolean(),
      })
    ),
    ParentId: z.string(),
    RoleARN: z.string(),
    RollbackConfiguration: z.object({
      MonitoringTimeInMinutes: z.number(),
      RollbackTriggers: z.array(z.unknown()),
    }),
    RootId: z.string(),
    StackId: z.string(),
    StackName: z.string(),
    StackStatus: stackStatusSchema,
    StackStatusReason: z.string(),
    Tags: z.array(z.object({ Key: z.string(), Value: z.string() })),
    TimeoutInMinutes: z.number(),
  })
);

export const getStacksData = async (data: GetStacksDataPayload) => {
  const { data: responseData } = await connectedModeAxiosInstance.request({
    data,
    params: { act: 'getstacksdata' },
  });
  return getStacksDataResponseSchema.parse(responseData);
};

const deleteStackPayloadSchema = z.object({
  accountId: z.string(),
  stackArn: z.string(),
});

type DeleteStackPayload = z.infer<typeof deleteStackPayloadSchema>;

export const deleteStack = async (data: DeleteStackPayload) => {
  const { data: responseData } = await connectedModeAxiosInstance.request({
    data,
    params: { act: 'deletestack' },
  });
  return responseData;
};
