import React, { ReactNode } from 'react';
import { Text, Link, AlertDescription, AlertTitle, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useSubscriptionQuery } from 'hooks/usePaymentApi';

import { CenterSpinner } from 'components/CenterSpinner';
import { DashboardPageCard } from 'components/DashboardPageCard';
import { AlertWrapper } from 'components/Chakra/AlertWrapper';

type SubscriptionPlanInterceptorProps = {
  children: ReactNode;
};

export const SubscriptionPlanInterceptor = (props: SubscriptionPlanInterceptorProps) => {
  const { children } = props;
  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
  } = useSubscriptionQuery();
  const isConnectedMode = subscriptionData?.subscription_tier === 'Connected';

  if (isSubscriptionLoading)
    return (
      <DashboardPageCard heading="AWS Connections" colSpan={2}>
        <CenterSpinner>
          <Text>Getting plan details</Text>
        </CenterSpinner>
      </DashboardPageCard>
    );

  if (isSubscriptionError)
    return (
      <DashboardPageCard heading="AWS Connections" colSpan={2}>
        <AlertWrapper>
          <AlertDescription>
            Unable to get plan details, please try again later
          </AlertDescription>
        </AlertWrapper>
      </DashboardPageCard>
    );

  if (!isConnectedMode)
    return (
      <DashboardPageCard heading="AWS Connections" colSpan={2}>
        <AlertWrapper status="warning">
          <Box>
            <AlertTitle>Invalid plan</AlertTitle>
            <AlertDescription>
              Enable automated deployments by{' '}
              <Link as={RouterLink} to="/plan" color="teal">
                upgrading to the Connected plan
              </Link>
            </AlertDescription>
          </Box>
        </AlertWrapper>
      </DashboardPageCard>
    );

  return <>{children}</>;
};
