export const LOG_VARIANTS = {
  log: {
    cat: 'log',
    type: 'node',
  },
  logOneLiner: {
    cat: 'logOneLiner',
    type: 'node',
  },
  logJSON: {
    cat: 'logJSON',
    type: 'node',
  },
  trace: {
    cat: 'trace',
    identifier: '=TRACE=',
    type: 'node',
  },
  profile: {
    identifier: '=PROFILE=',
    type: 'node',
  },
  probe: {
    identifier: '=PROBE=',
    type: 'link',
  },
  probeLimit: {
    identifier: '=PROBELIMIT=',
    type: 'link',
  },
} as const;

export type LogType = typeof LOG_VARIANTS[keyof typeof LOG_VARIANTS]['type'];

export type QueryRecord = {
  type: LogType;
  panel: string;
  goKey: string;
};

export type LogData = {
  logs: string[];
  count: number;
};

// format of panel => key => logData
export type LogRecord = Map<string, Map<string, LogData>>;
