export const Button = {
  variants: {
    circle: ({ colorMode }: { colorMode: string }) => {
      const isDarkMode = colorMode === 'dark';

      return {
        backgroundColor: isDarkMode ? 'whiteAlpha.200' : 'white',
        color: isDarkMode ? 'whiteAlpha.700' : 'gray.500',
        borderRadius: '50%',
        _hover: {
          backgroundColor: isDarkMode ? 'whiteAlpha.400' : 'gray.100',
        },
      };
    },
  },
};
