/**
 * Utility functions that interact directly with the DOM.
 * @module gojs/dom
 */
import * as go from 'gojs';
import { isDiagramModel } from 'utils/project';

export const DIAGRAM_DIV_ID = 'diagram-component';

/**
 * ----------------------------------------------------------------------------
 * Active diagram
 * ----------------------------------------------------------------------------
 */
/**
 * @returns The div of the current active diagram or undefined (when a text panel is active)
 */
const getActiveDiagramDiv = () => {
  return document.getElementById(DIAGRAM_DIV_ID) ?? undefined;
};

export const getActiveDiagram = () => {
  const activeDiagramDiv = getActiveDiagramDiv();
  return activeDiagramDiv ? go.Diagram.fromDiv(activeDiagramDiv) ?? undefined : undefined;
};

export const getActiveModel = () => {
  const model = getActiveDiagram()?.model;
  if (isDiagramModel(model)) return model;
  return undefined;
};

export const getActiveCanvas = () => {
  const activeDiagramDiv = getActiveDiagramDiv();
  const canvas = activeDiagramDiv
    ? activeDiagramDiv.querySelector('div canvas')
    : undefined;

  return canvas instanceof HTMLCanvasElement ? canvas : undefined;
};
