import * as go from 'gojs';
import { getLinkStroke, linkTemplateCategory } from 'services/GoJS';
import { z } from 'zod';
import { QueryRecord, LogRecord, LogData } from './types';

const logSchema = z
  .object({
    id: z.string(),
    timestamp: z.number(),
    message: z.string(),
  })
  .transform(({ id, timestamp, message }) => ({
    id,
    timeStamp: new Date(timestamp).toLocaleTimeString(),
    message,
  }));

export type Log = z.infer<typeof logSchema>;

export const transformLogEvent = (val: any) => {
  try {
    const json = JSON.parse(val);
    return z.array(logSchema).parse(json);
  } catch (error) {
    return [];
  }
};

// this mutates the original record
export const getLogData = (
  { panel, goKey }: Omit<QueryRecord, 'type'>,
  record: LogRecord
) => {
  const panelMap =
    record.get(panel) ?? record.set(panel, new Map<string, LogData>()).get(panel)!;
  return { logData: panelMap.get(goKey) ?? { logs: [], count: 0 }, panelMap };
};

export const addLinkAnimation = (
  animation: go.Animation,
  activeDiagram: go.Diagram,
  goKey: string
) => {
  const link = activeDiagram.findLinkForKey(goKey);
  if (!link) return;

  const linkCat = linkTemplateCategory(link.data);
  const originalStroke = getLinkStroke(linkCat);

  animation.add(link.elt(0), 'stroke', originalStroke, '#98FB98');
};
