import { z } from 'zod';

import { createAxiosInstance } from '../utils';

/** Types/schemas */
export const PLAN_TYPES = ['Free', 'Disconnected', 'Connected'] as const;
export const planTypesSchema = z.enum(PLAN_TYPES);
export type PlanType = z.infer<typeof planTypesSchema>;

const productSchema = z.object({
  id: z.string(),
  name: planTypesSchema,
});

export const priceSchema = z.object({
  currency: z.string(),
  id: z.string(),
  lookup_key: z.string(),
  product: productSchema,
  recurring: z.object({
    interval: z.string(),
    interval_count: z.number(),
  }),
  unit_amount: z.number(),
});
export type Price = z.infer<typeof priceSchema>;

const subscriptionSchema = z.object({
  id: z.string(),
  items: z.object({
    data: z.array(
      z.object({
        id: z.string(),
        price: priceSchema.pick({ id: true, product: true }),
      })
    ),
  }),
  status: z.string(),
});

/** Axios instance */
export const paymentAxiosInstance = createAxiosInstance({ isProtected: true });

const getSubscriptionResponseSchema = z.object({
  subscription_tier: planTypesSchema,
  subscription: subscriptionSchema,
});
export type GetSubscriptionResponse = z.infer<typeof getSubscriptionResponseSchema>;

export const getPlanNameFromSubscriptionResponse = (
  subscriptionResponse: GetSubscriptionResponse
) => {
  const { subscription } = subscriptionResponse;

  return (
    subscription.items.data[0]?.price.product.name ??
    subscriptionResponse.subscription_tier ??
    'Free'
  );
};

export const getSubscription = async () => {
  const { data } = await paymentAxiosInstance.request({
    params: { act: 'getsub' },
  });

  return getSubscriptionResponseSchema.parse(data);
};

const createSubscriptionResponseSchema = z.string();

export const createSubscription = async (priceId: string) => {
  const { data } = await paymentAxiosInstance.request({
    params: { act: 'createsub' },
    data: { priceId },
  });

  return createSubscriptionResponseSchema.parse(data);
};

export const updateSubscription = async (priceId: string) => {
  const { data } = await paymentAxiosInstance.request({
    params: { act: 'updatesub' },
    data: { priceId },
  });

  return data;
};

export const cancelSubscription = async () => {
  const { data } = await paymentAxiosInstance.request({
    params: { act: 'cancelsub' },
  });

  return data;
};

const pricesResponseSchema = z.object({
  data: z.array(priceSchema),
});

export const getPrices = async () => {
  const { data } = await paymentAxiosInstance.request({
    params: { act: 'getprices' },
    data: {
      lookupKeys: [
        'disconnected_monthly',
        // 'disconnected_annual',
        'connected_monthly',
        // 'connected_annual',
      ],
    },
  });

  return pricesResponseSchema.parse(data).data;
};
