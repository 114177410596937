import { Project, ProjectText, StorageProject } from 'types/project';
import { NodeDictionary, ProjectError, TypeDictionary } from 'types/transparency';
import { createAxiosInstance, getRequestHeaders } from '../utils';

export type TranspilerAction =
  | 'typeinf'
  | 'update'
  | 'transpile'
  | 'compile'
  | 'execute'
  | 'ingest'
  | 'debug';

// Update/typeinf action response
export type TranspileTypeinfResponse = {
  project?: StorageProject;
  errors?: ProjectError;
  warnings?: ProjectError;
  notices?: ProjectError;
};

// Transpile/compile action response
export type TranspileDownloadResponse = {
  URL: string;
  errors?: ProjectError;
  warnings?: ProjectError;
  notices?: ProjectError;
};

// Run action response
export type TranspileExecuteResponse = {
  executeOutput: string[];
  executeMessage: string;
  errors?: ProjectError;
  warnings?: ProjectError;
  notices?: ProjectError;
};

// Ingest action response
export type TranspileIngestResponse = {
  nodeDictionary?: NodeDictionary;
  typeDictionary?: TypeDictionary;
  stderr?: string;
};

export type TranspileResponse =
  | TranspileTypeinfResponse
  | TranspileDownloadResponse
  | TranspileExecuteResponse
  | TranspileIngestResponse;

export const transpilerInstance = createAxiosInstance({ isProtected: true });

export const postTranspile = async (
  action: TranspilerAction,
  project: StorageProject | ProjectText
) => {
  const { data } = await transpilerInstance.request<TranspileResponse>({
    params: { act: action, ver: '2' },
    headers: getRequestHeaders('protected'),
    data: project,
  });

  return data;
};

export type TranspileGenerateResponse = {
  project?: StorageProject;
  errors?: ProjectError;
};

export const postTranspileGenerate = async (
  project: Project,
  params: Record<string, string>,
  oldParams?: Record<string, string>
) => {
  const { data } = await transpilerInstance.request<TranspileGenerateResponse>({
    params: { act: 'generate' },
    headers: getRequestHeaders('protected'),
    data: { project, params, oldParams },
  });

  return data;
};

/**
 * ----------------------------------------------------------------------------
 * Type guards
 * ----------------------------------------------------------------------------
 */

export const hasTranspileMessages = (
  response: TranspileResponse
): response is
  | TranspileTypeinfResponse
  | TranspileDownloadResponse
  | TranspileExecuteResponse => {
  const castedResponse = response as TranspileDownloadResponse;
  return (
    castedResponse.errors !== undefined ||
    castedResponse.notices !== undefined ||
    castedResponse.warnings !== undefined
  );
};

export const hasDownloadUrl = (
  response?: TranspileResponse
): response is TranspileDownloadResponse => {
  return (
    response !== undefined && (response as TranspileDownloadResponse).URL !== undefined
  );
};

export const hasExecuteOutput = (
  response?: TranspileResponse
): response is TranspileExecuteResponse => {
  if (!response) return false;

  const { executeOutput, executeMessage } = response as TranspileExecuteResponse;
  return executeOutput !== undefined || executeMessage !== undefined;
};

export const hasUpdatedProject = (
  response?: TranspileResponse
): response is TranspileTypeinfResponse => {
  return (
    response !== undefined && (response as TranspileTypeinfResponse).project !== undefined
  );
};
