import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { Alert, AlertIcon, AlertProps } from '@chakra-ui/react';

type AlertWrapperProps = {
  children: ReactNode;
} & AlertProps;

export const AlertWrapper = forwardRef(
  (props: AlertWrapperProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...alertProps } = props;

    return (
      <Alert status="error" variant="left-accent" ref={ref} {...alertProps}>
        <AlertIcon />
        {children}
      </Alert>
    );
  }
);
