import { StorageProject } from 'types/project';
import { createAxiosInstance, getRequestHeaders } from '../utils';

export const cloudStorageInstance = createAxiosInstance({ isProtected: true });

export const cloudStorageGet = async (name: string) => {
  const params = { act: 'get', obj: name };
  const response = await cloudStorageInstance.request<StorageProject>({
    params,
    headers: getRequestHeaders('protected'),
  });
  return response.data;
};

export const cloudStoragePut = async (name: string, project: StorageProject) => {
  const params = { act: 'put', obj: name };
  const data = JSON.stringify(project);
  await cloudStorageInstance.request<void>({
    params,
    data,
    headers: getRequestHeaders('protected'),
  });
};

export const cloudStoragePutFolder = async (name: string) => {
  const params = { act: 'put', obj: name };
  await cloudStorageInstance.request<void>({
    params,
    headers: getRequestHeaders('protected'),
  });
};

export const cloudStorageDelete = async (name: string) => {
  const params = { act: 'del', obj: name };
  await cloudStorageInstance.request<void>({
    params,
    headers: getRequestHeaders('protected'),
  });
};

export const cloudStorageMove = async (name: string, dest: string) => {
  const params = { act: 'mov', obj: name, newobj: dest };
  await cloudStorageInstance.request<void>({
    params,
    headers: getRequestHeaders('protected'),
  });
};

export const cloudStorageList = async () => {
  const params = { act: 'lst' };
  const response = await cloudStorageInstance.request<string[]>({
    params,
    headers: getRequestHeaders('protected'),
  });
  return response.data;
};

export const getCloudKeySet = (keys: string[]) => {
  return new Set(keys);
};
