import React from 'react';
import {
  AlertDescription,
  AlertTitle,
  Flex,
  Grid,
  Heading,
  Link,
} from '@chakra-ui/react';
import { Outlet, useLocation, useMatches } from 'react-router-dom';
import { z } from 'zod';

import { NavSidebar } from 'components/NavSidebar';
import { PageLayout } from 'components/PageLayout';
import { useScreenSize } from 'hooks/useScreenSize';
import { getHandleFilter } from 'utils/route';
import { SHOULD_SHOW_PREVIEW_MESSAGE } from 'utils/string';
import { AlertWrapper } from 'components/Chakra/AlertWrapper';

const HandleSchema = z.object({
  heading: z.string(),
});

export const DashboardPageLayout = () => {
  const location = useLocation();

  // Get heading from route definition
  const matches = useMatches();
  const { handle } =
    matches
      .filter(getHandleFilter(HandleSchema))
      .find((match) => match.pathname === location.pathname) ?? {};

  const heading = handle?.heading;

  const { isMobile } = useScreenSize();

  return (
    <PageLayout>
      {SHOULD_SHOW_PREVIEW_MESSAGE && (
        <AlertWrapper status="info">
          <Flex direction={{ base: 'column', lg: 'row' }}>
            <AlertTitle>Coreograph is currently in preview.</AlertTitle>
            <AlertDescription>
              {`See `}
              <Link href="https://coreograph.com" isExternal={true} fontWeight="bold">
                coreograph.com
              </Link>
              {` for more information and updates.`}
            </AlertDescription>
          </Flex>
        </AlertWrapper>
      )}
      <Flex flex={1} overflowX="auto">
        <NavSidebar />

        <Flex
          direction="column"
          width="100%"
          maxWidth="1500px"
          padding={isMobile ? 4 : 12}
        >
          {heading ? (
            <Heading marginLeft={8} marginBottom={6}>
              {heading}
            </Heading>
          ) : null}

          <Grid
            templateColumns={isMobile ? '1fr' : '1fr 1fr'}
            gap={isMobile ? undefined : 6}
          >
            <Outlet />
          </Grid>
        </Flex>
      </Flex>
    </PageLayout>
  );
};
