import * as go from 'gojs';

import { Edge, Node, Port, PortType } from 'types/transparency';
import { decodeType } from 'utils/transparency/decode';
// eslint-disable-next-line import/no-cycle
import { getNameFromTypedId, getUserDefinedType } from 'utils/transparency/types';
import { getStrokeColorForMessage } from './color';

const $ = go.GraphObject.make;

const TOOLTIP_FONT = '10pt Quicksand';

const getMessageText = (data: Node | Edge) => {
  return data._message?.text ?? '';
};

const getMessageStrokeColor = (data: Node | Edge) => {
  if (!data._message) return 'transparent';

  return getStrokeColorForMessage(data._message.type);
};

/**
 * ----------------------------------------------------------------------------
 * PORT TOOLTIPS
 * ----------------------------------------------------------------------------
 */
const getPortTooltipText = (name: string, type: string) => {
  const decodedType = decodeType(type);

  return `Port name: ${name}\nType: ${decodedType}`;
};

export const makePortTooltip = (portId: string, portType: PortType): go.Adornment => {
  return $(
    'ToolTip',
    $(
      go.TextBlock,
      new go.Binding('text', '', (data: go.ObjectData) => {
        const name = getNameFromTypedId(portId);
        const type = getUserDefinedType(data, portId, portType);

        return getPortTooltipText(name, type);
      }),
      {
        font: TOOLTIP_FONT,
      }
    )
  );
};

export const ARRAYED_PORT_TOOLTIP = $(
  'ToolTip',
  $(
    go.TextBlock,
    new go.Binding('text', '', (data: go.ObjectData) => {
      const { pid, type } = data as Port;
      const name = getNameFromTypedId(pid);

      return getPortTooltipText(name, type);
    }),
    {
      font: TOOLTIP_FONT,
    }
  )
);

/**
 * Makes a tooltip for ports on special nodes, specifically inlet/outlet/param.
 * The port information is taken from the first element in the port data's
 * {portType} array, where the data is available through a go Binding converter function.
 *
 * @param portType The port array from which to get information to display
 * @returns a go Tooltip
 */
const makePortTooltipForPortType = (portType: PortType) =>
  $(
    'ToolTip',
    $(
      go.TextBlock,
      new go.Binding('text', '', (data) => {
        const [port] = data[portType] as Port[];
        const { pid, type } = port;
        const name = getNameFromTypedId(pid);
        return getPortTooltipText(name, type);
      }),
      {
        font: TOOLTIP_FONT,
      }
    )
  );

export const OUTPORT_TOOLTIP = makePortTooltipForPortType('outports');
export const INPORT_TOOLTIP = makePortTooltipForPortType('inports');
export const ARGPORT_TOOLTIP = makePortTooltipForPortType('argports');
export const PARPORT_TOOLTIP = makePortTooltipForPortType('parports');

/**
 * ----------------------------------------------------------------------------
 * NODE TOOLTIPS
 * ----------------------------------------------------------------------------
 */
const makeNodeTooltip = ({ isUnknown = false } = {}) =>
  $(
    'ToolTip',

    $(
      go.Panel,
      'Vertical',
      {
        maxSize: new go.Size(300, NaN),
      },

      // Node info
      $(
        go.TextBlock,
        new go.Binding('text', '', (data: Node) => {
          const { cat, key, name, queue } = data;
          const strings: Array<string> = [];

          strings.push(`Key: ${key}`);

          if (isUnknown) strings.push('Category: UNKNOWN');
          else strings.push(`Category: ${getNameFromTypedId(cat)}`);

          if (name) strings.push(`Callee: ${name}`);

          if (queue) strings.push(`Queue: ${queue}`);

          return strings.join('\n');
        }),
        {
          font: TOOLTIP_FONT,
          alignment: go.Spot.Left,
        }
      ),

      // Node errors
      $(
        go.TextBlock,
        new go.Binding('text', '', getMessageText),
        new go.Binding('visible', '', (node: Node) => !!getMessageText(node)),
        new go.Binding(
          'margin',
          '',
          (node: Node) => new go.Margin(getMessageText(node) ? 5 : 0, 0, 0, 0)
        ),
        new go.Binding('stroke', '', getMessageStrokeColor),
        {
          font: TOOLTIP_FONT,
          textAlign: 'left',
          alignment: go.Spot.Left,
        }
      )
    )
  );

export const NODE_TOOLTIP = makeNodeTooltip();
export const UNKNOWN_NODE_TOOLTIP = makeNodeTooltip({ isUnknown: true });

/**
 * ----------------------------------------------------------------------------
 * LINK TOOLTIP
 * ----------------------------------------------------------------------------
 */
export const LINK_TOOLTIP = $(
  'ToolTip',

  $(
    go.Panel,
    'Vertical',
    {
      maxSize: new go.Size(300, NaN),
    },

    // Link info
    $(
      go.TextBlock,
      new go.Binding('text', '', (data: Edge) => {
        const { src, dst, key, type, depth } = data;

        const srcName = getNameFromTypedId(src);
        const dstName = getNameFromTypedId(dst);

        const decodedType =
          type !== undefined && type !== '?' ? decodeType(type) : undefined;

        const strings = [`Link: ${srcName} → ${dstName}`, `Key: ${key}`];
        if (decodedType) strings.push(`Type: ${decodedType}`);
        if (depth) strings.push(`Depth: ${depth}`);

        return strings.join('\n');
      }),
      {
        font: TOOLTIP_FONT,
        alignment: go.Spot.Left,
      }
    ),

    // Link errors
    $(
      go.TextBlock,
      new go.Binding('text', '', getMessageText),
      new go.Binding('visible', '', (edge: Edge) => !!getMessageText(edge)),
      new go.Binding(
        'margin',
        '',
        (edge: Edge) => new go.Margin(getMessageText(edge) ? 5 : 0, 0, 0, 0)
      ),
      new go.Binding('stroke', '', getMessageStrokeColor),
      {
        font: TOOLTIP_FONT,
        textAlign: 'left',
        alignment: go.Spot.Left,
      }
    )
  )
);
