import {
  StackStatus,
  STACK_STATUS_ERROR,
  STACK_STATUS_PROGRESS,
  STACK_STATUS_SUCCESS,
} from 'services/api/ConnectedMode';
import { AWS_REGIONS } from 'utils/region';
import { capitalize, isInConstArray } from 'utils/string';

export const isStackActive = (status: StackStatus) => {
  return status.split('_').at(-1) === 'COMPLETE' && status !== 'DELETE_COMPLETE';
};

export const isStackProgress = (status: StackStatus) =>
  isInConstArray(status, STACK_STATUS_PROGRESS);

export const isStackSuccess = (status: StackStatus) =>
  isInConstArray(status, STACK_STATUS_SUCCESS);

export const isStackError = (status: StackStatus) =>
  isInConstArray(status, STACK_STATUS_ERROR);

export const displayStackStatus = (status: StackStatus) => {
  return capitalize(status.split('_').join(' ').toLowerCase());
};

export const getStackConsoleLink = (stackArn: string) => {
  const region = getRegionFromStackArn(stackArn);
  if (!region) return undefined;
  return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/stackinfo?stackId=${encodeURIComponent(
    stackArn
  )}`;
};
/**
 * @param stackArn
 * @returns AWS region name that is contained inside the arn
 * The format of the stack ARN is: 'arn:aws:cloudformation:${region}:${AWS::Account}:stack/${stackName}/${uuid}'
 */
export const getRegionFromStackArn = (stackArn: string) => {
  const splitArn = stackArn.split(':');
  if (splitArn.length !== 6) return undefined;
  const region = splitArn[3];
  return isInConstArray(region, AWS_REGIONS) ? region : undefined;
};
