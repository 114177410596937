import React from 'react';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import { useScreenSize } from 'hooks/useScreenSize';

/**
 * Wrapper around Chakra UI's Modal component that defaults to
 * 'xs' size for mobile screens.
 */
const Modal = (props: ModalProps) => {
  const { size, ...otherProps } = props;

  const { isMobile } = useScreenSize();
  const defaultSize = isMobile ? 'xs' : 'md';

  return (
    <ChakraModal scrollBehavior="inside" {...otherProps} size={size ?? defaultSize} />
  );
};

// Re-export all the Modal imports so they can be imported from this module
export {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
};
export type { ModalProps };
