import React, { useEffect, useState } from 'react';
import { Flex, Button, Text, AlertDescription, Link, useToast } from '@chakra-ui/react';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useCancelConnectedModeRegistrationMutation,
  useRegisterConnectedModeMutation,
} from 'hooks/useConnectedModeApi';
import { getLocalAwsRegion } from 'utils/region';
import { useUserContext } from 'context/useUserContext';
import { AlertWrapper } from 'components/Chakra/AlertWrapper';
import { getErrorMessage } from 'utils/error';

type PendingVerificationContentProps = {
  accountId: string;
  parentRegisterData: { accountId?: string; registerLink?: string };
};
export const PendingVerificationContent = (props: PendingVerificationContentProps) => {
  const { accountId, parentRegisterData } = props;

  const toast = useToast();

  const { accountId: parentAccountId, registerLink: parentRegisterLink } =
    parentRegisterData;

  const [registerLink, setRegisterLink] = useState(
    parentAccountId === accountId ? parentRegisterLink : undefined
  );

  const { setIsAuthed, openLoginRedirectModal } = useUserContext();

  const { mutate: registerMutate, isLoading: isRegisterLoading } =
    useRegisterConnectedModeMutation();

  const { mutate: cancelRegisterMutate, isLoading: isCancelRegisterLoading } =
    useCancelConnectedModeRegistrationMutation();

  // registerLink is not stored in db since it will time out, register again to get registerLink
  useEffect(() => {
    if (registerLink) return;

    const localAwsRegion = getLocalAwsRegion();
    if (!localAwsRegion) {
      setIsAuthed(false);
      openLoginRedirectModal();
      return;
    }
    registerMutate(
      { accountId, region: localAwsRegion },
      {
        onSuccess: ({ registerLink: link }) => {
          setRegisterLink(link);
        },
      }
    );
  }, [registerLink, accountId, openLoginRedirectModal, setIsAuthed, registerMutate]);

  const handleCancelRegister = () => {
    cancelRegisterMutate(accountId, {
      onSuccess: () =>
        toast({
          title: `Cancelled registration for account '${accountId}'`,
          status: 'success',
          isClosable: true,
        }),
      onError: (e) =>
        toast({
          title: `Unable to cancel registration for account '${accountId}'`,
          description: getErrorMessage(e),
          status: 'error',
          isClosable: true,
        }),
    });
  };

  return (
    <Flex flexDir="column">
      <Text>
        Deploy the CloudFormation stack with the link provided to verify the connection
      </Text>

      <Flex mt={5} justifyContent="space-between">
        {isRegisterLoading ? (
          <Text>Getting registration link...</Text>
        ) : registerLink ? (
          <Link color="teal" href={registerLink} isExternal={true}>
            To CloudFormation console <FontAwesomeIcon icon={faExternalLink} />
          </Link>
        ) : (
          <AlertWrapper>
            <AlertDescription>
              Unable to get registration link, please try again later
            </AlertDescription>
          </AlertWrapper>
        )}
        <Button
          onClick={handleCancelRegister}
          colorScheme="red"
          isLoading={isCancelRegisterLoading}
        >
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};
