export class IndexedDBService<T> {
  private db: IDBDatabase | undefined;

  private storeName: string;

  // Maintain set of keys in DB
  storageKeys: Set<string>;

  constructor(dbName: string, dbVersion: number, storeName: string) {
    this.storeName = storeName;
    this.storageKeys = new Set<string>();

    if (!window.indexedDB) return;

    const openRequest = window.indexedDB.open(dbName, dbVersion);

    // Create the schema
    openRequest.onupgradeneeded = () => {
      openRequest.result.createObjectStore(this.storeName);
    };

    openRequest.onsuccess = () => {
      this.db = openRequest.result;

      this.getKeys((set) => {
        this.storageKeys = set;
      });
    };
  }

  isSupported() {
    return this.db !== undefined;
  }

  get(key: string, onSuccess: (result: T) => void, onError: () => void) {
    if (!this.db) return;

    const objectStore = this.db
      .transaction(this.storeName, 'readonly')
      .objectStore(this.storeName);

    const request: IDBRequest<T> = objectStore.get(key);

    request.onsuccess = () => {
      if (!request.result) {
        onError();
      } else {
        onSuccess(request.result);
      }
    };
    request.onerror = onError;
  }

  private getKeys(onSuccess: (set: Set<string>) => void) {
    if (!this.db) return;

    const objectStore = this.db
      .transaction(this.storeName, 'readonly')
      .objectStore(this.storeName);

    const keySet = new Set<string>();
    const request = objectStore.openCursor();

    request.onsuccess = () => {
      const cursor = request.result;

      if (cursor) {
        keySet.add(<string>cursor.primaryKey);
        cursor.continue();
      } else {
        onSuccess(keySet);
      }
    };
  }

  put(key: string, value: T, onSuccess: () => void, onError: () => void) {
    if (!this.db) return;

    const objectStore = this.db
      .transaction(this.storeName, 'readwrite')
      .objectStore(this.storeName);

    const request = objectStore.put(value, key);

    request.onsuccess = () => {
      this.storageKeys.add(key);
      onSuccess();
    };
    request.onerror = onError;
  }

  delete(key: string, onSuccess: () => void, onError: () => void) {
    if (!this.db) return;

    const objectStore = this.db
      .transaction(this.storeName, 'readwrite')
      .objectStore(this.storeName);

    const request = objectStore.delete(key);

    request.onsuccess = () => {
      this.storageKeys.delete(key);
      onSuccess();
    };
    request.onerror = onError;
  }

  move(oldKey: string, newKey: string, onSuccess: () => void, onError: () => void) {
    if (!this.db) return;

    // If newKey already exists in the db, treat it as an error (same as cloud storage behavior)
    this.get(newKey, onError, () => {});

    // put with newKey and delete entry with oldKey
    this.get(
      oldKey,
      (result) => {
        this.put(
          newKey,
          result,
          () => {
            this.delete(oldKey, onSuccess, onError);
          },
          onError
        );
      },
      onError
    );
  }
}
