import React, { Component } from 'react';

import { ErrorOverlay } from 'components/ErrorOverlay';

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorOverlay />;
    }

    return children;
  }
}
