import nodeJson from 'data/node.json';
import typeJson from 'data/type.json';
import cfnJson from 'data/cfn.json';
import tempJson from 'data/temp.json';
import generatorsJson from 'data/generators.json';
import awsServiceJson from 'data/aws_services.json';

import {
  CfnDictionary,
  ProducerDictionary,
  NodeDictionary,
  TempDictionary,
  TypeDictionary,
  AwsServiceDictionary,
} from 'types/transparency';

// Add typing to json imports
export const BASE_NODE_DICTIONARY: NodeDictionary = nodeJson;
export const SPECIAL_NODE_DICTIONARY: TempDictionary = tempJson;
export const RESOURCE_DICTIONARY: CfnDictionary = cfnJson;
export const BASE_TYPE_DICTIONARY = typeJson as TypeDictionary;

const UNFILTERED_PRODUCER_DICTIONARY: ProducerDictionary = generatorsJson;

// The editor will only handle components with type `circuit`, `list<circuit>`, `project`
// and those with a non-empty docURL
export const PRODUCER_DICTIONARY: ProducerDictionary = Object.entries(
  UNFILTERED_PRODUCER_DICTIONARY
).reduce((dict, [key, value]) => {
  if (
    ['circuit', 'list<circuit>', 'project', 'text'].includes(value.type) &&
    value.docURL !== undefined
  ) {
    dict[key] = value;
  }

  return dict;
}, {} as ProducerDictionary);

export const AWS_SERVICE_DICTIONARY: AwsServiceDictionary = awsServiceJson;

export const COMBINED_NODE_DICTIONARY = {
  ...BASE_NODE_DICTIONARY,
  ...SPECIAL_NODE_DICTIONARY,
  ...RESOURCE_DICTIONARY,
};
