import { Dispatch, SetStateAction } from 'react';
import { getJWTAuthBearer, storeJWTs } from 'services/TokenService';
import { isLocalhost } from 'utils/url';

import axios from 'axios';
import { isForbiddenError } from 'utils/error';

type RequestType = 'unprotected' | 'protected';

export const getRequestHeaders = (type: RequestType) => {
  if (type === 'unprotected') {
    return {
      'Content-Type': 'text/plain; charset=UTF-8',
      // Add null Authorization header to send pre-flight request on localhost
      // https://coreograph.slack.com/archives/C02CUKV428P/p1660659618295049?thread_ts=1660647037.012479&cid=C02CUKV428P
      ...(isLocalhost() && { Authorization: null }),
    };
  }
  if (type === 'protected') {
    return {
      'Content-Type': 'text/plain; charset=UTF-8',
      Authorization: getJWTAuthBearer(),
    };
  }
};

type CreateAxiosParams = {
  isProtected: boolean;
};

export const createAxiosInstance = ({ isProtected }: CreateAxiosParams) => {
  const axiosInstance = axios.create({
    method: 'post',
    withCredentials: isProtected,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      return {
        ...config,
        headers: getRequestHeaders(isProtected ? 'protected' : 'unprotected'),
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      storeJWTs({
        idToken: response.headers.id_token,
        accessToken: response.headers.access_token,
      });
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const onRequestError = (
  setIsAuthed: Dispatch<SetStateAction<boolean>>,
  error: unknown
) => {
  if (isForbiddenError(error)) setIsAuthed(false);
};
