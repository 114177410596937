import { ColorMode } from '@chakra-ui/react';
import * as go from 'gojs';

import {
  CfnDictionaryEntry,
  CircuitCategory,
  DynPorts,
  Message,
  NodeDictionaryEntry,
  TempDictionaryEntry,
} from 'types/transparency';
import { getValidId } from 'utils/id';
import { SPECIAL_NODE_DICTIONARY } from 'utils/transparency/dictionaries';
// eslint-disable-next-line import/no-cycle
import { getFinalIdentifier } from 'utils/transparency/types';

import { getCircuitColor, getStrokeColorForMessage } from './color';
// eslint-disable-next-line import/no-cycle
import { getModelFromDiagram } from './diagram';
import { EDITORS, EditorVariant, showTextEditor } from './editor';
// eslint-disable-next-line import/no-cycle
import {
  addPorts,
  ARG_ARRAYED_PORTS,
  deletePorts,
  INPUT_ARRAYED_PORTS,
  makePortArrays,
  OUTPUT_ARRAYED_PORTS,
  PARAM_ARRAYED_PORTS,
} from './port';
// eslint-disable-next-line import/no-cycle
import {
  ARGPORT_TOOLTIP,
  ARRAYED_PORT_TOOLTIP,
  INPORT_TOOLTIP,
  NODE_TOOLTIP,
  OUTPORT_TOOLTIP,
  PARPORT_TOOLTIP,
  UNKNOWN_NODE_TOOLTIP,
} from './tooltip';

const NODE_BASE_HEIGHT = 40;
const NODE_BASE_WIDTH = 70;
const NODE_ICON_HEIGHT = 30;
const NODE_ICON_MARGIN = new go.Margin(0, 5);

const NODE_FONT = '11pt Quicksand, sans-serif';
const PORT_NODE_FONT = '10pt Quicksand, sans-serif';
const TEXT_NODE_FONT = '10pt Ubuntu Mono, monospace';
export const DIAGRAM_FONTS = [NODE_FONT, TEXT_NODE_FONT];

const TEXT_NODE_HEIGHT = 30;

const BASIC_NODE_STROKE = '#686868';
const SPECIAL_NODE_BG_COLOR = 'rgba(225, 225, 225, 0.8)';
const SPECIAL_NODE_STROKE = 'white';
const SPECIAL_NODE_TEXT_COLOR = '#3c3c3c';

const PARAM_PORT_FILL_COLOR = '#114764';
const INLET_PORT_FILL_COLOR = '#4040FF';
const GLOBAL_PORT_FILL_COLOR = '#2b8bc2';
const PORT_NODE_STROKE_WIDTH = 3;

const RESOURCE_NODE_HEIGHT = 40;

const $ = go.GraphObject.make;

// https://gojs.net/latest/extensions/RoundedRectangles.js
go.Shape.defineFigureGenerator('RoundedTopRectangle', (shape, w, h) => {
  // this figure takes one parameter, the size of the corner
  let p1 = 5; // default corner size
  if (shape !== null) {
    const param1 = shape.parameter1;
    if (!Number.isNaN(param1) && param1 >= 0) p1 = param1; // can't be negative or NaN
  }
  p1 = Math.min(p1, w / 2);
  p1 = Math.min(p1, h / 2); // limit by whole height or by half height?
  const geo = new go.Geometry();
  // a single figure consisting of straight lines and quarter-circle arcs
  geo.add(
    new go.PathFigure(0, p1)
      .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, w - p1, 0))
      .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - p1, p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, w, h))
      .add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
  );
  // don't intersect with two top corners when used in an "Auto" Panel
  geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
  geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);
  return geo;
});

go.Shape.defineFigureGenerator('RoundedBottomRectangle', (shape, w, h) => {
  // this figure takes one parameter, the size of the corner
  let p1 = 5; // default corner size
  if (shape !== null) {
    const param1 = shape.parameter1;
    if (!Number.isNaN(param1) && param1 >= 0) p1 = param1; // can't be negative or NaN
  }
  p1 = Math.min(p1, w / 3); // limit by width & height
  p1 = Math.min(p1, h);
  const geo = new go.Geometry();
  // a single figure consisting of straight lines and quarter-circle arcs
  geo.add(
    new go.PathFigure(0, 0)
      .add(new go.PathSegment(go.PathSegment.Line, w, 0))
      .add(new go.PathSegment(go.PathSegment.Line, w, h - p1))
      .add(new go.PathSegment(go.PathSegment.Arc, 0, 90, w - p1, h - p1, p1, p1))
      .add(new go.PathSegment(go.PathSegment.Line, p1, h))
      .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, h - p1, p1, p1).close())
  );
  // don't intersect with two bottom corners when used in an "Auto" Panel
  geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0);
  geo.spot2 = new go.Spot(1, 1, -0.3 * p1, -0.3 * p1);
  return geo;
});

/**
 * ----------------------------------------------------------------------------
 * Converters for two-way bindings
 * ----------------------------------------------------------------------------
 */
export const roundAndStringifyPoint = (point: go.Point) =>
  go.Point.stringify(new go.Point(Math.round(point.x), Math.round(point.y)));

const roundAndStringifySize = (size: go.Size) =>
  go.Size.stringify(new go.Size(Math.round(size.width), Math.round(size.height)));

/**
 * ----------------------------------------------------------------------------
 * Bindings
 * ----------------------------------------------------------------------------
 */
const BINDING_LOCATION_TWO_WAY = new go.Binding(
  'location',
  'loc',
  go.Point.parse
).makeTwoWay(roundAndStringifyPoint);

/**
 * @param defaultStroke Default value for the converter to return.
 * @returns A GoJS binding converter that receives the _message data property
 *          on a Part. The converter returns a stroke color depending on whether
 *          there is a message attached to this part and what the message type is.
 */
export const getStrokeConverter =
  (defaultStroke: string) => (message: Message | undefined) => {
    if (!message) return defaultStroke;

    return getStrokeColorForMessage(message.type) ?? defaultStroke;
  };

const getStrokeWidthConverter =
  (highlightedWidth = 3, defaultWidth = 1) =>
  (message: Message | undefined) => {
    return !message ? defaultWidth : highlightedWidth;
  };

/**
 * ----------------------------------------------------------------------------
 * Utils
 * ----------------------------------------------------------------------------
 */
const openTextEditor = (_: go.InputEvent, obj: go.GraphObject) => {
  const { part } = obj;
  const key = part?.key;
  const textEditor = part?.findObject(CONTEXT_MENU.textEditor.id);
  const editorVariant = part?.data?.cat === 'comment' ? 'comment' : 'expr';

  if (!textEditor || !key) return;
  showTextEditor(editorVariant, textEditor, key);
};

/**
 * ----------------------------------------------------------------------------
 * Node Templates
 * ----------------------------------------------------------------------------
 */
export const makeBasicTemplate = (
  category: string,
  data: NodeDictionaryEntry,
  colorMode: ColorMode
) => {
  const { icon, verb } = data;

  const { inPorts, outPorts, paramPorts, argPorts } = makePortArrays(data, colorMode);

  let iconImage;
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    iconImage = icon ? require(`assets/${icon}`)?.default : undefined;
  } catch {
    console.error(`Missing icon image for basic node ${category}: ${icon}`);
  }

  // prettier-ignore
  const node =
    $(go.Node, 'Table',
      BINDING_LOCATION_TWO_WAY.copy(),
      {
        locationSpot: go.Spot.TopLeft,
      },

      $(go.Panel, 'Auto',
        {
          row: 1,
          column: 1,
          stretch: go.GraphObject.Fill,
          minSize: new go.Size(NODE_BASE_WIDTH, NODE_BASE_HEIGHT),
          toolTip: NODE_TOOLTIP,
        },

        $(go.Shape, 'RoundedRectangle',
          new go.Binding(
            'stroke',
            '_message',
            getStrokeConverter(BASIC_NODE_STROKE)
          ),
          new go.Binding('strokeWidth', '_message', getStrokeWidthConverter()),
          {
            stroke: BASIC_NODE_STROKE,
            fill: 'rgba(61, 61, 61, 0.5)',
          },
        ),
        
        iconImage
          ? (
            $(go.Panel, 'Horizontal',
              {
                alignment: go.Spot.Left,
              },

              $(go.Picture, {
                margin: NODE_ICON_MARGIN,
                desiredSize: new go.Size(NODE_ICON_HEIGHT, NODE_ICON_HEIGHT),
                source: iconImage,
              }),
              
              $(go.TextBlock, verb ? verb.toLowerCase() : getFinalIdentifier(category),
                {
                  margin: new go.Margin(8, 6, 6, 0),
                  stroke: 'white',
                  font: NODE_FONT,
                }
              )
            )
          )
          : (
            $(go.TextBlock, getFinalIdentifier(category),
              {
                margin: new go.Margin(6, 6, 3, 6),
                stroke: 'white',
                font: NODE_FONT,
              },
            )
          ),
      ),

      $(go.Panel, 'Horizontal',
        {
          row: 0,
          column: 1,
          stretch: go.GraphObject.Fill,
        },
        paramPorts,
      ),

      $(go.Panel, 'Vertical',
        {
          row: 1,
          column: 0,
        },
        inPorts,
      ),

      $(go.Panel, 'Vertical',
        {
          row: 1,
          column: 2,
        },
        outPorts,
      ),

      $(go.Panel, 'Horizontal',
        {
          row: 2,
          column: 1,
          stretch: go.GraphObject.Fill,
        },
        argPorts,
      ),
    );

  node.contextMenu = makeContextMenu();

  return node;
};

const getSpecialNodeIcon = (name: string) => {
  const { icon, menu } = SPECIAL_NODE_DICTIONARY[name];
  if (!icon) return;

  let iconName;
  try {
    const iconCategory = menu.substring(menu.lastIndexOf('/') + 1);

    switch (iconCategory) {
      case 'Synch':
        iconName = 'sync';
        break;
      case 'Logical':
        iconName = `logical-${name}`;
        break;
      default:
        iconName = iconCategory.toLowerCase();
        break;
    }

    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`assets/img/SpecialNodes/${iconName}.svg`)?.default;
  } catch (error) {
    console.error(`Missing icon image for special node ${name}: ${iconName}`);
  }
};

export const makeSpecialTemplate = (
  category: string,
  data: TempDictionaryEntry,
  colorMode: ColorMode
) => {
  const iconImage = getSpecialNodeIcon(category);

  // prettier-ignore
  const node = $(go.Node, 'Table',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      toSpot: go.Spot.TopCenter,
      fromSpot: go.Spot.BottomCenter,
      locationSpot: go.Spot.TopLeft,
    },

    $(go.Panel, 'Auto',
      {
        row: 1,
        column: 1,
        stretch: go.GraphObject.Fill,
        minSize: new go.Size(NODE_BASE_WIDTH, NODE_BASE_HEIGHT),
        toolTip: NODE_TOOLTIP,
      },

      $(go.Shape, 'RoundedRectangle',
        new go.Binding(
          'stroke',
          '_message',
          getStrokeConverter(SPECIAL_NODE_STROKE)
        ),
        new go.Binding('strokeWidth', '_message', getStrokeWidthConverter()),
        {
          stroke: SPECIAL_NODE_STROKE,
          fill: SPECIAL_NODE_BG_COLOR,
        }
      ),

      iconImage
        ?  (
          $(go.Panel, 'Horizontal',
            {
              alignment: go.Spot.Left,
            },

            $(go.Picture, {
              margin: NODE_ICON_MARGIN,
              desiredSize: new go.Size(NODE_ICON_HEIGHT, NODE_ICON_HEIGHT),
              source: iconImage,
            }),

            $(go.TextBlock, category,
              {
                margin: new go.Margin(8, 6, 6, 0),
                stroke: SPECIAL_NODE_TEXT_COLOR,
                font: NODE_FONT,
              }
            )
          )
        )
        : (
          $(go.TextBlock, category,
            {
              margin: new go.Margin(6, 6, 3, 6),
              stroke: SPECIAL_NODE_TEXT_COLOR,
              font: NODE_FONT,
            }
          )
        )
    ),

    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'parports'),
      {
        row: 0,
        column: 1,
        stretch: go.GraphObject.Fill,
        itemTemplate: PARAM_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'inports'),
      {
        row: 1,
        column: 0,
        itemTemplate: INPUT_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'outports'),
      {
        row: 1,
        column: 2,
        itemTemplate: OUTPUT_ARRAYED_PORTS[colorMode],
      }
    ),
    
    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'argports'),
      {
        row: 2,
        column: 1,
        stretch: go.GraphObject.Fill,
        itemTemplate: ARG_ARRAYED_PORTS[colorMode],
      },
    ),
  );

  const menuButtons = makeBaseButtonArray();
  addPortButtons(menuButtons, data);
  node.contextMenu = makeContextMenu(menuButtons);

  return node;
};

export const makeResourceTemplate = (
  category: string,
  data: CfnDictionaryEntry,
  colorMode: ColorMode
) => {
  const { icon } = data;

  let iconImage;
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    iconImage = require(`assets/${icon}`)?.default;
  } catch {
    console.error(`Missing icon image for resource node ${category}: ${icon}`);
  }

  // prettier-ignore
  const node = $(go.Node, 'Table',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      toSpot: go.Spot.TopCenter,
      fromSpot: go.Spot.BottomCenter,
      locationSpot: go.Spot.TopLeft,
    },

    $(go.Panel, 'Auto',
      {
        row: 1,
        column: 0,
        stretch: go.GraphObject.Fill,
        minSize: new go.Size(RESOURCE_NODE_HEIGHT, RESOURCE_NODE_HEIGHT),
        toolTip: NODE_TOOLTIP,
      },
      
      $(go.Shape, 'RoundedRectangle',
        new go.Binding(
          'stroke',
          '_message',
          getStrokeConverter(BASIC_NODE_STROKE)
        ),
        new go.Binding('strokeWidth', '_message', getStrokeWidthConverter()),
        {
          stroke: BASIC_NODE_STROKE,
          fill: 'rgba(30, 30, 30, 0.8)',
        }
      ),
      
      iconImage
        ? (
          $(go.Picture, {
            margin: NODE_ICON_MARGIN,
            desiredSize: new go.Size(NODE_ICON_HEIGHT, NODE_ICON_HEIGHT),
            source: iconImage,
          })
        )
        : (
          $(go.TextBlock, category,
            {
              margin: new go.Margin(6, 6, 3, 6),
              stroke: 'white',
              font: NODE_FONT,
            }
          )
        )
    ),

    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'parports'),
      {
        row: 0,
        column: 0,
        stretch: go.GraphObject.Fill,
        itemTemplate: PARAM_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'outports'),
      {
        row: 1,
        column: 1,
        itemTemplate: OUTPUT_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'argports'),
      {
        row: 2,
        column: 0,
        itemTemplate: ARG_ARRAYED_PORTS[colorMode],
      }
    )
  );

  node.contextMenu = makeContextMenu();

  return node;
};

const makeNodeNameTag = (
  name: string,
  options?: Partial<{ stroke: string; backgroundColor: string; isTop: boolean }>
) => {
  const {
    stroke = 'black',
    backgroundColor = SPECIAL_NODE_BG_COLOR,
    isTop = true,
  } = options ?? {};

  return $(
    go.Panel,
    'Auto',
    {
      defaultStretch: go.GraphObject.Fill,
    },

    $(
      go.Shape,
      isTop ? 'RoundedTopRectangle' : 'RoundedBottomRectangle',
      new go.Binding('stroke', '_message', getStrokeConverter('transparent')),
      new go.Binding(
        'strokeWidth',
        '_message',
        getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH / 2, 0)
      ),
      {
        strokeWidth: 0,
        fill: backgroundColor,
      }
    ),

    $(go.TextBlock, name.toUpperCase(), {
      minSize: new go.Size(30, NaN),
      verticalAlignment: go.Spot.Center,
      isMultiline: false,
      textAlign: 'center',
      font: '6pt Quicksand, sans-serif',
      stroke,
      margin: new go.Margin(1, 2),
    })
  );
};

export const makeArgTemplate = (data: TempDictionaryEntry, colorMode: ColorMode) => {
  // prettier-ignore
  const node = $(go.Node, 'Auto',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      toSpot: go.Spot.LeftCenter,
      fromSpot: go.Spot.RightCenter,
      locationSpot: go.Spot.TopLeft,
      selectionAdorned: true,
    },

    $(go.Panel, 'Vertical',

      $(go.Panel, 'Horizontal',
        new go.Binding('itemArray', 'parports'),
        {
          itemTemplate: PARAM_ARRAYED_PORTS[colorMode],
        }
      ),

      $(go.Panel, 'Auto',
        {
          toolTip: NODE_TOOLTIP,
        },

        $(go.Shape, 'RoundedRectangle',
          new go.Binding(
            'stroke',
            '_message',
            getStrokeConverter('transparent')
          ),
          new go.Binding('strokeWidth', '_message', getStrokeWidthConverter()),
          {
            stroke: 'transparent',
            fill: '#033'
          }
        ),

        $(go.TextBlock, '<expr>',
          new go.Binding('text', 'text').makeTwoWay(),
          {
            name: CONTEXT_MENU.textEditor.id,
            editable: false,
            isMultiline: true,
            maxLines: 1,
            textAlign: 'center',
            margin: new go.Margin(6, 6, 3, 6),
            stroke: '#DDD',
            background: '#033',
            font: TEXT_NODE_FONT,
            textEditor: EDITORS.expr.HTMLInfo,
          }
        )
      ),

      $(go.Panel, 'Horizontal',
        new go.Binding('itemArray', 'argports'),
        {
          itemTemplate: ARG_ARRAYED_PORTS[colorMode],
        }
      )
    )
  );

  node.doubleClick = openTextEditor;

  node.contextMenu = makeTextNodeContextMenu(data);

  return node;
};

export const makeCallTemplate = (
  type: CircuitCategory,
  setActivePanel: (name: string) => void
) => {
  const strokeColor = getCircuitColor(type);

  // prettier-ignore
  const node = $(go.Node, 'Table',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      toSpot: go.Spot.Top,
      fromSpot: go.Spot.BottomCenter,
      locationSpot: go.Spot.TopLeft,
    },

      $(go.TextBlock, type.toUpperCase(),
        {
          column: 1,
          row: 1,
          height: 15,
          verticalAlignment: go.Spot.Center,
          isMultiline: false,
          alignment: go.Spot.Top,
          stretch: go.GraphObject.Horizontal,
          textAlign: 'center',
          font: '6pt Quicksand, sans-serif',
          background: strokeColor,
        }
      ),
      
    $(go.Panel, 'Auto',
      {
        // column and row here refer to the surrounding table
        column: 1,
        row: 2,
        stretch: go.GraphObject.Fill,
        minSize: new go.Size(NODE_BASE_WIDTH + 10, 50),
        margin: new go.Margin(-5, 0, 0, 0),
        toolTip: NODE_TOOLTIP,
      },
      
      $(go.Shape, 'RoundedBottomRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(strokeColor)),
        {
          stretch: go.GraphObject.Fill,
          fill: SPECIAL_NODE_BG_COLOR,
          stroke: strokeColor,
          strokeWidth: 4,
        },
      ),
      
      $(go.TextBlock, '',
        new go.Binding('text', 'name').makeTwoWay(),
        {
          name: CONTEXT_MENU.textEditor.id,
          editable: false,
          isMultiline: false,
          stretch: go.GraphObject.Horizontal,
          textAlign: 'center',
          margin: new go.Margin(6, 6, 3, 6),
          stroke: SPECIAL_NODE_TEXT_COLOR,
          font: `bold ${NODE_FONT}`, 
        }
      ),
    ),

    // parports
    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'parports'),
      {
        column: 1,
        row: 0,
        alignment: go.Spot.Left,
        stretch: go.GraphObject.Fill,
        itemTemplate: $(go.Panel,
          new go.Binding('portId', 'pid'),
          {
            toolTip: ARRAYED_PORT_TOOLTIP,
            toLinkable: true,
            toSpot: go.Spot.Top,
            alignment: go.Spot.TopCenter,
            cursor: 'pointer',
          },
          $(go.Shape, 'TriangleDown',
            {
              fill: PARAM_PORT_FILL_COLOR,
              stroke: null,
              desiredSize: new go.Size(12, 12),
            }
          )
        ),
      }
    ),

    // inports
    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'inports'), {
        column: 0,
        row: 2,
        alignment: go.Spot.Left,
        itemTemplate: $(go.Panel,
          new go.Binding('portId', 'pid'),
          {
            toolTip: ARRAYED_PORT_TOOLTIP,
            toLinkable: true,
            toSpot: go.Spot.Left,
            alignment: go.Spot.TopLeft,
            cursor: 'pointer',
          },

          $(go.Shape, 'TriangleRight',
            {
              desiredSize: new go.Size(15, 15),
              fill: INLET_PORT_FILL_COLOR,
              stroke: null,
            }
          )
        ),
      }
    ),

    // outports
    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'outports'),
      {
        column: 2,
        row: 2,
        alignment: go.Spot.Right,
        itemTemplate: $(go.Panel,
          new go.Binding('portId', 'pid'),
          {
            toolTip: ARRAYED_PORT_TOOLTIP,
            fromLinkable: true,
            fromSpot: go.Spot.Right,
            alignment: go.Spot.TopRight,
            cursor: 'pointer',
          },
          $(go.Shape, 'TriangleRight',
            {
              desiredSize: new go.Size(15, 15),
              fill: INLET_PORT_FILL_COLOR,
              stroke: null,
            }
          )
        ),
      }
    ),
    
    // argports
    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'argports'),
      {
        column: 1,
        row: 3,
        alignment: go.Spot.Left,
        stretch: go.GraphObject.Fill,
        itemTemplate: $(go.Panel,
          new go.Binding('portId', 'pid'),
          {
            toolTip: ARRAYED_PORT_TOOLTIP,
            fromLinkable: true,
            fromSpot: go.Spot.Bottom,
            alignment: go.Spot.BottomCenter,
            cursor: 'pointer',
          },
          $(go.Shape, 'TriangleDown',
            {
              fill: PARAM_PORT_FILL_COLOR,
              stroke: null,
              desiredSize: new go.Size(12, 12),
            }
          )
        ),
      }
    ),
  );

  node.doubleClick = (_, obj: go.GraphObject) => {
    const { name } = obj.part?.data;
    if (!name) return;

    setActivePanel(name);
  };

  /**
   * Create context menu here instead of in `addExceptionalSpecialTemplates`
   * (iterating through RENAMEABLE_NODES) so that the context menu is recreated
   * whenever `addCallTemplateLazily` is called
   */
  node.contextMenu = makeCallNodeContextMenu(type);

  return node;
};

export const makeExprTemplate = (data: TempDictionaryEntry, colorMode: ColorMode) => {
  // prettier-ignore
  const node = $(go.Node, 'Table',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      toSpot: go.Spot.TopCenter,
      fromSpot: go.Spot.BottomCenter,
      locationSpot: go.Spot.TopLeft,
    },

    $(go.Panel, 'Auto',
      {
        row: 1,
        column: 1,
        stretch: go.GraphObject.Fill,
        minSize: new go.Size(NaN, TEXT_NODE_HEIGHT),
        toolTip: NODE_TOOLTIP,
      },

      $(go.Shape, 'RoundedRectangle',
        new go.Binding(
          'stroke',
          '_message',
          getStrokeConverter('transparent')
        ),
        new go.Binding('strokeWidth', '_message', getStrokeWidthConverter()),
        {
          stroke: 'transparent',
          fill: '#300'
        }
      ),  

      $(go.TextBlock, '<expr>',
        new go.Binding('text', 'text').makeTwoWay(),
        {
          name: CONTEXT_MENU.textEditor.id,
          editable: false,
          isMultiline: true,
          maxLines: 1,
          textAlign: 'center',
          margin: new go.Margin(6, 6, 3, 6),
          stroke: '#DDD',
          background: '#300',
          font: TEXT_NODE_FONT,
          textEditor: EDITORS.expr.HTMLInfo,
        }
      )
    ),

    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'parports'), {
        row: 0,
        column: 1,
        stretch: go.GraphObject.Fill,
        itemTemplate: PARAM_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'inports'),
      {
        row: 1,
        column: 0,
        itemTemplate: INPUT_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'outports'),
      {
        row: 1,
        column: 2,
        itemTemplate: OUTPUT_ARRAYED_PORTS[colorMode],
      }
    )
  );

  node.doubleClick = openTextEditor;

  node.contextMenu = makeTextNodeContextMenu(data);

  return node;
};

export const makeValueTemplate = (
  data: TempDictionaryEntry,
  backgroundColor: string,
  colorMode: ColorMode
) => {
  // prettier-ignore
  const node = $(go.Node, 'Table',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      toSpot: go.Spot.TopCenter,
      fromSpot: go.Spot.BottomCenter,
      locationSpot: go.Spot.TopLeft,
    },
    $(go.Panel, 'Auto',
      {
        row: 1,
        column: 1,
        stretch: go.GraphObject.Fill,
        minSize: new go.Size(NaN, TEXT_NODE_HEIGHT),
        toolTip: NODE_TOOLTIP,
      },

      $(go.Shape, 'RoundedRectangle',
        new go.Binding(
          'stroke',
          '_message',
          getStrokeConverter('transparent')
        ),
        new go.Binding('strokeWidth', '_message', getStrokeWidthConverter()),
        {
          stroke: 'transparent',
          fill: backgroundColor,
        }
      ),
      
      $(go.TextBlock, '<expr>',
        new go.Binding('text', 'text').makeTwoWay(),
        {
          name: CONTEXT_MENU.textEditor.id,
          editable: false,
          isMultiline: true,
          maxLines: 1,
          textAlign: 'center',
          margin: new go.Margin(6, 6, 3, 6),
          stroke: '#DDD',
          background: backgroundColor,
          font: TEXT_NODE_FONT,
          textEditor: EDITORS.expr.HTMLInfo,
        }
      )
    ),

    $(go.Panel, 'Horizontal',
      new go.Binding('itemArray', 'parports'),
      {
        row: 0,
        column: 1,
        stretch: go.GraphObject.Fill,
        itemTemplate: PARAM_ARRAYED_PORTS[colorMode],
      }
    ),

    $(go.Panel, 'Vertical',
      new go.Binding('itemArray', 'outports'),
      {
        row: 1,
        column: 2,
        itemTemplate: OUTPUT_ARRAYED_PORTS[colorMode],
      }
    )
  );

  node.doubleClick = openTextEditor;

  node.contextMenu = makeTextNodeContextMenu(data);

  return node;
};

const COMMENT_COLOR_SOURCE_PROP = 'color';
const changeColor = (obj: go.GraphObject) => {
  const diagram = obj.part?.diagram;
  const node = obj.part;
  if (!diagram || !node) return;

  const model = getModelFromDiagram(diagram);

  model.startTransaction('change color');

  const { data } = node;
  let newColor = '#C0C0C0';
  switch (data.color ?? '#C0C0C0') {
    case '#C0C0C0':
      newColor = '#FFFFFF';
      break;
    case '#FFFFFF':
      newColor = '#FFFF00';
      break;
    case '#FFFF00':
      newColor = '#FFA500';
      break;
    case '#FFA500':
      newColor = '#FF0000';
      break;
    case '#FF0000':
      newColor = '#00FF00';
      break;
    case '#00FF00':
      newColor = '#00FFFF';
      break;
    case '#00FFFF':
      newColor = '#C0C0C0';
      break;
    default:
      break;
  }
  model.setDataProperty(data, COMMENT_COLOR_SOURCE_PROP, newColor);

  model.commitTransaction('change color');
};

export const makeCommentTemplate = () => {
  // prettier-ignore
  const node = $(go.Node, 'Auto',
    BINDING_LOCATION_TWO_WAY.copy(),
    new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(roundAndStringifySize),
    {
      selectionAdorned: true,
      resizable: true,
      locationSpot: go.Spot.TopLeft,
      background: '#222',
    },

    $(go.TextBlock, '<comment>',
      new go.Binding('text', 'text').makeTwoWay(),
      new go.Binding('stroke', COMMENT_COLOR_SOURCE_PROP).makeTwoWay(),
      {
        name: CONTEXT_MENU.textEditor.id,
        editable: false,
        isMultiline: true,
        textAlign: 'center',
        verticalAlignment: go.Spot.Center,
        stroke: '#C0C0C0',
        background: '#222',
        font: NODE_FONT,
        margin: 4,
        textEditor: EDITORS.comment.HTMLInfo,
      }
    )
  );

  node.doubleClick = openTextEditor;

  const menuButtons = makeBaseButtonArray();
  addOpenTextEditorButton(menuButtons, 'comment');
  addChangeColorButton(menuButtons);
  node.contextMenu = makeContextMenu(menuButtons);

  return node;
};

export const makeInletTemplate = () => {
  const node = $(
    go.Node,
    'Horizontal',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
    },

    $(
      go.Panel,
      'Auto',
      {
        defaultStretch: go.GraphObject.Horizontal,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(INLET_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: INLET_PORT_FILL_COLOR,
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        {
          defaultStretch: go.GraphObject.Horizontal,
        },

        makeNodeNameTag('inlet'),

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          maxLines: 1,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          margin: new go.Margin(4),
        })
      )
    ),

    $(
      go.Shape,
      'TriangleRight',
      new go.Binding('portId', 'outports', (ports) => ports[0].pid),
      {
        toolTip: OUTPORT_TOOLTIP,
        fromSpot: go.Spot.RightCenter,
        fromLinkable: true,
        desiredSize: new go.Size(20, 20),
        fill: INLET_PORT_FILL_COLOR,
        strokeWidth: 0,
        cursor: 'pointer',
      }
    )
  );

  return node;
};

export const makeOutletTemplate = () => {
  const node = $(
    go.Node,
    'Horizontal',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
      margin: new go.Margin(0, 15),
    },

    $(
      go.Shape,
      'TriangleRight',
      new go.Binding('portId', 'inports', (ports) => ports[0].pid),
      {
        toolTip: INPORT_TOOLTIP,
        toSpot: go.Spot.LeftCenter,
        toLinkable: true,
        desiredSize: new go.Size(20, 20),
        fill: INLET_PORT_FILL_COLOR,
        strokeWidth: 0,
        cursor: 'pointer',
      }
    ),

    $(
      go.Panel,
      'Auto',
      {
        defaultStretch: go.GraphObject.Horizontal,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(INLET_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: INLET_PORT_FILL_COLOR,
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        {
          defaultStretch: go.GraphObject.Horizontal,
        },

        makeNodeNameTag('outlet'),

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          margin: new go.Margin(4),
        })
      )
    )
  );

  return node;
};

export const makeParamTemplate = () => {
  const node = $(
    go.Node,
    'Vertical',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
    },

    $(
      go.Panel,
      'Auto',
      {
        minSize: new go.Size(NODE_BASE_WIDTH, 30),
        defaultStretch: go.GraphObject.Horizontal,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(PARAM_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: PARAM_PORT_FILL_COLOR,
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        { defaultStretch: go.GraphObject.Horizontal },

        makeNodeNameTag('param'),

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          minSize: new go.Size(NODE_BASE_WIDTH, 25),
          margin: new go.Margin(0, 4),
        })
      )
    ),

    $(
      go.Shape,
      'TriangleDown',
      new go.Binding('portId', 'argports', (ports) => ports[0].pid),
      {
        toolTip: ARGPORT_TOOLTIP,
        fromSpot: go.Spot.BottomCenter,
        fromLinkable: true,
        desiredSize: new go.Size(20, 20),
        fill: PARAM_PORT_FILL_COLOR,
        strokeWidth: 0,
        cursor: 'pointer',
      }
    )
  );

  return node;
};

export const makeReturnTemplate = () => {
  const node = $(
    go.Node,
    'Vertical',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
    },

    $(
      go.Shape,
      'TriangleDown',
      new go.Binding('portId', 'parports', (ports) => ports[0].pid),
      {
        toolTip: PARPORT_TOOLTIP,
        toSpot: go.Spot.TopCenter,
        toLinkable: true,
        desiredSize: new go.Size(20, 20),
        fill: PARAM_PORT_FILL_COLOR,
        strokeWidth: 0,
        cursor: 'pointer',
      }
    ),

    $(
      go.Panel,
      'Auto',
      {
        minSize: new go.Size(NODE_BASE_WIDTH, 30),
        defaultStretch: go.GraphObject.Horizontal,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(PARAM_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: PARAM_PORT_FILL_COLOR,
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        { defaultStretch: go.GraphObject.Horizontal },

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          minSize: new go.Size(NODE_BASE_WIDTH, 25),
          margin: new go.Margin(0, 4),
        }),

        makeNodeNameTag('return', { isTop: false })
      )
    )
  );

  return node;
};

export const makeConditionalOutletTemplate = (type: string, colorMode: ColorMode) => {
  const data = SPECIAL_NODE_DICTIONARY[type];

  const node = $(
    go.Node,
    'Table',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
      margin: new go.Margin(0, 15),
    },

    $(go.Panel, 'Horizontal', new go.Binding('itemArray', 'parports'), {
      row: 0,
      column: 1,
      stretch: go.GraphObject.Fill,
      itemTemplate: PARAM_ARRAYED_PORTS[colorMode],
    }),

    $(go.Panel, 'Vertical', new go.Binding('itemArray', 'inports'), {
      row: 1,
      column: 0,
      itemTemplate: $(
        go.Panel,
        new go.Binding('portId', 'pid'),
        {
          toolTip: ARRAYED_PORT_TOOLTIP,
          desiredSize: new go.Size(9, 9),
          cursor: 'pointer',
          toLinkable: true,
          toSpot: go.Spot.Left,
          alignment: go.Spot.TopLeft,
        },

        $(
          go.Shape,
          'TriangleRight',

          // Color selector/condition port differently
          new go.Binding('fill', 'itemIndex', (i) =>
            i === 0 ? '#c0c2e5' : INLET_PORT_FILL_COLOR
          ).ofObject(),

          {
            desiredSize: new go.Size(9, 9),
            strokeWidth: 0,
          }
        )
      ),
    }),

    $(
      go.Panel,
      'Auto',
      {
        row: 1,
        column: 1,
        stretch: go.GraphObject.Fill,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(INLET_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: INLET_PORT_FILL_COLOR,
          minSize: new go.Size(NaN, NODE_BASE_HEIGHT),
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        {
          stretch: go.GraphObject.Fill,
          defaultStretch: go.GraphObject.Horizontal,
        },

        makeNodeNameTag(type),

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          margin: new go.Margin(4),
        })
      )
    )
  );

  const menuButtons = makeBaseButtonArray();
  addOpenRenameModalButton(menuButtons);
  addPortButtons(menuButtons, data);

  node.contextMenu = makeContextMenu(menuButtons);

  return node;
};

export const makeSendTemplate = () => {
  const node = $(
    go.Node,
    'Vertical',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
    },

    $(
      go.Shape,
      'TriangleDown',
      new go.Binding('portId', 'parports', (ports) => ports[0].pid),
      {
        toolTip: PARPORT_TOOLTIP,
        toSpot: go.Spot.TopCenter,
        toLinkable: true,
        desiredSize: new go.Size(20, 20),
        fill: GLOBAL_PORT_FILL_COLOR,
        strokeWidth: 0,
        cursor: 'pointer',
      }
    ),

    $(
      go.Panel,
      'Auto',
      {
        minSize: new go.Size(NODE_BASE_WIDTH, 30),
        defaultStretch: go.GraphObject.Horizontal,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(GLOBAL_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: GLOBAL_PORT_FILL_COLOR,
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        { defaultStretch: go.GraphObject.Horizontal },

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          minSize: new go.Size(NODE_BASE_WIDTH, 25),
          margin: new go.Margin(0, 4),
        }),

        makeNodeNameTag('send', { isTop: false })
      )
    )
  );

  return node;
};

export const makeReceiveTemplate = () => {
  const node = $(
    go.Node,
    'Vertical',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      background: 'transparent', // makes the background selectable -- critical here
    },

    $(
      go.Panel,
      'Auto',
      {
        minSize: new go.Size(NODE_BASE_WIDTH, 30),
        defaultStretch: go.GraphObject.Horizontal,
        toolTip: NODE_TOOLTIP,
      },

      $(
        go.Shape,
        'RoundedRectangle',
        new go.Binding('stroke', '_message', getStrokeConverter(GLOBAL_PORT_FILL_COLOR)),
        new go.Binding(
          'strokeWidth',
          '_message',
          getStrokeWidthConverter(PORT_NODE_STROKE_WIDTH, 0)
        ),
        {
          strokeWidth: 0,
          fill: GLOBAL_PORT_FILL_COLOR,
          spot1: go.Spot.TopLeft,
          spot2: go.Spot.BottomRight,
        }
      ),

      $(
        go.Panel,
        'Vertical',
        { defaultStretch: go.GraphObject.Horizontal },

        makeNodeNameTag('receive'),

        $(go.TextBlock, '', new go.Binding('text', 'name').makeTwoWay(), {
          name: CONTEXT_MENU.textEditor.id,
          editable: true,
          isMultiline: false,
          textValidation: (_t: go.TextBlock, _o: string, newName: string) =>
            !!getValidId(newName),
          font: PORT_NODE_FONT,
          stroke: 'white',
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          minSize: new go.Size(NODE_BASE_WIDTH, 25),
          margin: new go.Margin(0, 4),
        })
      )
    ),

    $(
      go.Shape,
      'TriangleDown',
      new go.Binding('portId', 'argports', (ports) => ports[0].pid),
      {
        toolTip: ARGPORT_TOOLTIP,
        fromSpot: go.Spot.BottomCenter,
        fromLinkable: true,
        desiredSize: new go.Size(20, 20),
        fill: GLOBAL_PORT_FILL_COLOR,
        strokeWidth: 0,
        cursor: 'pointer',
      }
    )
  );

  return node;
};

// template for an unrecognized node category.  this gets used most often
// when pkg.json changes and a previously available node is no longer available,
// e.g. because of a change of type.
export const makeUnknownTemplate = () => {
  // prettier-ignore
  const node = $(go.Node, 'Auto',
    BINDING_LOCATION_TWO_WAY.copy(),
    {
      locationSpot: go.Spot.TopLeft,
      toolTip: UNKNOWN_NODE_TOOLTIP,
    },

    $(go.Shape, 'RoundedRectangle',
      {
        stroke: 'red',
        fill: 'black',
      }
    ),

    $(go.TextBlock, '???',
      new go.Binding('text', 'cat'),
      {
        desiredSize: new go.Size(100, NODE_BASE_HEIGHT),
        maxLines: 1,
        overflow: go.TextBlock.OverflowEllipsis,
        textAlign: 'center',
        verticalAlignment: go.Spot.Center,
        stroke: 'white',
        background: 'black',
        font: NODE_FONT,
      }
    )
  );
  node.contextMenu = makeContextMenu();

  return node;
};

/**
 * ----------------------------------------------------------------------------
 * CONTEXT MENU
 * ----------------------------------------------------------------------------
 */

const CONTEXT_KEY = [
  'copy',
  'cut',
  'paste',
  'delete',
  'openInspector',
  'closeInspector',
  'assignType',
  'assignDepth',
  'bundle',
  'unbundle',
  'textEditor',
  'changeColor',
  'openRenameModal',
  'selectCircuit',
  'selectInfra',
  'selectConnectedEdges',
  'viewLogs',
  'addPorts',
  'removePorts',
] as const;

export type ContextKey = typeof CONTEXT_KEY[number];

type ContextItem = {
  id: ContextKey;
  text: String;
  shouldBindToVisible?: boolean;
};

export const CONTEXT_MENU: Record<ContextKey, ContextItem> = {
  copy: { id: 'copy', text: 'Copy', shouldBindToVisible: true },
  cut: { id: 'cut', text: 'Cut', shouldBindToVisible: true },
  paste: { id: 'paste', text: 'Paste', shouldBindToVisible: true },
  delete: { id: 'delete', text: 'Delete', shouldBindToVisible: true },
  openInspector: {
    id: 'openInspector',
    text: 'Open inspector',
    shouldBindToVisible: true,
  },
  closeInspector: {
    id: 'closeInspector',
    text: 'Close inspector',
    shouldBindToVisible: true,
  },
  assignType: { id: 'assignType', text: 'Assign edge type', shouldBindToVisible: true },
  assignDepth: {
    id: 'assignDepth',
    text: 'Assign edge depth',
    shouldBindToVisible: true,
  },
  bundle: { id: 'bundle', text: 'Bundle nodes', shouldBindToVisible: true },
  unbundle: { id: 'unbundle', text: 'Unbundle call node', shouldBindToVisible: true },
  textEditor: { id: 'textEditor', text: 'Open text editor' },
  changeColor: { id: 'changeColor', text: 'Change color' },
  openRenameModal: { id: 'openRenameModal', text: 'Rename node' },
  selectCircuit: { id: 'selectCircuit', text: 'Select circuit' },
  selectInfra: { id: 'selectInfra', text: 'Select infrastructure' },
  selectConnectedEdges: {
    id: 'selectConnectedEdges',
    text: 'Select connected edges',
    shouldBindToVisible: true,
  },
  viewLogs: { id: 'viewLogs', text: 'View logs', shouldBindToVisible: true },
  // generate text dynamically for each node in addPortsButton
  addPorts: { id: 'addPorts', text: '' },
  removePorts: { id: 'removePorts', text: '' },
} as const;

const makeBaseButtonArray = () => {
  const buttons: go.Panel[] = [];

  const cutButton = makeMenuButtonTemplate(
    CONTEXT_MENU.cut,
    (_: go.InputEvent, obj: go.GraphObject) => {
      obj.diagram?.commandHandler.cutSelection();
    }
  );
  buttons.push(cutButton);

  const copyButton = makeMenuButtonTemplate(
    CONTEXT_MENU.copy,
    (_: go.InputEvent, obj: go.GraphObject) => {
      obj.diagram?.commandHandler.copySelection();
    }
  );
  buttons.push(copyButton);

  const pasteButton = makeMenuButtonTemplate(
    CONTEXT_MENU.paste,
    (_: go.InputEvent, obj: go.GraphObject) => {
      obj.diagram?.commandHandler.pasteSelection();
    }
  );
  buttons.push(pasteButton);

  const deleteButton = makeMenuButtonTemplate(
    CONTEXT_MENU.delete,
    (_: go.InputEvent, obj: go.GraphObject) => {
      obj.diagram?.commandHandler.deleteSelection();
    }
  );
  buttons.push(deleteButton);

  buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.bundle, () => {}));
  buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.unbundle, () => {}));
  buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.assignType, () => {}));
  buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.assignDepth, () => {}));

  buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.selectConnectedEdges, () => {}));

  const openInspectorButton = makeMenuButtonTemplate(
    CONTEXT_MENU.openInspector,
    () => {}
  );
  buttons.push(openInspectorButton);

  const closeInspectorButton = makeMenuButtonTemplate(
    CONTEXT_MENU.closeInspector,
    () => {}
  );
  buttons.push(closeInspectorButton);

  const viewLogsButton = makeMenuButtonTemplate(CONTEXT_MENU.viewLogs, () =>
    showTextEditor('log')
  );
  buttons.push(viewLogsButton);

  return buttons;
};

const addPortButtons = (buttons: go.Panel[], data?: TempDictionaryEntry) => {
  if (!data) return;
  const params = data.params ?? [];

  data.dynports?.forEach((dynObj) => {
    const portsName = getButtonName(dynObj);

    const addPortsContextItem: ContextItem = {
      ...CONTEXT_MENU.addPorts,
      text: `Add ${portsName}`,
    };
    const addButton = makeMenuButtonTemplate(addPortsContextItem, (_, obj) => {
      addPorts((obj.part as go.Adornment).adornedObject!, params, dynObj);
    });

    const removePortsContextItem: ContextItem = {
      ...CONTEXT_MENU.removePorts,
      text: `Remove ${portsName}`,
    };
    const deleteButton = makeMenuButtonTemplate(removePortsContextItem, (_, obj) => {
      deletePorts((obj.part as go.Adornment).adornedObject!, params, dynObj);
    });

    buttons.push(addButton);
    buttons.push(deleteButton);
  });
};

const addOpenTextEditorButton = (buttons: go.Panel[], variant: EditorVariant) => {
  const openTextEditorButton = makeMenuButtonTemplate(
    CONTEXT_MENU.textEditor,
    (_, obj) => {
      const key = obj.part?.key;
      // get the GraphObject of the TextBlock
      const textEditor = obj.diagram
        ?.findNodeForKey(key)
        ?.findObject(CONTEXT_MENU.textEditor.id);
      if (!textEditor || !key) return;
      showTextEditor(variant, textEditor, key);
    }
  );
  buttons.push(openTextEditorButton);
};

const addChangeColorButton = (buttons: go.Panel[]) => {
  const changeColorButton = makeMenuButtonTemplate(CONTEXT_MENU.changeColor, (_, obj) =>
    changeColor(obj)
  );
  buttons.push(changeColorButton);
};

const addOpenRenameModalButton = (buttons: go.Panel[]) => {
  buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.openRenameModal, () => {}));
};

export const makeRenameableNodeContextMenu = (type: string) => {
  const data = SPECIAL_NODE_DICTIONARY[type];
  const buttons = makeBaseButtonArray();

  addOpenRenameModalButton(buttons);
  addPortButtons(buttons, data);

  return makeContextMenu(buttons);
};

export const makeCallNodeContextMenu = (cat: CircuitCategory) => {
  const buttons = makeBaseButtonArray();

  if (cat === 'circuit') {
    buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.selectCircuit, () => {}));
  } else if (cat === 'infrastructure') {
    buttons.push(makeMenuButtonTemplate(CONTEXT_MENU.selectInfra, () => {}));
  }

  return makeContextMenu(buttons);
};

const makeTextNodeContextMenu = (data?: TempDictionaryEntry) => {
  const buttons = makeBaseButtonArray();
  addOpenTextEditorButton(buttons, 'expr');
  addPortButtons(buttons, data);
  return makeContextMenu(buttons);
};

export const makeContextMenu = (buttons: go.Panel[] = makeBaseButtonArray()) => {
  return $(go.Adornment, 'Vertical', ...buttons);
};

// Template for context menu buttons
const makeMenuButtonTemplate = (
  contextItem: ContextItem,
  onClick: (e: go.InputEvent, obj: go.GraphObject) => void
) => {
  const contextMenuButton = $(
    'ContextMenuButton',
    { click: onClick },

    $(go.TextBlock, contextItem.text, {
      name: contextItem.id,
      stroke: 'black',
      font: NODE_FONT,
      alignment: go.Spot.LeftCenter,
      margin: new go.Margin(0, 10, 0, 10),
    })
  );

  /*
   bind the id of the context menu to "visible".
   allows control of whether to show a specific context menu by setDataProperty of the active model.
   only bind 'visible' using id to the model if the context menu is used in the active model scope.
  */
  if (contextItem.shouldBindToVisible) {
    contextMenuButton.bind(new go.Binding('visible', contextItem.id).ofModel());
  }

  return contextMenuButton;
};

const getButtonName = (dynObj: DynPorts) => {
  const buttonTypes = Object.keys(dynObj).map(getButtonType);

  if (buttonTypes.length === 0) {
    return 'unknown port(s)';
  }

  return buttonTypes.length > 1
    ? `${buttonTypes.join('/')} ports`
    : `${buttonTypes[0]} port`;
};

const getButtonType = (portType: string) => {
  switch (portType) {
    case 'inports':
      return 'input';
    case 'outports':
      return 'output';
    case 'parports':
      return 'parameter';
    default:
      return 'unknown';
  }
};

export const setContextMenuItemVisible = (
  diagramModel: go.GraphLinksModel,
  contextKey: ContextKey,
  visible: boolean
) => {
  const { skipsUndoManager } = diagramModel;

  diagramModel.skipsUndoManager = true;
  diagramModel.startTransaction();

  diagramModel.setDataProperty(
    diagramModel.modelData,
    CONTEXT_MENU[contextKey].id,
    visible
  );

  diagramModel.commitTransaction();
  diagramModel.skipsUndoManager = skipsUndoManager;
};
