import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AxiosRegionInterceptor } from 'components/AxiosRegionInterceptor';
import { DashboardPageLayout } from 'components/DashboardPageLayout';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { PrivateRoute } from 'components/PrivateRoute';
import { UserContextProvider } from 'context/useUserContext';
import { ProjectContextProvider } from 'context/useProjectContext';
import { DiagramContextProvider } from 'views/EditorPage/context/useDiagramContext';

import { theme } from 'theme';
import './App.css';
import { DebuggerContextProvider } from 'views/EditorPage/context/useDebuggerContext';
import ConnectionsPage from 'views/ConnectionsPage';
import { SubscriptionPlanInterceptor } from 'views/ConnectionsPage/SubscriptionPlanInterceptor';

const LoginPage = lazy(() => import('./views/LoginPage'));
const RegisterPage = lazy(() => import('./views/RegisterPage'));
const ResetPasswordPage = lazy(() => import('./views/ResetPasswordPage'));
const ConfirmPasswordPage = lazy(() => import('./views/ConfirmPasswordPage'));
const VerifyEmailPage = lazy(() => import('./views/VerifyEmailPage'));
const AccountPage = lazy(() => import('./views/AccountPage'));
const PlanPage = lazy(() => import('./views/PlanPage'));
const DashboardPage = lazy(() => import('./views/DashboardPage'));
const EditorPage = lazy(() => import('./views/EditorPage'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // set stale time to 5 minutes
      staleTime: 1000 * 60 * 5,
      retry: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    // element: <Outlet />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: 'confirm-password',
        element: <ConfirmPasswordPage />,
      },
      {
        path: 'verify-email',
        element: <VerifyEmailPage />,
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <DashboardPageLayout />,
            children: [
              {
                path: 'dashboard',
                element: <DashboardPage />,
                handle: { heading: 'Hello!' },
              },
              {
                path: 'account',
                element: <AccountPage />,
              },
              {
                path: 'plan',
                element: <PlanPage />,
              },
              {
                path: 'connections',
                element: (
                  <SubscriptionPlanInterceptor>
                    <ConnectionsPage />
                  </SubscriptionPlanInterceptor>
                ),
              },
            ],
          },
          {
            path: 'project',
            element: (
              <ProjectContextProvider>
                <DiagramContextProvider>
                  <DebuggerContextProvider>
                    <EditorPage />
                  </DebuggerContextProvider>
                </DiagramContextProvider>
              </ProjectContextProvider>
            ),
          },
          {
            index: true,
            element: <Navigate to="/project" />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
]);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading ...</div>}>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <AxiosRegionInterceptor>
                <div className="App">
                  <RouterProvider router={router} />
                </div>
              </AxiosRegionInterceptor>
            </UserContextProvider>
          </QueryClientProvider>
        </Suspense>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
