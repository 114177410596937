import React, { useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  faBars,
  faCloud,
  faCreditCard,
  faHome,
  faLaptopCode,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useScreenSize } from 'hooks/useScreenSize';

const LINKS = [
  { title: 'Dashboard', to: '/dashboard', icon: faHome },
  { title: 'Account', to: '/account', icon: faUser },
  { title: 'Plan and Billing', to: '/plan', icon: faCreditCard },
  { title: 'Connections', to: '/connections', icon: faCloud },
  // { title: 'Projects', to: '/projects' },
  { title: 'Editor', to: '/project', icon: faLaptopCode },
];

const LinkList = () => {
  const { pathname } = useLocation();

  return (
    <>
      {LINKS.map(({ to, title, icon }) => (
        <Link as={NavLink} to={to} key={title}>
          <Flex alignItems="center" padding={2}>
            <Flex as="span" width="40px" justifyContent="center">
              <FontAwesomeIcon icon={icon} />
            </Flex>
            <Text fontSize="1.25em" color={pathname === to ? 'pink' : undefined}>
              {title}
            </Text>
          </Flex>
        </Link>
      ))}
    </>
  );
};

export const NavSidebar = () => {
  const { isMobile } = useScreenSize();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (isMobile) {
    return (
      <>
        <IconButton
          ref={buttonRef}
          onClick={onOpen}
          icon={<FontAwesomeIcon icon={faBars} />}
          aria-label="Menu"
          position="absolute"
          marginTop={3}
        />
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={buttonRef}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>

            <DrawerBody>
              <LinkList />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Flex as="aside" direction="column" width="300px" padding={3}>
      <LinkList />
    </Flex>
  );
};
