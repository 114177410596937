import * as go from 'gojs';
import { Edge, Port } from 'types/transparency';
// eslint-disable-next-line import/no-cycle
import {
  addTypeToTypeMap,
  createNodeTypeMap,
  decomposeJSONType,
  getContentTypeFromTypedId,
  getContextFreeContentTypeFromTypedId,
  getUserInputType,
  getUserOutputType,
  isDynamicPort,
  isStaticPort,
  isWellDefined,
  joinType,
} from 'utils/transparency/types';
// eslint-disable-next-line import/no-cycle
import { getModelFromDiagram } from './diagram';
// eslint-disable-next-line import/no-cycle
import { applyTypeMap, changePortType } from './port';
// eslint-disable-next-line import/no-cycle
import { getStrokeConverter, makeContextMenu } from './templates';
// eslint-disable-next-line import/no-cycle
import { LINK_TOOLTIP } from './tooltip';

/**
 * Two ports can be linked if the join of their congruence IDs is well-defined.
 * GoJS itself enforces the basic from-linkable and to-linkable restrictions.
 */
export const canLinkPorts = (
  fromNode: go.Node,
  fromPort: go.GraphObject,
  toNode: go.Node,
  toPort: go.GraphObject
) => {
  // prettier-ignore
  const isSamePortType = (isDynamicPort(fromPort.portId) && isDynamicPort(toPort.portId))
    || (isStaticPort(fromPort.portId) && isStaticPort(toPort.portId));

  if (!isSamePortType) return false;

  const from = getContextFreeContentTypeFromTypedId(fromPort.portId);
  const to = getContextFreeContentTypeFromTypedId(toPort.portId);

  const join = joinType(from, to);
  return isWellDefined(join);
};

const setLinkType = (model: go.GraphLinksModel, link: go.Link, type: string) => {
  const { fromNode, fromPortId, toNode, toPortId } = link;

  const fromData = fromNode!.data;
  const toData = toNode!.data;

  // use context-sensitive types as keys in the type map below
  const fromType = getContentTypeFromTypedId(fromPortId);
  const toType = getContentTypeFromTypedId(toPortId);

  if (toType.indexOf('?') >= 0) {
    switch (toData.cat) {
      case 'detuple':
      case 'unpack': {
        changeDetupleType(model, toNode!, type);
        break;
      }

      case 'retuple':
      case 'repack': {
        if (toPortId === 'input$X?') changeRetupleType(model, toNode!, type);
        break;
      }

      case 'tuple':
      case 'pack': {
        // Do nothing when the input side of a tuple/pack changes
        break;
      }

      default: {
        // Change the directly connected port type
        changePortType(model, toData, toPortId, type);

        // Use a typemap to update all port types
        const map = createNodeTypeMap(toData); // current map
        addTypeToTypeMap(toType, type, map); // new binding
        applyTypeMap(model, toData, map);
        break;
      }
    }
  }

  if (fromType.indexOf('?') >= 0) {
    switch (fromData.cat) {
      case 'tuple':
      case 'pack': {
        changeTupleType(model, fromNode!, type);
        break;
      }

      case 'retuple':
      case 'repack': {
        changeRetupleType(model, fromNode!, type);
        break;
      }

      case 'detuple':
      case 'unpack': {
        // do nothing when the output side of a detuple/unpack changes
        break;
      }

      default: {
        // Change the directly connected port type
        changePortType(model, fromData, fromPortId, type);

        // Use a typemap to update all port types
        const map = createNodeTypeMap(fromData); // current map
        addTypeToTypeMap(fromType, type, map); // new binding
        applyTypeMap(model, fromData, map);
        break;
      }
    }
  }
};

export const linkedOrRelinkedListener = (event: go.DiagramEvent) => {
  const { name } = event;
  if (!['LinkDrawn', 'LinkRelinked'].includes(name)) return;

  const model = getModelFromDiagram(event.diagram);

  const isForwards =
    name === 'LinkDrawn'
      ? event.diagram.toolManager.linkingTool.isForwards
      : event.diagram.toolManager.relinkingTool.isForwards;

  const link = event.subject;
  const { fromNode, fromPortId, toNode, toPortId } = link;

  const fromStaticType = getContextFreeContentTypeFromTypedId(fromPortId);
  const toStaticType = getContextFreeContentTypeFromTypedId(toPortId);

  const fromDynamicType = getUserOutputType(fromNode.data, fromPortId);
  const toDynamicType = getUserInputType(toNode.data, toPortId);

  const staticType = joinType(fromStaticType, toStaticType);
  const dynamicType = joinType(fromDynamicType, toDynamicType);

  if (!isWellDefined(staticType))
    throw new Error(
      `internal: staticType is not well-defined in linkedOrRelinkedListener: ${fromStaticType} ${toStaticType} ${staticType}`
    );

  let type = staticType;

  if (isWellDefined(dynamicType)) {
    // Use the dynamic types from both endpoints if their join is well-defined
    type = dynamicType;
  } else {
    // Otherwise, join the dynamic type of the 'from' endpoint to the
    // static type of the 'to' endpoint of the link the user dragged
    const prop = isForwards
      ? joinType(fromDynamicType, toStaticType)
      : joinType(toDynamicType, fromStaticType);

    // If the join is well-defined, use it; otherwise, use the joins of
    // the static types of the endpoints
    if (isWellDefined(prop)) {
      type = prop;
    }
  }

  // Set the link type to the resulting type; this affects both endpoints
  return setLinkType(model, link, type);
};

export const bindLinkType = (
  model: go.GraphLinksModel,
  links: go.Link[],
  type: string
) => {
  links.forEach((link) => {
    // propagate this type to the ports at both ends of the edge
    setLinkType(model, link, type);

    /*
     * what follows is awkward, because the change of link template
     * must occur within the call to setCategoryForLinkData(), or
     * GoJS will not recreate the link using the new template. we
     * cannot for example simply set edge.type to the new value
     * before calling that function, because GoJS will conclude that
     * the call to setCategoryForLinkData() has no effect.
     * TLDR: this is all about forcing a redraw of the edge.
     */

    // find the old link category, and the new link category that will
    // hold after edge.type is changed, but do not change edge.type yet.
    const edge = link.data as Edge;
    const temp = { ...edge, type };
    const oldcategory = linkTemplateCategory(edge);
    const newcategory = linkTemplateCategory(temp);

    if (oldcategory !== newcategory) {
      edge.temp = type;
      model.setCategoryForLinkData(edge, newcategory);
      delete edge.temp;
      if (type === '?') delete edge.type;
    } else {
      model.setDataProperty(edge, 'type', type);
      if (type === '?') delete edge.type;
    }
  });
};

export const bindLinkDepth = (
  model: go.GraphLinksModel,
  links: go.Link[],
  depth: number
) => {
  links.forEach((link) => {
    const edge = link.data as Edge;

    if (!isDynamicPort(edge.src)) return;

    const temp = { ...edge, depth };
    const oldcategory = linkTemplateCategory(edge);
    const newcategory = linkTemplateCategory(temp);

    if (oldcategory !== newcategory) {
      edge.temp = depth;
      model.setCategoryForLinkData(edge, newcategory);
      delete edge.temp;
      if (depth === 0) delete edge.depth;
    } else {
      model.setDataProperty(edge, 'depth', depth);
      if (depth === 0) delete edge.depth;
    }
  });
};

const makeLinkTemplate = (color: string, width: number, zorder: number) => {
  const $ = go.GraphObject.make;
  const link = $(
    go.Link,

    {
      zOrder: zorder,
      layerName: 'Background',
      routing: go.Link.Orthogonal,
      curve: go.Link.JumpGap,
      corner: 4,
      relinkableFrom: true,
      relinkableTo: true,
      toolTip: LINK_TOOLTIP,
    },

    $(go.Shape, new go.Binding('stroke', '_message', getStrokeConverter(color)), {
      stroke: color,
      strokeWidth: width,
    })
  );

  link.contextMenu = makeContextMenu();

  return link;
};

const LINK = {
  untypedEW: {
    type: 'untypedEW',
    stroke: 'lightslategray',
    width: 2,
    zorder: 1,
  },
  untypedNS: {
    type: 'untypedNS',
    stroke: 'darkslategray',
    width: 1,
    zorder: 2,
  },
  typedEW: {
    type: 'typedEW',
    stroke: 'blue',
    width: 2,
    zorder: 1,
  },
  typedNS: {
    type: 'typedNS',
    stroke: 'blue',
    width: 1,
    zorder: 2,
  },
  depthEW: {
    type: 'depthEW',
    stroke: 'green',
    width: 2,
    zorder: 1,
  },
} as const;

type LinkType = keyof typeof LINK;

export const linkTemplateCategory = (data: go.ObjectData): LinkType => {
  if (isDynamicPort(data.src)) {
    // return (data.type && data.type !== '?') ? 'typedEW' : (data.depth ? 'depthEW' : 'untypedEW');

    if (data.type && data.type !== '?') return 'typedEW';

    if (data.depth) return 'depthEW';

    return 'untypedEW';
  }

  // return (data.type && data.type !== '?') ? 'typedNS' : 'untypedNS';

  if (data.type && data.type !== '?') return 'typedNS';

  return 'untypedNS';
};

export const getLinkStroke = (linkType: LinkType) => LINK[linkType].stroke;

const makeLinkTemplates = () => {
  const map = new go.Map<LinkType, go.Link>();

  Object.values(LINK).forEach(({ type, stroke, width, zorder }) =>
    map.add(type, makeLinkTemplate(stroke, width, zorder))
  );

  return map;
};

export const LINK_TEMPLATE_MAP = makeLinkTemplates();

export const changeTupleType = (
  model: go.GraphLinksModel,
  node: go.Node,
  type: string
) => {
  const { data } = node;
  const { cat } = data;

  const { types, names } = decomposeJSONType(type, cat === 'pack');

  const numberOfNewInports = types.length;
  const inports: Port[] = new Array(numberOfNewInports);

  for (let i = 0; i < numberOfNewInports; i++) {
    const name = names[i] || `in${i}`;

    inports[i] = {
      pid: `${name}$X?`, // context-free
      type: types[i],
    };
  }

  const outports: Port[] = [{ pid: 'output$Y?', type }];

  model.setDataProperty(data, 'inports', inports);
  model.setDataProperty(data, 'outports', outports);

  // Force a redraw of incoming edges
  const links = node.findLinksInto();

  links.each((link) => {
    // eslint-disable-next-line no-shadow
    const { data } = link;
    const { dst } = data;

    model.setDataProperty(data, 'dst', '--none--'); // port name that is certain to be unused
    model.setDataProperty(data, 'dst', dst); // and set it immediately back.
  });
};

export const changeRetupleType = (
  model: go.GraphLinksModel,
  node: go.Node,
  type: string
) => {
  const { data } = node;
  const { cat } = data;
  const { types, names } = decomposeJSONType(type, cat === 'repack');

  const numberOfNewInports = types.length;
  const inports: Port[] = new Array(1 + numberOfNewInports);

  inports[0] = { pid: 'input$X?', type };

  for (let i = 0; i < numberOfNewInports; i++) {
    const name = names[i] || `in${i}`;

    inports[i + 1] = {
      pid: `${name}$X?`, // context-free
      type: types[i],
    };
  }

  const outports: Port[] = [{ pid: 'output$Y?', type }];

  model.setDataProperty(data, 'inports', inports);
  model.setDataProperty(data, 'outports', outports);

  // Force a redraw of incoming edges
  const links = node.findLinksInto();

  links.each((link) => {
    // eslint-disable-next-line no-shadow
    const { data } = link;
    const { dst } = data;

    model.setDataProperty(data, 'dst', '--none--'); // port name that is certain to be unused
    model.setDataProperty(data, 'dst', dst); // and set it immediately back.
  });
};

export const changeDetupleType = (
  model: go.GraphLinksModel,
  node: go.Node,
  type: string
) => {
  const { data } = node;
  const { cat } = data;
  const { types, names } = decomposeJSONType(type, cat === 'unpack');

  const numberOfNewOutPorts = types.length;
  const outports: Port[] = new Array(numberOfNewOutPorts);

  for (let i = 0; i < numberOfNewOutPorts; i++) {
    const name = names[i] || `out${i}`;

    outports[i] = {
      pid: `${name}$Y?`, // context-free
      type: types[i],
    };
  }

  const inports: Port[] = [{ pid: 'input$X?', type }];

  model.setDataProperty(data, 'outports', outports);
  model.setDataProperty(data, 'inports', inports);

  // Force a redraw of outgoing edges
  const links = node.findLinksOutOf();

  links.each((link) => {
    // eslint-disable-next-line no-shadow
    const { data } = link;
    const { src } = data;

    model.setDataProperty(data, 'src', '--none--'); // port name that is certain to be unused
    model.setDataProperty(data, 'src', src); // and set it immediately back.
  });
};
