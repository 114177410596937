import {
  cloudStorageDelete,
  cloudStorageGet,
  cloudStorageList,
  cloudStorageMove,
  cloudStoragePut,
  cloudStoragePutFolder,
  getCloudKeySet,
} from 'services/api/CloudStorage';
import {
  ExamplePathMap,
  getExample,
  getExamplePaths as _getExamplePaths,
} from 'services/api/Example';
import { IndexedDBService } from 'services/storage/IndexedDB';
import * as LocalStorage from 'services/storage/LocalStorage';
import { Project, StorageFolder, StorageProject } from 'types/project';
import { CoreModel } from 'types/transparency';
import {
  getProjectFromModels,
  isValidFolderName,
  isValidProjectName,
} from 'utils/project';

export type StorageType = 'INDEXED_DB' | 'CLOUD';

export class StorageServiceClass {
  private indexedDBService = new IndexedDBService<StorageProject | StorageFolder>(
    'CoreographProjects',
    1,
    'ProjectStore'
  );

  private cloudProjectKeys = new Set<string>();

  private examplePaths: ExamplePathMap | undefined;

  /**
   * Fetches project with the given `name` from the input `storageType`.
   *
   * @returns Promise that resolves to a Project/undefined
   */
  async fetchProject(
    name: string,
    storageType: StorageType
  ): Promise<Project | undefined> {
    if (storageType === 'CLOUD') {
      return cloudStorageGet(name).then((cloudStorageProject) =>
        getProjectFromModels(cloudStorageProject, name)
      );
    }

    // storageType === 'INDEXED_DB'
    return new Promise((resolve, reject) => {
      if (!this.indexedDBService.isSupported()) {
        return reject(new Error('This browser does not support IndexedDB'));
      }

      this.indexedDBService.get(
        name,
        (result) => {
          if (!result) return reject(new Error('Failed to retrieve project'));
          return resolve(getProjectFromModels(result, name));
        },
        () => reject(new Error('Failed to retrieve project'))
      );
    });
  }

  static saveProjectLocalStorage = (project: Project) => {
    const { name, ...storageProject } = project;

    if (!isValidProjectName(name)) throw new Error('Invalid project name');
    LocalStorage.unsafeSet('@name', name);
    LocalStorage.unsafeSet('@project', JSON.stringify(storageProject));
  };

  async saveProject(currentProject: Project, storageType: StorageType): Promise<void> {
    const { name, ...storageProject } = currentProject;

    if (!isValidProjectName(name)) throw new Error('Invalid project name');

    if (storageType === 'CLOUD') {
      return cloudStoragePut(name, storageProject).then(() => {
        this.cloudProjectKeys.add(name);
      });
    }

    // storageType === 'INDEXED_DB'
    return new Promise((resolve, reject) => {
      if (!this.indexedDBService.isSupported()) {
        return reject(new Error('This browser does not support IndexedDB'));
      }

      this.indexedDBService.put(
        name,
        storageProject,
        () => {
          this.indexedDBService.storageKeys.add(name);
          resolve();
        },
        () => {
          reject(new Error('IndexedDB put failed'));
        }
      );
    });
  }

  async saveFolder(name: string, storageType: StorageType): Promise<void> {
    // Folder name has to end with '/'
    const folderName = `${name}/`;
    if (!isValidFolderName(folderName)) throw new Error('Invalid folder name');
    if (storageType === 'CLOUD') {
      return cloudStoragePutFolder(folderName).then(() => {
        this.cloudProjectKeys.add(folderName);
      });
    }

    // storageType === 'INDEXED_DB'
    return new Promise((resolve, reject) => {
      if (!this.indexedDBService.isSupported()) {
        return reject(new Error('This browser does not support IndexedDB'));
      }

      const folder: StorageFolder = undefined;

      this.indexedDBService.put(
        folderName,
        folder,
        () => {
          this.indexedDBService.storageKeys.add(folderName);
          resolve();
        },
        () => {
          reject(new Error('IndexedDB put failed'));
        }
      );
    });
  }

  async renameProject(currentName: string, newName: string, storageType: StorageType) {
    if (storageType === 'CLOUD') {
      return cloudStorageMove(currentName, newName).then(() => {
        this.cloudProjectKeys.add(newName);
        this.cloudProjectKeys.delete(currentName);
      });
    }

    return new Promise<void>((resolve, reject) => {
      if (!this.indexedDBService.isSupported()) {
        return reject(new Error('This browser does not support IndexedDB'));
      }

      // storageType === 'INDEXED_DB'
      this.indexedDBService.move(
        currentName,
        newName,
        () => {
          resolve();
        },
        () => {
          reject(new Error('IndexedDB move failed'));
        }
      );
    });
  }

  async deleteProject(name: string, storageType: StorageType) {
    if (storageType === 'CLOUD') {
      return cloudStorageDelete(name).then(() => {
        this.cloudProjectKeys.delete(name);
      });
    }

    // storageType === 'INDEXED_DB'
    return new Promise<void>((resolve, reject) => {
      if (!this.indexedDBService.isSupported()) {
        return reject(new Error('This browser does not support IndexedDB'));
      }

      this.indexedDBService.delete(
        name,
        () => {
          resolve();
        },
        () => {
          reject(new Error('IndexedDB delete failed'));
        }
      );
    });
  }

  async fetchCloudProjectKeys() {
    const cloudKeys = await cloudStorageList();
    this.cloudProjectKeys = getCloudKeySet(cloudKeys);
  }

  getCloudProjectKeys() {
    return this.cloudProjectKeys;
  }

  getCombinedProjectKeys() {
    const keys = new Set<string>();

    this.cloudProjectKeys.forEach((key) => keys.add(key));
    this.indexedDBService.storageKeys.forEach((key) => keys.add(key));

    return keys;
  }

  isCloudProjectKey(key: string) {
    return this.cloudProjectKeys.has(key);
  }

  isLocalProjectKey(key: string) {
    return this.indexedDBService.storageKeys.has(key);
  }

  async getExamplePaths(): Promise<ExamplePathMap | undefined> {
    if (this.examplePaths === undefined) {
      try {
        const paths = await _getExamplePaths();
        this.examplePaths = paths;
      } catch {
        console.error('Error getting example paths');
        return;
      }
    }

    return this.examplePaths;
  }

  async fetchExample(name: string): Promise<Project> {
    if (this.examplePaths === undefined) {
      await this.getExamplePaths();
    }

    if (!this.examplePaths?.[name]) {
      throw new Error(`Couldn't find example ${name}`);
    }

    const example = await getExample(this.examplePaths[name]);
    return getProjectFromModels(example, name);
  }

  /**
   * Gets a default project.
   *
   * @returns Project from localStorage if one exists; otherwise 'welcome' example
   */
  async getDefaultProject() {
    const localProject = LocalStorage.get('@project');

    try {
      if (!localProject) {
        throw new Error('No project saved in localStorage; should load welcome example');
      }

      const project: Project | CoreModel[] = JSON.parse(localProject);
      const name = LocalStorage.get('@name') || '';

      return getProjectFromModels(project, name);
    } catch {
      return this.fetchExample('welcome');
    }
  }
}

export const StorageService = new StorageServiceClass();
