import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useToast } from '@chakra-ui/react';
import { useImmer } from 'use-immer';
import go from 'gojs';
import { WsConnectParam } from 'hooks/Websocket';
import { useDualSession } from 'hooks/Websocket/useDualSession';
import { ProjectService } from 'services/ProjectService';

import { QueryRecord, LogRecord, LOG_VARIANTS, LogData } from './types';
import { addLinkAnimation, getLogData, Log, transformLogEvent } from './util';
import { useDiagramContext } from '../useDiagramContext';

type DebuggerContextValues = {
  isConnected: boolean;
  isRunning: boolean;
  isConnecting: boolean;
  isError: boolean;
  connect: (data: DebugConnectParam) => void;
  close: () => void;
  subscribe: (data: QueryRecord) => void;
  clearLogs: () => void;
  subscriptionKey?: string;
  subscriptionData: LogData;
};

const DebuggerContext = createContext<DebuggerContextValues | undefined>(undefined);
DebuggerContext.displayName = 'DebuggerContext';

type DebuggerContextProviderProps = {
  children: ReactNode;
};

type DebugSubscription = {
  type: 'node' | 'link';
  key: string;
  logData: LogData;
};

type RunConnectParam = {
  logs: Log[];
};

type DebugConnectParam =
  | (WsConnectParam & { mode: 'websocket' })
  | (RunConnectParam & { mode: 'run' });

export const DebuggerContextProvider = ({ children }: DebuggerContextProviderProps) => {
  // format of panel => key => logs
  const nodeRecordRef = useRef<LogRecord>(new Map());
  const linkRecordRef = useRef<LogRecord>(new Map());

  const { activeDiagram } = useDiagramContext();

  const toast = useToast();

  const [isRunning, setIsRunning] = useState(false);

  const [subscription, setSubscription] = useImmer<DebugSubscription | undefined>(
    undefined
  );
  const {
    key: subscriptionKey,
    type: subscriptionType,
    logData: subscriptionData,
  } = subscription ?? { logData: { logs: [] as string[], count: 0 } };

  const subscribe = useCallback((data: QueryRecord) => {
    const { goKey, type } = data;
    const { logData } = getLogData(
      data,
      type === 'node' ? nodeRecordRef.current : linkRecordRef.current
    );
    setSubscription({
      type,
      key: goKey,
      logData,
    });
  }, []);

  const updateSubscription = (newContent: string, addCount: number) => {
    setSubscription((draft) => {
      if (!draft) return;
      draft.logData.logs.push(newContent);
      draft.logData.count += addCount;
    });
  };

  const updateLogs = useCallback(
    (logs: Log[]) => {
      const activeDiagramName = activeDiagram?.model.modelData.name as string | undefined;

      const animation = new go.Animation({
        duration: 10000,
        reversible: true,
        isViewportUnconstrained: false,
        finished: activeDiagram
          ? () => {
              if (!activeDiagram.animationManager.isAnimating)
                activeDiagram.scrollMargin = 400;
            }
          : undefined,
        easing: go.Animation.EaseOutExpo,
      });

      // breaks dragging while diagram is animating if not set at 0 for some reason
      if (activeDiagram) {
        activeDiagram.scrollMargin = 0;
      }

      logs.forEach(({ message }) => {
        const splitData = message.split(':');

        // log format: {identifier}:{rootPanel}:{panel}:{key}:{content}
        // content can include ":" as well
        // keys can be negative or positive
        if (splitData.length < 4 || !/^[-]?[0-9]+$/.test(splitData[3])) return;
        const [identifier, , panel, goKey, ...splitContent] = splitData;
        let content = splitContent.join(':').trimEnd();

        try {
          content = JSON.stringify(JSON.parse(content), null, 2);
        } catch {
          // content is not valid JSON format
        }

        if (identifier === LOG_VARIANTS.trace.identifier) {
          // update record
          const { logData: prevLogData, panelMap } = getLogData(
            { panel, goKey },
            nodeRecordRef.current
          );
          const { logs: prevLogs, count: prevCount } = prevLogData;
          const newLogs = [...prevLogs, content];
          panelMap.set(goKey, { logs: newLogs, count: prevCount + 1 });

          // update active subscription
          if (
            goKey === subscriptionKey &&
            subscriptionType === 'node' &&
            activeDiagramName === panel
          ) {
            updateSubscription(content, 1);
          }
          // no animations for probe nodes
          return;
        }

        if (identifier === LOG_VARIANTS.probe.identifier) {
          const { logData: prevLogData, panelMap } = getLogData(
            { panel, goKey },
            linkRecordRef.current
          );
          const { logs: prevLogs, count: prevCount } = prevLogData;
          const newLogs = [...prevLogs, content];
          panelMap.set(goKey, { logs: newLogs, count: prevCount + 1 });

          // update active subscription
          if (
            goKey === subscriptionKey &&
            subscriptionType === 'link' &&
            activeDiagramName === panel
          ) {
            updateSubscription(content, 1);
          }

          if (activeDiagram && activeDiagramName === panel) {
            addLinkAnimation(animation, activeDiagram, goKey);
          }
        }

        if (identifier === LOG_VARIANTS.probeLimit.identifier) {
          const { logData: prevLogData, panelMap } = getLogData(
            { panel, goKey },
            linkRecordRef.current
          );
          const { logs: prevLogs, count: prevCount } = prevLogData;
          const newContent = `Omitted ${content} logs`;
          panelMap.set(goKey, {
            logs: [...prevLogs, newContent],
            count: prevCount + Number(content),
          });

          // update active subscription
          if (
            goKey === subscriptionKey &&
            subscriptionType === 'link' &&
            activeDiagramName === panel
          ) {
            updateSubscription(newContent, Number(content));
          }

          if (activeDiagram && activeDiagramName === panel) {
            addLinkAnimation(animation, activeDiagram, goKey);
          }
        }
      });
      animation.start();
    },
    [activeDiagram, subscriptionKey, subscriptionType]
  );

  const onMessage = (e: MessageEvent) => {
    const logs = transformLogEvent(e.data);
    if (logs.length === 0) return;
    updateLogs(logs);
  };

  const {
    isConnected,
    isConnecting,
    isError,
    connect: wsConnect,
    close: wsClose,
  } = useDualSession({
    onMessage,
  });

  const runConnect = useCallback(
    (logs: Log[]) => {
      updateLogs(logs);
      setIsRunning(true);
    },
    [updateLogs]
  );

  const clearLogs = useCallback(() => {
    setSubscription((draft) => {
      if (!draft) return;
      draft.logData.logs = [];
      draft.logData.count = 0;
    });
    nodeRecordRef.current.clear();
    linkRecordRef.current.clear();
  }, []);

  const connect = useCallback(
    (data: DebugConnectParam) => {
      clearLogs();
      if (data.mode === 'websocket') wsConnect(data);
      if (data.mode === 'run') runConnect(data.logs);
    },
    [clearLogs, wsConnect, runConnect]
  );

  const close = useCallback(() => {
    // reset diagrams to be in an editable state again
    const diagrams = ProjectService.getDiagrams();
    diagrams.forEach((diagram) => {
      diagram.isReadOnly = false;
    });

    clearLogs();
    wsClose();
    setIsRunning(false);
  }, [clearLogs, wsClose]);

  useEffect(() => {
    if (isConnected) {
      toast({
        id: 'debugger-connect',
        title: 'Connected to the debugger instance',
        status: 'success',
        isClosable: true,
      });
    } else {
      nodeRecordRef.current.clear();
      linkRecordRef.current.clear();
    }
  }, [toast, isConnected]);

  useEffect(() => {
    if (isError) {
      toast({
        id: 'debugger-error',
        title: 'Debugger error',
        description: 'An unexpected error has occurred; please reconnect to the debugger',
        status: 'error',
        isClosable: true,
      });
      close();
    }
  }, [toast, isError, close]);

  const value: DebuggerContextValues = useMemo(
    () => ({
      isRunning,
      isConnected,
      isConnecting,
      isError,
      connect,
      close,
      subscribe,
      clearLogs,
      subscriptionKey,
      subscriptionData,
    }),
    [
      isRunning,
      isConnected,
      isConnecting,
      isError,
      connect,
      close,
      subscribe,
      clearLogs,
      subscriptionKey,
      subscriptionData,
    ]
  );

  return <DebuggerContext.Provider value={value}>{children}</DebuggerContext.Provider>;
};

export const useDebuggerContext = () => {
  const context = useContext(DebuggerContext);

  if (context === undefined) {
    throw new Error('useDebuggerContext must be used within a DebuggerContextProvider');
  }

  return context;
};
