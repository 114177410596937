import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ColorModeScript } from '@chakra-ui/react';
import { enableMapSet } from 'immer';

// Font imports
import '@fontsource/quicksand';
import '@fontsource/raleway';
import '@fontsource/ubuntu-mono';

import App from './App';

import { theme } from './theme';
import './index.css';

// Needed so that Immer can operate on Maps/Sets
// https://immerjs.github.io/immer/installation/#pick-your-immer-version
enableMapSet();

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
