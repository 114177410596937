import * as go from 'gojs';
import { Project, StorageProject } from 'types/project';
import {
  CircuitError,
  CIRCUIT_PANEL_CATEGORIES,
  CoreModel,
  ProjectError,
  ProjectMessages,
  MessageType,
  TEXT_PANEL_CATEGORIES,
  CircuitMeta,
} from 'types/transparency';

import { getValidId } from './id';
import { FOLDERNAME, PROJNAME } from './regex';
import { isInConstArray } from './string';

export const EMPTY_PROJECT_NAME = 'untitled';

export const isStorageProject = (
  model: StorageProject | CoreModel[]
): model is StorageProject => {
  return (model as Project).circuits !== undefined;
};

export const isProject = (project: unknown): project is Project => {
  const castedProject = project as Project;
  return castedProject?.circuits !== undefined && castedProject?.name !== undefined;
};

export const isValidProjectName = (name: string) => PROJNAME.test(name);

export const isValidFolderName = (name: string) => FOLDERNAME.test(name);

/**
 * @param name The name to be tested
 * @param panelNames Optional: Leave empty if checking for valid call node, pass in list of the panel names in the current project if checking for valid panel.
 * @returns True if the given panel/node name is valid; an error string otherwise
 */
export const isValidPanelName = (name: string, panelNames?: string[]) => {
  const id = getValidId(name);

  if (!id)
    return 'Name can contain letters, numbers, "$", and "_" but must start with a letter';

  if (panelNames && panelNames.includes(id)) return `The name "${id}" is already in use`;

  return true;
};

export const isTextModel = (model: unknown): model is CoreModel => {
  if (model === undefined) return false;

  const castedModel = model as CoreModel;
  return (
    castedModel.name !== undefined &&
    castedModel.type !== undefined &&
    isTextType(castedModel.type)
  );
};

export const isTextType = (type: string) => {
  return isInConstArray(type, TEXT_PANEL_CATEGORIES);
};

export const isDiagramModel = (model: unknown): model is go.GraphLinksModel => {
  if (model === undefined) return false;

  const castedModel = model as go.GraphLinksModel;
  return (
    castedModel.name !== undefined &&
    castedModel.modelData !== undefined &&
    isDiagramType(castedModel.modelData.type)
  );
};

export const isDiagramType = (type: string) => {
  return isInConstArray(type, CIRCUIT_PANEL_CATEGORIES);
};

/**
 * Converts CoreModel array representations (e.g. old examples)
 * into the newer Project representation.
 *
 * @param model either a StorageProject or CoreModel[]
 * @param projectName optional override for CoreModel[] name; otherwise default is EMPTY_PROJECT_NAME
 * @returns Project representation
 */
export const getProjectFromModels = (
  model: StorageProject | CoreModel[],
  projectName?: string
): Project => {
  const name = projectName || EMPTY_PROJECT_NAME;

  // Add name to Project if it does not already have one
  return isStorageProject(model) ? { name, ...model } : { circuits: model, name };
};

export const getPanelIndex = (caller: string, panelNames: string[]) => {
  return panelNames.indexOf(caller);
};

/**
 * Replace the args property on call nodes in the input `circuits`
 * with the callargs property. This is to simplify the args type -
 * args is an object/map on call nodes and an array on all other nodes.
 *
 * @param circuits An array of circuits/CoreModels
 * @returns A new array of circuits/CoreModels with replaced call node args
 */
export const replaceCallArgs = (circuits: CoreModel[]) => {
  return circuits.map((circuit) => {
    if (!Array.isArray(circuit.nodes)) return circuit;

    return {
      ...circuit,
      nodes: circuit.nodes.map((node) => {
        const updatedNode = { ...node };

        if (updatedNode.args && !Array.isArray(updatedNode.args)) {
          updatedNode.callargs = updatedNode.args;
          delete updatedNode.args;
        }

        return updatedNode;
      }),
    };
  });
};

export const getHighestPriorityProjectError = (
  projectMessages?: ProjectMessages
): MessageType | undefined => {
  if (!projectMessages) return;

  const { errors, warnings, notices } = projectMessages;

  if (errors?.projectErrors !== undefined) return 'errors';
  if (warnings?.projectErrors !== undefined) return 'warnings';
  if (notices?.projectErrors !== undefined) return 'notices';
};

export const getHighestPriorityCircuitError = (
  panelName: string,
  projectMessages?: ProjectMessages
): MessageType | undefined => {
  if (!projectMessages) return;

  const { errors, warnings, notices } = projectMessages;

  if (errors?.circuitErrors?.[panelName] !== undefined) return 'errors';
  if (warnings?.circuitErrors?.[panelName] !== undefined) return 'warnings';
  if (notices?.circuitErrors?.[panelName] !== undefined) return 'notices';
};

export const hasProjectErrors = (error?: ProjectError) => {
  if (!error) return false;

  if (error.projectErrors) return true;

  if (!error.circuitErrors) return false;

  return Object.values(error.circuitErrors).some(hasCircuitErrors);
};

export const hasCircuitErrors = (circuitError: CircuitError) => {
  const { nodeErrors, edgeErrors, circErrors } = circuitError;

  return nodeErrors || edgeErrors || circErrors;
};

const NUMBER_SUFFIX_REGEX = /_[0-9]+$/;
export const getRenameMap = (newCircuits: CoreModel[], existingProject?: Project) => {
  // Set of current tab names
  const tabNameSet = new Set(existingProject?.circuits.map((circuit) => circuit.name));
  // Set of tab names in circuit to insert
  const insertTabNameSet = new Set(newCircuits.map((circuit) => circuit.name));

  const renameMap = new Map<string, string>();
  newCircuits.forEach((circuit) => {
    const { name } = circuit;
    if (!tabNameSet.has(name)) return;

    const nameStem = name.replace(NUMBER_SUFFIX_REGEX, '');
    const match = NUMBER_SUFFIX_REGEX.exec(name);
    let n = match ? parseInt(match[0].slice(1), 10) + 1 : 2;
    let newName = `${nameStem}_${n}`;

    while (tabNameSet.has(newName) || insertTabNameSet.has(newName)) {
      newName = `${nameStem}_${++n}`;
    }

    renameMap.set(name, newName);
    insertTabNameSet.add(newName);
  });

  return renameMap;
};

export const renameCircuits = (circuits: CoreModel[], renameMap: Map<string, string>) => {
  for (let i = 0; i < circuits.length; i++) {
    const circuit = circuits[i];
    const { name, nodes } = circuit;

    if (renameMap.has(name)) {
      circuit.name = renameMap.get(name)!;
    }

    if (nodes) {
      for (let j = 0; j < nodes.length; j++) {
        const node = nodes[j];
        // Rename call
        if (
          isInConstArray(node.cat, CIRCUIT_PANEL_CATEGORIES) &&
          renameMap.has(node.name!)
        ) {
          node.name = renameMap.get(node.name!);
        }
      }
    }
  }
};

export const isRootCircuitMeta = (meta: unknown): meta is CircuitMeta => {
  if (!meta) return false;

  const castedMeta = meta as CircuitMeta;

  return castedMeta.producer !== undefined;
};
