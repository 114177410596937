import { CallGraph, CallerMap } from 'types/project';
import { CIRCUIT_PANEL_CATEGORIES, CoreModel } from 'types/transparency';
import { isInConstArray } from './string';

export const addCircuitsToCallGraph = (
  callGraph: CallGraph,
  callerMap: CallerMap,
  circuits: CoreModel[]
) => {
  circuits.forEach(({ nodes, name: caller }) => {
    nodes?.forEach(({ cat, key, name: callee }) => {
      if (isInConstArray(cat, CIRCUIT_PANEL_CATEGORIES)) {
        // Non-null assertion for callee because name exists for call nodes
        addCallerToCallGraph(callGraph, callerMap, caller, key, callee!);
      }
    });
  });
};

export const addCallerToCallGraph = (
  callGraph: CallGraph,
  callerMap: CallerMap,
  caller: string,
  key: number,
  callee: string
) => {
  if (!callee) return;

  if (!callGraph.has(callee)) {
    callGraph.set(callee, new Map<string, Set<number>>());
  }

  // Use `!` non-null assertion because callers was made above if it did not exist
  const callers = callGraph.get(callee)!;

  if (!callers.has(caller)) {
    callers.set(caller, new Set<number>());
  }
  callers.get(caller)!.add(key);

  if (!callerMap.has(caller)) {
    callerMap.set(caller, new Set<string>());
  }

  // Use `!` non-null assertion because callees was made above if it did not exist
  const callees = callerMap.get(caller)!;

  callees.add(callee);
};

export const deleteCallerFromCallGraph = (
  callGraph: CallGraph,
  callerMap: CallerMap,
  caller: string,
  key: number,
  callee: string
) => {
  if (!callee) return;

  // note: the early returns work for callerMap also,
  // because it is the inverse of the top level of
  // callGraph.  if (a,b) is not in callGraph, then
  // (b,a) is not in callerMap.

  const callers = callGraph.get(callee);
  if (!callers) return;

  const keys = callers.get(caller);

  if (!keys) return;

  keys.delete(key);
  if (keys.size === 0) callers.delete(caller);
  if (callers.size === 0) callGraph.delete(callee);

  // ! form because callerMap is an inverse of top layer
  // of callGraph; see note above.
  const callees = callerMap.get(caller)!;
  if (keys.size === 0) callees.delete(callee);
  if (callees.size === 0) callerMap.delete(caller);
};

// this is used to delete callee altogether from callGraph and callerMap,
// for the case in which callee is being deleted from the project.
export const deleteCalleeFromCallGraph = (
  callGraph: CallGraph,
  callerMap: CallerMap,
  callee: string
) => {
  // Delete callee and its callers
  callGraph.delete(callee);

  // Delete callee as a caller of other circuits
  callGraph.forEach((callers) => {
    callers.delete(callee);
  });

  // Delete callee as a caller of other circuits
  callerMap.delete(callee);

  // Delete callee as a callee from other circuits
  callerMap.forEach((callees) => {
    callees.delete(callee);
  });
};

/**
 * @param callGraph Mapping from callee name to caller->key map
 * @param panelNames Array of all model/panel names
 * @returns Array of names whose corresponding models are not called by other models
 */
export const getRootPanels = (
  callGraph: CallGraph,
  callerMap: CallerMap,
  panelNames: string[]
) => {
  //  pendingPanels is the set of panels that has to be evaluated for being rootPanels
  //  pendingPanels.size should be 0 by the end of the function call
  const pendingPanels = new Set(panelNames);
  // Finds rootPanels that does not have a caller (not cyclic)
  // Removes each rootPanel and it's callees from pendingPanels
  const rootPanels = panelNames.filter((name) => {
    const isRoot = !callGraph.has(name) || callGraph.get(name)?.size === 0;
    if (isRoot) deleteTreeFromSet(pendingPanels, name, callerMap);
    return isRoot;
  });
  // If pendingPanels.size > 0 then there are cyclic islands that does not have a rootPanel
  // Pick the first panel from the island to be the rootPanel and remove it's callees
  const setIterator = pendingPanels.values();
  while (pendingPanels.size > 0) {
    const rootPanel = setIterator.next().value as string;
    rootPanels.push(rootPanel);
    deleteTreeFromSet(pendingPanels, rootPanel, callerMap);
  }
  return rootPanels;
};

const deleteTreeFromSet = (
  set: Set<string>,
  name: string,
  callerMap: CallerMap,
  treeHistory: Set<string> = new Set()
) => {
  // catches any cylic calls (direct or indirect)
  if (treeHistory.has(name)) return;
  treeHistory.add(name);
  set.delete(name);
  const callees = callerMap.get(name);
  if (callees) {
    callees.forEach((callee) => {
      deleteTreeFromSet(set, callee, callerMap, treeHistory);
    });
  }
};
