import { UseToastOptions } from '@chakra-ui/react';

export const SAVE_FAILED_TOAST: UseToastOptions = {
  position: 'bottom',
  duration: 5000,
  title: 'Unable to stash project locally',
  status: 'error',
  isClosable: true,
  variant: 'solid',
};
