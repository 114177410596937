import React from 'react';
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
} from '@chakra-ui/react';

import { useScreenSize } from 'hooks/useScreenSize';

/**
 * Wrapper around Chakra UI's AlertDialog component that defaults to
 * 'xs' size for mobile screens.
 */
const AlertDialog = (props: AlertDialogProps) => {
  const { size, ...otherProps } = props;

  const { isMobile } = useScreenSize();
  const defaultSize = isMobile ? 'xs' : 'md';

  return <ChakraAlertDialog {...otherProps} size={size ?? defaultSize} />;
};

// Re-export all the AlertDialog imports so they can be imported from this module
export {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
};
export type { AlertDialogProps };
