import { ColorMode } from '@chakra-ui/react';
import * as go from 'gojs';
import {
  CIRCUIT_PANEL_CATEGORIES,
  NodeDictionary,
  RENAMEABLE_NODES,
} from 'types/transparency';
import {
  BASE_NODE_DICTIONARY,
  RESOURCE_DICTIONARY,
  SPECIAL_NODE_DICTIONARY,
} from 'utils/transparency/dictionaries';
// eslint-disable-next-line import/no-cycle
import {
  isResourceCategory,
  isBasicCategory,
  isSpecialCategory,
} from 'utils/transparency/types';

// eslint-disable-next-line import/no-cycle
import {
  makeArgTemplate,
  makeBasicTemplate,
  makeCallTemplate,
  makeCommentTemplate,
  makeConditionalOutletTemplate,
  makeExprTemplate,
  makeInletTemplate,
  makeOutletTemplate,
  makeParamTemplate,
  makeReceiveTemplate,
  makeRenameableNodeContextMenu,
  makeResourceTemplate,
  makeReturnTemplate,
  makeSendTemplate,
  makeSpecialTemplate,
  makeUnknownTemplate,
  makeValueTemplate,
} from './templates';

const COLOR_MODE_ARRAY: ColorMode[] = ['light', 'dark'];

// this is used to dynamically (on demand) augment the node template maps for both color modes
export const addTemplateLazily = (category: string) => {
  COLOR_MODE_ARRAY.forEach((colorMode) => {
    const map = getDefinedBaseNodeTemplateMap(colorMode);
    if (!map.has(category)) {
      if (isBasicCategory(category) && BASE_NODE_DICTIONARY[category]) {
        const value = BASE_NODE_DICTIONARY[category];
        map.add(category, makeBasicTemplate(category, value, colorMode));
      } else if (isResourceCategory(category) && RESOURCE_DICTIONARY[category]) {
        const value = RESOURCE_DICTIONARY[category];
        map.add(category, makeResourceTemplate(category, value, colorMode));
      } else if (isSpecialCategory(category) && SPECIAL_NODE_DICTIONARY[category]) {
        const value = SPECIAL_NODE_DICTIONARY[category];
        map.add(category, makeSpecialTemplate(category, value, colorMode));
      }
    }
  });
};

// This is used to reattach the setActivePanel callback to the call template whenever the setActivePanel is remounted
export const addCallTemplateLazily = (setActivePanel: (name: string) => void) => {
  COLOR_MODE_ARRAY.forEach((colorMode) => {
    const map = getBaseNodeTemplateMap(colorMode, setActivePanel);
    CIRCUIT_PANEL_CATEGORIES.forEach((type) =>
      map.add(type, makeCallTemplate(type, setActivePanel))
    );
  });
};

// this is used to add a project node dictionary to the template map
export const addBasicTemplates = (
  map: go.Map<string, go.Node>,
  nodeDictionary: NodeDictionary,
  colorMode: ColorMode
) => {
  Object.entries(nodeDictionary).forEach(([category, value]) => {
    if (isBasicCategory(category)) {
      if ('params' in value) {
        map.add(category, makeBasicTemplate(category, value, colorMode));
      } else {
        // this error will go off the first time the editor tries to load a new node
        // dictionary that has a basic node without a params property.
        throw new Error(`internal: ${category} is a typed ID but has no params property`);
      }
    }
  });
};

// this is used to populate a freshly allocated, initially empty
// template map with special nodes that use customized templates.
const addExceptionalSpecialTemplates = (
  map: go.Map<string, go.Node>,
  colorMode: ColorMode,
  setActivePanel: (name: string) => void
) => {
  // special nodes that require a GoJS template that is distinctive
  // in behavior or appearance are created ahead of time.

  // Text editing
  map.add('CFN', makeArgTemplate(SPECIAL_NODE_DICTIONARY.CFN, colorMode));
  map.add('arg', makeArgTemplate(SPECIAL_NODE_DICTIONARY.arg, colorMode));
  map.add('expr', makeExprTemplate(SPECIAL_NODE_DICTIONARY.expr, colorMode));
  map.add('val', makeValueTemplate(SPECIAL_NODE_DICTIONARY.val, '#006', colorMode));
  map.add('once', makeValueTemplate(SPECIAL_NODE_DICTIONARY.once, '#602', colorMode));
  map.add('comment', makeCommentTemplate());

  // Calls
  CIRCUIT_PANEL_CATEGORIES.forEach((type) =>
    map.add(type, makeCallTemplate(type, setActivePanel))
  );

  // Inlets and outlets
  map.add('inlet', makeInletTemplate());
  map.add('outlet', makeOutletTemplate());
  map.add('param', makeParamTemplate());
  map.add('return', makeReturnTemplate());

  // Conditional outlets
  ['fioutlet', 'chooseoutlet'].forEach((type) => {
    map.add(type, makeConditionalOutletTemplate(type, colorMode));
  });

  // Portals
  map.add('send', makeSendTemplate());
  map.add('recv', makeReceiveTemplate());

  // add context menu for renamable nodes
  RENAMEABLE_NODES.forEach((category) => {
    const template = map.get(category);
    if (template) template.contextMenu = makeRenameableNodeContextMenu(category);
  });

  // the remaining special node templates are created and added to the map on-demand
};

const addExceptionalTemplates = (
  map: go.Map<string, go.Node>,
  colorMode: ColorMode,
  setActivePanel: (name: string) => void
) => {
  // add exceptional special node templates
  addExceptionalSpecialTemplates(map, colorMode, setActivePanel);

  // Unknown/obsolete/missing category in the model data
  map.add('', makeUnknownTemplate());
};

/**
 * ----------------------------------------------------------------------------
 * Maintain a node template map for each color mode, and track whether they
 * have had the exceptional templates added to them yet.
 * ----------------------------------------------------------------------------
 */
const baseNodeTemplateMaps: Record<ColorMode, go.Map<string, go.Node>> = {
  light: new go.Map<string, go.Node>(),
  dark: new go.Map<string, go.Node>(),
};

let hasAddedExceptionalTemplates = false;

export const getBaseNodeTemplateMap = (
  colorMode: ColorMode,
  setActivePanel: (name: string) => void
) => {
  if (!hasAddedExceptionalTemplates) {
    COLOR_MODE_ARRAY.forEach((mode) => {
      addExceptionalTemplates(baseNodeTemplateMaps[mode], mode, setActivePanel);
    });
    hasAddedExceptionalTemplates = true;
  }

  return baseNodeTemplateMaps[colorMode];
};

export const getDefinedBaseNodeTemplateMap = (colorMode: ColorMode) => {
  return baseNodeTemplateMaps[colorMode];
};
