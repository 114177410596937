import { ColorMode } from '@chakra-ui/react';
import * as go from 'gojs';

// eslint-disable-next-line import/no-cycle
import {
  addBasicTemplates,
  coreModelToDiagramModel,
  diagramModelToCoreModel,
  getDefinedBaseNodeTemplateMap,
  getModelFromDiagram,
  makeDiagram,
} from 'services/GoJS';
import { TRANSACTIONS } from 'services/GoJS/const';
// eslint-disable-next-line import/no-cycle
import { StorageService } from 'services/StorageService';

import {
  CallerKeyMap,
  CallerMap,
  CallGraph,
  Project,
  TextModel,
  TextModelMap,
} from 'types/project';
import { ModelData } from 'types/gojs';
import {
  CoreModel,
  Message,
  MESSAGE_TYPES,
  NodeDictionary,
  ProjectMessages,
  TypeDictionary,
} from 'types/transparency';
import {
  addCircuitsToCallGraph,
  deleteCalleeFromCallGraph,
  getRootPanels,
} from 'utils/callgraph';
import { makeBaseModel } from 'utils/model';
import {
  EMPTY_PROJECT_NAME,
  getProjectFromModels,
  renameCircuits,
  getRenameMap,
  hasCircuitErrors,
  isDiagramType,
  isTextType,
  isTextModel,
  isDiagramModel,
} from 'utils/project';
// eslint-disable-next-line import/no-cycle
import { nullifyCall, sync } from 'utils/sync';

type ExternalUpdateCallback = () => void;
type ExternalCallgraphCallback = () => void;
type ExternalMessagesCallback = (messages?: ProjectMessages) => void;
type ExternalActivePanelCallback = (name: string) => void;
type ExternalPanelHistoryDeleteCallback = (name: string) => void;
type ExternalPanelHistoryRenameCallback = (name: string, newName: string) => void;
type ExternalPanelHistoryResetCallback = () => void;
type ExternalDebuggerResetCallback = () => void;

type ProjectClassOptions = {
  updateCallback: ExternalUpdateCallback;
  callgraphCallback: ExternalCallgraphCallback;
  messagesCallback: ExternalMessagesCallback;
  activePanelCallback: ExternalActivePanelCallback;
  panelHistoryDeleteCallback: ExternalPanelHistoryDeleteCallback;
  panelHistoryRenameCallback: ExternalPanelHistoryRenameCallback;
  panelHistoryResetCallback: ExternalPanelHistoryResetCallback;
};

class ProjectServiceClass {
  /**
   * Map used to maintain reference to the GoJS diagram.
   *
   * diagram.model contains the GraphLinksModel.
   *
   * go.GraphLinksModel is the lowest GoJS object that contains
   * all of the necessary data to reconstruct the project from scratch.
   *
   * graphLinksModel.modelData contains everything in CoreModel without nodes and edges.
   * graphLinksModel.nodeDataArray contains nodes in CoreModel.
   * graphLinksModel.linkDataArray contains edges in CoreModel.
   */
  private diagramMap = new Map<string, go.Diagram>();

  /**
   * Map used to maintain reference to the textModel data of each text editor model.
   *
   * TextModel type is a combination of CoreModel and EditorView type
   *
   * CoreModel contains the name, type, meta, and text properties passed from the transpiler.
   *
   * EditorView is the editor view instance that is used in the <CodeMirror/> component
   * that stores the true state of the each text editor in the "state" property.
   */
  private textModelMap: TextModelMap = new Map<string, TextModel>();

  private projectName: string = EMPTY_PROJECT_NAME;

  private projectMessages: ProjectMessages | undefined;

  private projectNodeDictionary: NodeDictionary = {};

  private projectTypeDictionary: TypeDictionary = {};

  private callGraph: CallGraph = new Map<string, CallerKeyMap>();

  private callerMap: CallerMap = new Map<string, Set<string>>();

  getTextModelMap() {
    return this.textModelMap;
  }

  getProjectName() {
    return this.projectName;
  }

  getProjectMessages() {
    return this.projectMessages;
  }

  getProjectNodeDictionary() {
    return this.projectNodeDictionary;
  }

  getProjectTypeDictionary() {
    return this.projectTypeDictionary;
  }

  getCallGraph() {
    return this.callGraph;
  }

  getCallerMap() {
    return this.callerMap;
  }

  init(options: ProjectClassOptions) {
    const {
      updateCallback,
      callgraphCallback,
      messagesCallback,
      activePanelCallback,
      panelHistoryDeleteCallback,
      panelHistoryRenameCallback,
      panelHistoryResetCallback,
    } = options;

    this.externalUpdateCallback = updateCallback;
    this.externalCallgraphCallback = callgraphCallback;
    this.externalMessagesCallback = messagesCallback;
    this.externalActivePanelCallback = activePanelCallback;
    this.externalPanelHistoryDeleteCallback = panelHistoryDeleteCallback;
    this.externalPanelHistoryRenameCallback = panelHistoryRenameCallback;
    this.externalPanelHistoryResetCallback = panelHistoryResetCallback;
  }

  updateDebuggerResetCallback(debuggerResetCallback: ExternalDebuggerResetCallback) {
    this.externalDebuggerResetCallback = debuggerResetCallback;
  }

  /**
   * ----------------------------------------------------------------------------
   * Set up callbacks to be run when the project changes
   * ----------------------------------------------------------------------------
   */

  private externalUpdateCallback: ExternalUpdateCallback | undefined;

  private externalCallgraphCallback: ExternalCallgraphCallback | undefined;

  private externalMessagesCallback: ExternalMessagesCallback | undefined;

  private externalActivePanelCallback: ExternalActivePanelCallback | undefined;

  private externalPanelHistoryDeleteCallback:
    | ExternalPanelHistoryDeleteCallback
    | undefined;

  private externalPanelHistoryRenameCallback:
    | ExternalPanelHistoryRenameCallback
    | undefined;

  private externalPanelHistoryResetCallback:
    | ExternalPanelHistoryResetCallback
    | undefined;

  private externalDebuggerResetCallback: ExternalDebuggerResetCallback | undefined;

  private highlightProjectMessages(shouldHighlight: boolean) {
    if (!this.projectMessages) return;

    MESSAGE_TYPES.forEach((messageType) => {
      const errors = this.projectMessages?.[messageType];
      if (errors?.circuitErrors === undefined) return;

      Object.entries(errors.circuitErrors).forEach(([circuit, circuitError]) => {
        if (!hasCircuitErrors(circuitError)) return;

        const errorModel = this.getDiagramModelByName(circuit);
        if (!errorModel) return;

        // Highlight nodes and edges
        errorModel.startTransaction(`highlight ${messageType}`);

        circuitError.nodeErrors?.forEach(({ key, msg }) => {
          const nodeData = errorModel.findNodeDataForKey(key);
          if (!nodeData) return;

          if (!shouldHighlight) {
            // Remove message object
            errorModel.setDataProperty(nodeData, '_message', undefined);
          } else if (!nodeData._message) {
            // Add message object if it wasn't already added in a previous iteration
            const message: Message = { type: messageType, text: msg };
            errorModel.setDataProperty(nodeData, '_message', message);
          }
        });

        circuitError.edgeErrors?.forEach(({ key, msg }) => {
          const linkData = errorModel.findLinkDataForKey(key);
          if (!linkData) return;

          if (!shouldHighlight) {
            // Remove message object
            errorModel.setDataProperty(linkData, '_message', undefined);
          } else if (!linkData._message) {
            // Add message object if it wasn't already added in a previous iteration
            const message: Message = { type: messageType, text: msg };
            errorModel.setDataProperty(linkData, '_message', message);
          }
        });

        errorModel.commitTransaction(`highlight ${messageType}`);
      });
    });
  }

  setProjectMessages(projectMessages?: ProjectMessages) {
    this.projectMessages = projectMessages;
    this.highlightProjectMessages(true);
    this.externalMessagesCallback?.(projectMessages);
  }

  private onProjectChange() {
    if (!this.externalUpdateCallback) {
      throw new Error('internal: external project callback has not been registered');
    }
    this.externalUpdateCallback();
  }

  private onCallgraphChange() {
    if (!this.externalCallgraphCallback) {
      throw new Error('internal: external callgraph callback has not been registered');
    }
    this.externalCallgraphCallback();
  }

  private setActivePanel = (name: string) => {
    if (!this.externalActivePanelCallback) {
      throw new Error('internal: external active panel callback has not been registered');
    }
    this.externalActivePanelCallback(name);
  };

  private panelHistoryDelete = (name: string) => {
    if (!this.externalPanelHistoryDeleteCallback) {
      throw new Error('internal: external delete panel callback has not been registered');
    }
    this.externalPanelHistoryDeleteCallback(name);
  };

  private panelHistoryRename = (name: string, newName: string) => {
    if (!this.externalPanelHistoryRenameCallback) {
      throw new Error('internal: external rename panel callback has not been registered');
    }
    this.externalPanelHistoryRenameCallback(name, newName);
  };

  private panelHistoryReset = () => {
    if (!this.externalPanelHistoryResetCallback) {
      throw new Error(
        'internal: external reset panel history callback has not been registered'
      );
    }
    this.externalPanelHistoryResetCallback();
  };

  private resetDebugger = () => {
    if (!this.externalDebuggerResetCallback) {
      throw new Error(
        'internal: external clear debugger callback has not been registered'
      );
    }
    this.externalDebuggerResetCallback();
  };

  /**
   * ----------------------------------------------------------------------------
   * Handle project dictionaries / maps
   * ----------------------------------------------------------------------------
   */

  private addCircuitsToProjectMap(circuits: CoreModel[], isReadOnly?: boolean) {
    circuits.forEach((coreModel) => {
      const { type, name } = coreModel;
      if (isDiagramType(type)) {
        this.diagramMap.set(
          name,
          makeDiagram(coreModelToDiagramModel(coreModel), this.setActivePanel, isReadOnly)
        );
      }
      if (isTextType(type)) {
        this.textModelMap.set(name, coreModel);
      }
    });
  }

  setTextModelMap(modelName: string, textModel: TextModel) {
    const codeMirror =
      textModel.codeMirror ?? this.textModelMap.get(modelName)?.codeMirror;
    const newTextModel = { ...textModel, codeMirror };
    this.textModelMap.set(modelName, newTextModel);
  }

  private addProjectNodeTemplates() {
    const colorArray: ColorMode[] = ['light', 'dark'];
    colorArray.forEach((colorMode) => {
      const map = getDefinedBaseNodeTemplateMap(colorMode);
      addBasicTemplates(map, this.projectNodeDictionary, colorMode);
    });
  }

  loadProjectDictionaries(
    nodeDictionary?: NodeDictionary,
    typeDictionary?: TypeDictionary,
    runCallback = false
  ) {
    this.projectNodeDictionary = nodeDictionary ?? {};
    this.projectTypeDictionary = typeDictionary ?? {};

    this.addProjectNodeTemplates();

    if (runCallback) this.onProjectChange();
  }

  private insertProjectDictionaries(
    nodeDictionary?: NodeDictionary,
    typeDictionary?: TypeDictionary,
    runCallback = false
  ) {
    // We should detect conflicts between the dictionaries.
    // The transpiler / compiler will detect it ultimately,
    // but detecting it here would result in a better UX.
    Object.assign(this.projectNodeDictionary, nodeDictionary);
    Object.assign(this.projectTypeDictionary, typeDictionary);

    this.addProjectNodeTemplates();

    if (runCallback) this.onProjectChange();
  }

  /**
   * ----------------------------------------------------------------------------
   * Methods
   * ----------------------------------------------------------------------------
   */
  getPanelNames() {
    return [...this.getDiagramsModelData(), ...this.getTextModels()].map(
      (model) => model.name
    );
  }

  getRootPanelName() {
    const panelNames = this.getPanelNames();
    const rootPanels = getRootPanels(
      this.getCallGraph(),
      this.getCallerMap(),
      panelNames
    );
    return rootPanels.length ? rootPanels[0] : undefined;
  }

  getDiagrams() {
    return Array.from(this.diagramMap.values());
  }

  getDiagramModels() {
    return this.getDiagrams().map(getModelFromDiagram);
  }

  getDiagramByName(name: string) {
    return this.diagramMap.get(name);
  }

  getDiagramModelByName(modelName: string) {
    const diagram = this.getDiagramByName(modelName);
    if (!diagram) return undefined;
    return getModelFromDiagram(diagram);
  }

  getDiagramsModelData() {
    return this.getDiagramModels().map(
      (diagramModel) => diagramModel.modelData as ModelData
    );
  }

  getTextModels(): TextModel[] {
    return Array.from(this.textModelMap.values());
  }

  getTextCoreModelByName(modelName: string): CoreModel | undefined {
    const textModel = this.textModelMap.get(modelName);
    if (!textModel) return undefined;
    return ProjectServiceClass.textModelToCoreModel(textModel);
  }

  getTextCoreModels(): CoreModel[] {
    return this.getTextModels().map(ProjectServiceClass.textModelToCoreModel);
  }

  static textModelToCoreModel(textModel: TextModel): CoreModel {
    const { name, type, meta, text, codeMirror } = textModel;
    return {
      name,
      type,
      meta,
      text: codeMirror?.state.doc.toString() ?? text,
    };
  }

  getModelByName(modelName: string) {
    return (
      this.getDiagramModelByName(modelName) ?? this.getTextCoreModelByName(modelName)
    );
  }

  getProject(): Project {
    sync(this.callGraph);

    const circuits = [
      ...this.getDiagramModels().map(diagramModelToCoreModel),
      ...this.getTextCoreModels(),
    ];

    return {
      name: this.projectName || EMPTY_PROJECT_NAME,
      nodeDictionary: this.projectNodeDictionary,
      typeDictionary: this.projectTypeDictionary,
      circuits,
    };
  }

  isEmpty() {
    return (
      this.projectName === EMPTY_PROJECT_NAME &&
      this.diagramMap.size === 0 &&
      this.textModelMap.size === 0
    );
  }

  private clearProject(options?: {
    shouldRunCallbacks?: boolean;
    shouldClearPanelHistory?: boolean;
    shouldClearDebugger?: boolean;
  }) {
    const {
      shouldRunCallbacks = true,
      shouldClearPanelHistory = true,
      shouldClearDebugger = true,
    } = options ?? {};

    this.diagramMap.clear();
    this.textModelMap.clear();

    this.projectName = EMPTY_PROJECT_NAME;

    this.projectNodeDictionary = {};
    this.projectTypeDictionary = {};

    this.callGraph.clear();
    this.callerMap.clear();

    this.resetErrorState();

    if (shouldClearPanelHistory) {
      this.panelHistoryReset();
    }

    if (shouldClearDebugger) {
      this.resetDebugger();
    }

    if (shouldRunCallbacks) {
      this.onProjectChange();
      this.onCallgraphChange();
    }
  }

  resetErrorState() {
    this.highlightProjectMessages(false);
    this.setProjectMessages(undefined);
  }

  loadProject(
    projectToLoad: Project,
    options?: {
      search?: string;
      shouldClearPanelHistory?: boolean;
      shouldClearDebugger?: boolean;
      shouldRunCallbacksOnClear?: boolean;
      isReadOnly?: boolean;
    }
  ) {
    const { circuits, nodeDictionary, typeDictionary, name } = projectToLoad;
    const {
      search,
      shouldClearPanelHistory = true,
      shouldClearDebugger = true,
      shouldRunCallbacksOnClear = true,
      isReadOnly,
    } = options ?? {};

    this.clearProject({
      shouldRunCallbacks: shouldRunCallbacksOnClear,
      shouldClearPanelHistory,
      shouldClearDebugger,
    });

    this.projectName = name;

    this.loadProjectDictionaries(nodeDictionary, typeDictionary);

    this.addCircuitsToProjectMap(circuits, isReadOnly);

    // Recreate callgraph
    addCircuitsToCallGraph(this.callGraph, this.callerMap, circuits);

    const panelNames = this.getPanelNames();

    // If a panel is passed as a query param, set it as active
    const query = new URLSearchParams(search);
    const activePanel = query.get('activePanel');
    if (activePanel && panelNames.indexOf(activePanel) >= 0) {
      this.setActivePanel(activePanel);
    } else if (shouldClearPanelHistory) {
      const rootPanel = this.getRootPanelName();
      if (rootPanel) this.setActivePanel(rootPanel);
    }

    this.onCallgraphChange();
    this.onProjectChange();
  }

  insertProject(projectToInsert: Project) {
    const { circuits, nodeDictionary, typeDictionary } = projectToInsert;

    this.resetErrorState();

    const currentProject = this.getProject();

    const renameMap = getRenameMap(circuits, currentProject);

    renameCircuits(circuits, renameMap);

    this.insertProjectDictionaries(nodeDictionary, typeDictionary);

    this.addCircuitsToProjectMap(circuits);

    addCircuitsToCallGraph(this.callGraph, this.callerMap, circuits);
    this.onCallgraphChange();

    this.onProjectChange();
  }

  /**
   * Function for loading a project when the editor is first opened.
   *
   * If an `exampleName` is provided, the corresponding example will be
   * loaded if it exists. Otherwise, a 'default' project is loaded (project
   * from local storage if one exists, or the 'welcome' example).
   *
   * If there are any errors doing the above, an empty circuit is made.
   */
  async loadInitialProject(
    initialExampleName: string | undefined,
    options?: { search?: string }
  ) {
    try {
      const initialProject = initialExampleName
        ? await StorageService.fetchExample(initialExampleName)
        : await StorageService.getDefaultProject();

      if (!initialProject) {
        throw new Error('No example to load');
      }
      this.loadProject(initialProject, options);
    } catch {
      // If initial project load fails, make an empty model
      this.loadProject(getProjectFromModels([makeBaseModel()]));
    }
  }

  makeNewProject(name?: string) {
    this.clearProject();

    this.addCircuitsToProjectMap([makeBaseModel()]);

    this.projectName = name || 'new';

    // Set new panel as active
    const rootPanel = this.getRootPanelName();
    if (rootPanel) this.setActivePanel(rootPanel);

    this.onProjectChange();
  }

  addModel(model: CoreModel) {
    this.addCircuitsToProjectMap([model]);

    // Set new panel as active
    this.setActivePanel(model.name);

    this.onProjectChange();
  }

  deleteModel(panelName: string, deleteAll: boolean) {
    // Nullify the callers of this model using the callgraph.
    // This is a kind of ad-hoc syncPull(), specialized for panel deletion.
    const callers = this.callGraph.get(panelName);
    if (callers) {
      callers.forEach((keys, caller) => {
        const model = ProjectService.getDiagramModelByName(caller);
        if (!model) return;

        model.startTransaction(TRANSACTIONS.deleteTab);

        keys.forEach((key) => {
          const data = model.findNodeDataForKey(key)!;
          nullifyCall(model, data);
        });

        model.commitTransaction(TRANSACTIONS.deleteTab);
      });
    }

    const deleteSet = new Set<string>();
    deleteSet.add(panelName);
    if (deleteAll) this.findDeletableModels(panelName, deleteSet);

    deleteSet.forEach((name) => {
      // Remove model from diagramModelMap/textModelMap
      this.diagramMap.delete(name);
      this.textModelMap.delete(name);

      // Update the callers structure
      deleteCalleeFromCallGraph(this.callGraph, this.callerMap, name);

      // Update the panel history
      this.panelHistoryDelete(name);
    });

    // Update React state
    this.onCallgraphChange();
    this.onProjectChange();
  }

  private findDeletableModels(name: string, deleteSet: Set<string>) {
    const callerMap = this.callerMap.get(name);
    callerMap?.forEach((child) => {
      const parents = this.callGraph.get(child);
      if (!parents) return;

      const parentsArray = Array.from(parents.keys());
      if (parentsArray.every((parent) => deleteSet.has(parent))) {
        deleteSet.add(child);
      }

      this.findDeletableModels(child, deleteSet);
    });
  }

  renameModel(currentName: string, newName: string) {
    if (currentName === newName) return;

    const project = this.getProject();

    const renameMap = new Map<string, string>();
    renameMap.set(currentName, newName);

    // Rename coreModels in place
    renameCircuits(project.circuits, renameMap);
    this.loadProject(project, {
      shouldClearPanelHistory: false,
      shouldRunCallbacksOnClear: false,
    });

    // Update panel history
    this.panelHistoryRename(currentName, newName);
  }

  setModelThreads(panelName: string, threads: number) {
    this.setMetaAttribute(panelName, 'threads', threads);

    this.onProjectChange();
  }

  setModelExpand(panelName: string, value: boolean | undefined = false) {
    this.setBooleanMetaAttribute(panelName, 'expanded', value);
  }

  private setBooleanMetaAttribute(
    panelName: string,
    attribute: 'hidden' | 'expanded',
    value: boolean | undefined = false
  ) {
    this.setMetaAttribute(panelName, attribute, value);
  }

  private setMetaAttribute(
    panelName: string,
    attribute: 'hidden' | 'expanded' | 'threads',
    value: number | boolean
  ) {
    const model = this.getModelByName(panelName);

    if (!model) return;
    const { name, meta } = isTextModel(model) ? model : (model.modelData as ModelData);
    const newMeta = {
      ...(meta || { version: 2 }),
      [attribute]: value,
    };

    if (isTextModel(model)) {
      // Only true when setting threads, which are not supported for text panels
      if (typeof value === 'number') return;
      this.setTextModelMap(name, { ...model, meta: newMeta });
    }

    if (isDiagramModel(model)) {
      model.startTransaction(`set ${attribute}`);
      model.setDataProperty(model.modelData, 'meta', newMeta);
      model.commitTransaction(`set ${attribute}`);
    }
  }
}

export const ProjectService = new ProjectServiceClass();
