import React, { ReactNode } from 'react';
import { GridItem, GridItemProps, Heading, useColorModeValue } from '@chakra-ui/react';

import { useScreenSize } from 'hooks/useScreenSize';

type DashboardPageCardProps = Partial<
  Pick<GridItemProps, 'backgroundColor' | 'colSpan'>
> & {
  children: ReactNode;
  heading?: string | ReactNode;
};

export const DashboardPageCard = (props: DashboardPageCardProps) => {
  const { backgroundColor, children, colSpan, heading } = props;

  const { isMobile } = useScreenSize();

  const defaultColor = useColorModeValue('white', 'gray.800');

  return (
    <GridItem
      colSpan={colSpan}
      display="flex"
      flexDirection="column"
      backgroundColor={backgroundColor ?? defaultColor}
      marginBottom={isMobile ? 4 : undefined}
      padding={8}
      borderRadius="xl"
    >
      {heading !== undefined && (
        // default font doesn't work well with numbers
        <Heading size="md" marginBottom={3} fontFamily="body">
          {heading}
        </Heading>
      )}

      {children}
    </GridItem>
  );
};
