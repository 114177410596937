import { javascript } from '@codemirror/lang-javascript';
import { EditorView } from '@codemirror/view';

import * as go from 'gojs';

const $ = go.GraphObject.make;

export const EDITOR_VARIANT = ['expr', 'comment', 'log'] as const;
export type EditorVariant = typeof EDITOR_VARIANT[number];

const makeEditorHTMLInfo = (variant: EditorVariant) => {
  return $(go.HTMLInfo, {
    show: (obj: go.GraphObject) => showTextEditor(variant, obj),
    hide: () => hideTextEditor(variant),
    valueFunction: () => getTextEditorValue(variant),
  });
};

export const EDITORS = {
  expr: {
    divClassName: 'expr-editor-container',
    codeMirrorClassName: 'expr-editor',
    HTMLInfo: makeEditorHTMLInfo('expr'),
    extensions: [javascript()],
  },
  comment: {
    divClassName: 'comment-editor-container',
    codeMirrorClassName: 'comment-editor',
    HTMLInfo: makeEditorHTMLInfo('comment'),
    extensions: [],
  },
  log: {
    divClassName: 'log-editor-container',
    codeMirrorClassName: 'log-editor',
    extensions: [javascript()],
  },
};

export const getEditorContainerId = (variant: EditorVariant) =>
  EDITORS[variant].divClassName;

const getEditorSelector = (variant: EditorVariant) =>
  `.${EDITORS[variant].codeMirrorClassName}`;

export const isEditorOpen = (variant: EditorVariant) => {
  const div = document.getElementById(getEditorContainerId(variant));

  return div?.style.display === 'block';
};

export const isAnyEditorOpen = () => {
  return EDITOR_VARIANT.some(isEditorOpen);
};

const CM_BRACKET_CLASSES = ['ͼ1w', 'ͼ1v', 'cm-nonmatchingBracket', 'cm-matchingBracket'];
export const isCodeMirrorBracket = (className: string) =>
  CM_BRACKET_CLASSES.includes(className);

/**
 * ----------------------------------------------------------------------------
 * Custom text editor for arg/val/expr/comment nodes using go.HTMLInfo
 * https://gojs.net/latest/intro/HTMLInteraction.html#HTMLInfoClass
 *
 * The implementation is coupled with our usage of the CodeMirror text editor
 * (and the react-codemirror2 wrapper). CodeMirror contains a div with classname
 * `CodeMirror` that holds the editor instance and is how we access/set the
 * code editor value when opening and closing the GoJS text editor.
 * ----------------------------------------------------------------------------
 */

let tempValue: string = '';
let tempKey: go.Key | undefined;

// save text if opened by contextMenu
export const saveTextEditor = (diagram: go.Diagram, variant: EditorVariant) => {
  const text = getTextEditorValue(variant);
  const node = diagram?.findNodeForKey(tempKey);

  if (!diagram || !node || !text) return;

  const { data } = node;

  diagram.startTransaction('save text');

  diagram.model.setDataProperty(data, 'text', text);

  diagram.commitTransaction('save text');
};

// Revert editor value to its value when first opened
export const revertTextChange = (variant: EditorVariant) => {
  const editorElement = document.querySelector<HTMLDivElement>(
    getEditorSelector(variant)
  );
  // Should not happen
  if (!editorElement) return;

  const editor = EditorView.findFromDOM(editorElement);

  // replace document text
  editor?.dispatch({
    changes: [{ from: 0, to: editor.state.doc.length, insert: tempValue }],
  });
};

export const showTextEditor = (
  editorVariant: EditorVariant,
  obj?: go.GraphObject,
  key?: go.Key
) => {
  const div = document.getElementById(
    getEditorContainerId(editorVariant)
  )! as HTMLDivElement;
  div.style.display = 'block';

  // log data is not maintained in modelData, so we only change the display property
  if (editorVariant === 'log') return;

  const editorElement = document.querySelector<HTMLDivElement>(
    getEditorSelector(editorVariant)
  );

  // Should not happen
  if (!editorElement || !obj) return;

  // Get text from textblock and set it as the editor's value.
  // Also track this initial value in tempValue.
  const { text = '' } = obj as go.TextBlock;

  const editor = EditorView.findFromDOM(editorElement);

  // replace document text
  editor?.dispatch({
    changes: [{ from: 0, to: editor.state.doc.length, insert: text }],
  });
  tempValue = text;
  tempKey = key;

  // Focus editor
  editor?.focus();
};

export const hideTextEditor = (variant: EditorVariant) => {
  const div = document.getElementById(getEditorContainerId(variant))! as HTMLDivElement;

  div.style.display = 'none';
  tempValue = '';
  tempKey = undefined;
};

const getTextEditorValue = (variant: EditorVariant) => {
  const editorElement = document.querySelector<HTMLDivElement>(
    getEditorSelector(variant)
  );

  // Should not happen
  if (!editorElement) return;

  const editor = EditorView.findFromDOM(editorElement);

  return editor?.state.doc.toString();
};
