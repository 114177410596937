import {
  PREFIX_TEXTTYPE,
  PREFIX_WSID,
  PREFIX_WSSCOPEDID,
  PREFIX_CGPARAM,
} from 'utils/regex';

/**
 * Encode a Transparency source code type as a typeid.
 * Does not expand type names, and does not consult the type dictionary.
 */
export const encodeType = (type: string) => {
  const A: string[] = [];
  encodeType0(type, 0, A, false);

  const E = A.join('');
  // console.log(`encodeType(${t}) = ${E}`);
  return E;
};

export const encodeJSONType = (type: string) => {
  const A: string[] = [];
  encodeType0(type, 0, A, true);

  const E = A.join('');
  // console.log(`encodeJSONType(${t}) = ${E}`);
  return E;
};

const encodeType0 = (type: string, i: number, A: string[], jsonp: boolean): number => {
  // console.log(`encodeType0(${t}, ${i}`);

  const s = type.substring(i);

  const x = PREFIX_CGPARAM.exec(s); // do we have a ${cg-param-...} form?
  if (x) {
    const n = x[0].length;
    A.push(`<${x[1]}>`); // wrap the entire ${cg-param-...} term in <>'s
    return i + n;
  }

  const m = PREFIX_WSSCOPEDID.exec(s); // do we have an identifier preceded by whitespace?
  if (m) {
    const n = m[0].length;

    switch (m[1]) {
      case 'string':
        A.push('s');
        return i + n;
      case 'symbol':
        A.push('y');
        return i + n;
      case 'regex':
        A.push('r');
        return i + n;
      case 'match':
        A.push('p');
        return i + n;
      case 'int8':
        A.push('a');
        return i + n;
      case 'int16':
        A.push('b');
        return i + n;
      case 'int32':
        A.push('c');
        return i + n;
      case 'int64':
      case 'int':
        A.push('d');
        return i + n;
      case 'uint8':
        A.push('e');
        return i + n;
      case 'uint16':
        A.push('f');
        return i + n;
      case 'uint32':
        A.push('g');
        return i + n;
      case 'uint64':
      case 'uint':
        A.push('h');
        return i + n;
      case 'float32':
      case 'single':
        A.push('i');
        return i + n;
      case 'float64':
      case 'double':
        A.push('j');
        return i + n;
      case 'codepoint':
      case 'char':
        A.push('u');
        return i + n;
      case 'bool':
        A.push('t');
        return i + n;
      case 'bitset':
        A.push('B');
        return i + n;
      case 'idxset':
        A.push('I');
        return i + n;
      case 'device':
        A.push('D');
        return i + n;
      case 'buffer':
        A.push('U');
        return i + n;
      case 'stream':
        A.push('E');
        return i + n;

      case 'shared':
        A.push('q');
        return encodeType0(type, i + n, A, jsonp);
      case 'const':
        A.push('k');
        return encodeType0(type, i + n, A, jsonp);

      case 'vector':
        A.push('V');
        return encodeType0(type, i + n, A, jsonp);
      case 'list':
        A.push('L');
        return encodeType0(type, i + n, A, jsonp);
      case 'ordset':
        A.push('R');
        return encodeType0(type, i + n, A, jsonp);
      case 'set':
        A.push('S');
        return encodeType0(type, i + n, A, jsonp);
      case 'table':
        A.push('Z');
        return encodeType0(type, i + n, A, jsonp);
      case 'idxmap':
        A.push('J');
        return encodeType0(type, i + n, A, jsonp);
      case 'deque':
        A.push('K');
        return encodeType0(type, i + n, A, jsonp);
      case 'box':
        A.push('P');
        return encodeType0(type, i + n, A, jsonp);
      case 'pqueue':
        A.push('Q');
        return encodeType0(type, i + n, A, jsonp);
      case 'wire':
        A.push('W');
        return encodeType0(type, i + n, A, jsonp);
      case 'in':
        A.push('n');
        return encodeType0(type, i + n, A, jsonp);
      case 'out':
        A.push('o');
        return encodeType0(type, i + n, A, jsonp);

      case 'trigger': {
        const triggerSubstring = type.substring(i + n);
        const identifierMatch = /^[\s]*([a-z]+)/.exec(triggerSubstring); // identifier in or out following trigger

        if (identifierMatch) {
          const o = identifierMatch[0].length;
          switch (identifierMatch[1]) {
            case 'in': {
              A.push('X');
              return encodeType0(type, i + n + o, A, jsonp);
            }
            case 'out': {
              A.push('Y');
              return encodeType0(type, i + n + o, A, jsonp);
            }
            default: {
              A.push('?');
              return i;
            }
          }
        } else {
          A.push('?');
          return i;
        }
      }

      case 'ordmap': {
        A.push('N');
        const j = encodeType0(type, i + n, A, jsonp);
        const k = encodeTypeExpect(type, j, /^[\s]*to\b/);
        if (k > j) {
          return encodeType0(type, k, A, jsonp);
        }

        A.push('?');
        return i;
      }
      case 'map': {
        A.push('M');
        const j = encodeType0(type, i + n, A, jsonp);
        const k = encodeTypeExpect(type, j, /^[\s]*to\b/);
        if (k > j) {
          return encodeType0(type, k, A, jsonp);
        }

        A.push('?');
        return i;
      }

      case 'matrix': {
        const j = i + n;
        const substringJ = type.substring(j);
        const p = /^[\s]*{([0-9])}/.exec(substringJ);
        if (p) {
          const o = p[0].length;
          A.push(`A{${m[1]}`);

          return encodeType0(type, j + o, A, jsonp);
        }

        A.push('?');
        return i;
      }

      default: {
        A.push(`<${m[1]}>`);
        return i + n;
      }
    }
  } else {
    // tuple <...>, empty signature [], or ()-enclosed id
    const match = /^[\s]*([<[(])/.exec(s);

    if (match) {
      const n = match[0].length;
      let j = i + n;
      switch (match[1]) {
        case '<': {
          const B: string[] = [];
          let N = 0; // number of comma-separated types in the tuple

          const v = encodeTypeExpect(type, j, /^[\s]*>/);
          if (v > j) {
            // void type <>
            B.push('v');
            j = v;
          } else {
            // singleton or tuple type
            for (;;) {
              const C: string[] = [];
              const k = encodeType0(type, j, C, jsonp); // the slot type (if any) is encoded into C
              if (k > j) {
                j = k;
                ++N;
                let currentInput = type.substring(j);
                const slotname = PREFIX_WSID.exec(currentInput); // is there a slot name?
                if (slotname) {
                  // if there is a slot name, it is wrapped in {}s,
                  // and precedes the encoded slot type.
                  j += slotname[0].length;
                  if (jsonp) B.push(`{${slotname[1]}}`); // emit {id} before the slot type.
                  currentInput = type.substring(j); // advance the current input
                }

                B.push(C.join('')); // emit the slot type after the slotname, if any.

                const p = /^[\s]*([,>])/.exec(currentInput);
                if (p) {
                  j += p[0].length;
                  // eslint-disable-next-line no-continue
                  if (p[1] === ',') continue; // to the next iteration of the for(;;) loop
                } else {
                  B.push('?');
                }
              }
              break; // from the for(;;) loop
            }
          }

          // we fetched 0 types (void), 1  type (singleton), or > 1 type (tuple)
          // into the array B.  if the next input is <-, then this is a function
          // type, B holds the output type, and we must fetch the input type.

          const f = encodeTypeExpect(type, j, /^[\s]*<-/);

          // if a function type, push the F indicator
          if (f > j) A.push('F');

          // if we fetched a tuple type, push the T indicator and count N
          if (N > 1) A.push(`T${N}`);

          // push the type(s) encoded in B
          A.push(B.join(''));

          // if a function type, fetch and encode the immediately following type, else done.
          return f > j ? encodeType0(type, f, A, jsonp) : j;
        }

        case '[': {
          const o = encodeTypeExpect(type, j, /^[\s]*\]/);
          A.push(o > j ? 'O' : '?');
          return o;
        }

        case '(': {
          const parensS = type.substring(i);
          const parensM = PREFIX_TEXTTYPE.exec(parensS);

          if (parensM) {
            A.push(parensM[0]);
            return i + parensM[0].length;
          }

          // Signal an error to the caller
          A.push('?');
          return i;
        }

        default: {
          // this is unreachable
          A.push('?'); // signal an error to the caller
          return i;
        }
      }
    } else {
      // the input was not a keyword or identifier,
      // neither was it '<' or '['.
      A.push('?'); // signal an error to the caller
      return i;
    }
  }
};

const encodeTypeExpect = (type: string, i: number, r: RegExp) => {
  const s = type.substring(i);
  const m = r.exec(s);

  return i + (m ? m[0].length : 0);
};

export const checkType = (type: string) => {
  return encodeType(type).indexOf('?') < 0;
};
