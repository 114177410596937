/**
 * ----------------------------------------------------------------------------
 * Prefix regexes - match at beginning of input but do not constrain what follows
 * ----------------------------------------------------------------------------
 */
// ids
export const PREFIX_ID = /^((?:[\p{L}\p{N}_$]+)|(?:«[^«»]+»))/u;
export const PREFIX_SCOPEDID = /^((?:[\p{L}\p{N}_:$]+)|(?:«[^«»]+»))/u; // scope quals are optional

// ids with leading whitespace
export const PREFIX_WSID = /^[\s]*((?:[\p{L}\p{N}_$]+)|(?:«[^«»]+»))/u;
export const PREFIX_WSSCOPEDID = /^[\s]*((?:[\p{L}\p{N}_:$]+)|(?:«[^«»]+»))/u; // scope quals are optional
export const PREFIX_CGPARAM = /^[\s]*(\$\{(?:[\p{L}\p{N}-]+)\})/u; // ${cg-param-....}

// within encoded typeids
export const PREFIX_SLOTNAME = /^\{([^{}]+)\}/u;
export const PREFIX_TYPENAME = /^<([^<>]+)>/u;
export const PREFIX_TEXTTYPE = /^\(([a-zA-Z]*)\)/;

// a type parameter w/ possibly empty digit string
export const PREFIX_TYPEPARAM = /^\?[0-9]*/;

// non-empty decimal digit string
export const PREFIX_DECINT = /^[0-9]+/;

/**
 * ----------------------------------------------------------------------------
 * User input
 * ----------------------------------------------------------------------------
 */
// identifiers scanned from user input.  «» not allowed here.
export const WS_ALPHANUM_WS = /^[\s]*([a-zA-z][a-zA-Z0-9]*)[\s]*$/;
export const WS_ID_WS = /^[\s]*([\p{L}][\p{L}\p{N}_$]*)[\s]*$/u;
export const WS_INT_WS = /^[\s]*([0-9]+)[\s]*$/;

// empty string scanned from user input
export const WS = /^[\s]*$/;

/**
 * ----------------------------------------------------------------------------
 * Node categories (and pid decomposition into ID and type)
 * ----------------------------------------------------------------------------
 */
// the structure here is ID$TYPE.
// [1] : id prefix; [2] : type suffix
// NOTE: we allow a '.' in pids because of pack and unpack;
//       the '.' is used as a nesting level separator.
// basic node categories match this.
export const TYPED_ID = /^((?:[\p{L}\p{N}_:.$]+)|(?:«[^«»]+»))[$](.+)$/u;

// special node categories match this.
export const ALPHANUM = /^[a-zA-z][a-zA-Z0-9]*$/;

// Transparency outline id.  mandatory scope qualifiers, no $'s, no type suffix.
// resource node categories match this.   «» not allowed here.
export const OUTLINE = /^(?:[\p{L}\p{N}_]+::)+(?:[\p{L}\p{N}_]+)$/u;

/**
 * ----------------------------------------------------------------------------
 * Project name
 * ----------------------------------------------------------------------------
 */
// slash-separated identifier, i.e., a Unix-style path.
// the non-alphnumeric codepoints we allow are - and _.
// the inclusion of hyphen ('-') in project names means
// that project names cannot be variable names in Transparency.
export const PROJNAME = /^([\p{L}\p{N}_-]+(?:\/[\p{L}\p{N}_-]+)*)$/u;

// PROJNAME + must have a single '/' at the end of the string
export const FOLDERNAME = /^([\p{L}\p{N}_-]+(?:\/[\p{L}\p{N}_-]+)*)+(\/)$/u;

/**
 * ----------------------------------------------------------------------------
 * Replacement
 * ----------------------------------------------------------------------------
 */
// for global replacement of numeric type parameters
export const ALL_TYPE_PARAMS = /\?[0-9]+/g;

/**
 * ----------------------------------------------------------------------------
 * Password validation
 * ----------------------------------------------------------------------------
 */

// has 8 or more characters
export const EIGHT_OR_MORE = /^.{8,}$/;

// has at least one lowercase letter
export const ONE_LOWERCASE = /[a-z]/;

// has at least one uppercase letter
export const ONE_UPPERCASE = /[A-Z]/;

// has at least one number
export const ONE_NUMBER = /[0-9]/;

// has at least one special-case character
// eslint-disable-next-line no-useless-escape
export const ONE_SPECIAL = /[\^$*.\[\]{}\(\)?\\\-"!@#%&\/,><\':;|_~`+=]/;
