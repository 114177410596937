import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { Center, Spinner, SpinnerProps, Stack } from '@chakra-ui/react';

type CenterSpinnerProps = {
  children: ReactNode;
} & SpinnerProps;

export const CenterSpinner = forwardRef(
  (props: CenterSpinnerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...spinnerProps } = props;

    return (
      <Center>
        <Stack align="center">
          <Spinner ref={ref} {...spinnerProps} />
          {children}
        </Stack>
      </Center>
    );
  }
);
