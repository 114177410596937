import * as LocalStorage from 'services/storage/LocalStorage';
import { z } from 'zod';

const REGIONS = process.env.REACT_APP_REGIONS?.split(',') ?? [];

export const AWS_REGIONS = [
  'ap-southeast-1',
  'eu-central-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-south-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-south-2',
  'eu-north-1',
  'eu-central-2',
  'il-central-1',
  'me-south-1',
  'me-central-1',
  'sa-east-1',
  'us-east-2',
  'us-east-1',
  'us-west-1',
] as const;

export const awsRegionSchema = z.enum(AWS_REGIONS, {
  errorMap: () => ({ message: 'Specify a valid region' }),
});

export type AwsRegion = z.infer<typeof awsRegionSchema>;

// coreograph backend is deployed in these regions
export const awsCoreographRegionSchema = awsRegionSchema.extract([
  'ap-southeast-1',
  'us-west-2',
  'eu-central-1',
]);

const regionInfoSchema = z.object({
  // Prefix added in front of API URLs
  subdomain: z.string(),
  // Region name that will be displayed to the user (e.g. in the RegionDropdown)
  name: z.string(),
  // Corresponding AWS region
  aws: awsCoreographRegionSchema,
});

type RegionInfo = z.infer<typeof regionInfoSchema>;

/**
 * Map of 'standard' regions we deploy to. All regions that the backend
 * is deployed to in production should be added here so we can define
 * the `name` that will be displayed in the UI.
 */
const STANDARD_REGION_MAP: Record<string, RegionInfo> = {
  ap: {
    subdomain: 'ap',
    name: 'Asia Pacific',
    aws: 'ap-southeast-1',
  },
  eu: {
    subdomain: 'eu',
    name: 'Europe',
    aws: 'eu-central-1',
  },
  us: {
    subdomain: 'us',
    name: 'United States',
    aws: 'us-west-2',
  },
};

const getRegionMap = () => {
  const map: Record<string, RegionInfo> = {};

  return REGIONS.reduce((accumulator, region) => {
    // Any region not in STANDARD_REGION_MAP is assumed to be for internal use;
    // `name` will just be set as `region`
    accumulator[region] = STANDARD_REGION_MAP[region] ?? {
      subdomain: region,
      name: region,
      aws: 'ap-southeast-1',
    };

    return accumulator;
  }, map);
};

/**
 * Map of regions that the backend is deployed to (driven by REACT_APP_REGIONS in env files).
 */
export const REGION_MAP: Record<string, RegionInfo> = getRegionMap();

export const getRegionName = (region: string | undefined) => {
  return region ? REGION_MAP[region]?.name : undefined;
};

export const getRegionSubdomain = (region: string | undefined) => {
  return region ? REGION_MAP[region]?.subdomain : undefined;
};

export const getRegionFromLocalStorage = () => {
  return LocalStorage.get('region') ?? undefined;
};

export const getLocalAwsRegion = () => {
  const localRegion = getRegionFromLocalStorage();
  return localRegion ? REGION_MAP[localRegion]?.aws : undefined;
};
