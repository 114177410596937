import * as go from 'gojs';
import {
  Node,
  Edge,
  ErrorMessage,
  ProjectError,
  CIRCUIT_PANEL_CATEGORIES,
} from 'types/transparency';
import { Project } from 'types/project';
import { port2callSitePID } from 'utils/port';
import { isInConstArray } from 'utils/string';

export const unbundle = (
  activeModel: go.GraphLinksModel,
  call: Node, // note: the call is model data
  project: Project // the project
): { projectErrors?: ProjectError; newProject?: Project } => {
  // these errors belong to the caller
  const nodeErrors: ErrorMessage[] = [];

  // these errors belong to the callee
  const edgeErrors: ErrorMessage[] = [];

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // examine the call
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  // destructure the call
  const { cat: callCat, key: callKey, loc: callLoc, name: callee } = call;
  if (!isInConstArray(callCat, CIRCUIT_PANEL_CATEGORIES)) {
    nodeErrors.push({
      key: callKey,
      msg: 'only circuit or infrastructure calls can be unbundled',
    });
  }

  if (!callee) {
    nodeErrors.push({
      key: callKey,
      msg: 'call does not name a called circuit; cannot unbundle',
    });
  }

  // get the name of the caller (currently active diagram)
  const {
    modelData: { name: caller },
  } = activeModel;

  if (nodeErrors.length > 0) {
    return { projectErrors: { circuitErrors: { [caller]: { nodeErrors } } } };
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // find the caller and callee circuits
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  // fetch the list of circuits from the project.
  // NOTE these are already deep-copies.
  const { circuits } = project;

  // find the caller and callee; and count the # of calls to callee
  let callerIndex = -1;
  let calleeIndex = -1;
  let numberOfCallsToCallee = 0;

  circuits.forEach((circuit, i) => {
    const { name, nodes } = circuit;

    if (name === caller) callerIndex = i;
    else if (name === callee) calleeIndex = i;

    if (nodes) {
      nodes!.forEach((node) => {
        if (node.name === callee) {
          ++numberOfCallsToCallee;
        }
      });
    }
  });

  if (callerIndex < 0) {
    nodeErrors.push({
      key: callKey,
      msg: `can't find caller circuit ${caller} in project`,
    });
  }

  if (calleeIndex < 0) {
    nodeErrors.push({
      key: callKey,
      msg: `can't find called circuit ${callee} in project`,
    });
  }

  if (nodeErrors.length > 0) {
    return { projectErrors: { circuitErrors: { [caller]: { nodeErrors } } } };
  }

  const callerCircuit = circuits[callerIndex];
  const calleeCircuit = circuits[calleeIndex];

  const { nodes: callerNodes, edges: callerEdges } = callerCircuit;
  const { nodes: calleeNodes, edges: calleeEdges } = calleeCircuit;

  // CoreModel defines these types as optional, which seems not right
  if (callerNodes === undefined) throw new Error(`callerNodes is undefined`);
  if (callerEdges === undefined) throw new Error(`callerEdges is undefined`);
  if (calleeNodes === undefined) throw new Error(`calleeNodes is undefined`);
  if (calleeEdges === undefined) throw new Error(`calleeEdges is undefined`);

  // prepare to generate new (unused) node and edge keys
  let minNodeKey = 0;
  let minEdgeKey = 0;

  callerNodes!.forEach((node) => {
    const k = node.key;
    if (k < minNodeKey) minNodeKey = k;
  });
  callerEdges!.forEach((edge) => {
    const k = edge.key;
    if (k < minEdgeKey) minEdgeKey = k;
  });

  const genNodeKey = () => {
    return --minNodeKey;
  };
  const genEdgeKey = () => {
    return --minEdgeKey;
  };

  // build a new set of nodes and edges for the active diagram.
  const updatedNodes: Node[] = []; // this will replace the nodes of the caller
  const updatedEdges: Edge[] = []; // this will replace the edges of the caller

  // move the nodes and edges of the callee into the
  // updated nodes and edges array. params, returns, inlets and
  // outlets are not moved; they are discarded, but we
  // record their keys for further downstream
  // processing of them.

  const calleeParamPID = new Map<number, string>(); // param node key -> param pid (at call)
  const calleeReturnPID = new Map<number, string>(); // return node key -> return pid (at call)
  const calleeInletPID = new Map<number, string>(); // inlet node key -> inlet pid (at call)
  const calleeOutletPID = new Map<number, string>(); // outlet node key -> outlet pid (at call)
  const calleeNodeKeys = new Map<number, number>(); // old node key -> new node key

  calleeNodes!.forEach((node) => {
    const { cat, key } = node;
    switch (cat) {
      case 'param':
        calleeParamPID.set(key, port2callSitePID(node));
        break;
      case 'return':
        calleeReturnPID.set(key, port2callSitePID(node));
        break;
      case 'inlet':
        calleeInletPID.set(key, port2callSitePID(node));
        break;
      case 'outlet':
        calleeOutletPID.set(key, port2callSitePID(node));
        break;
      default: {
        // generate a new key for this node
        const newKey = genNodeKey();

        // assign it to the node
        node.key = newKey;

        // map the old key to the new
        calleeNodeKeys.set(key, newKey);

        // add the node to updatedNodes.  other than param, inlet and outlet,
        // every node in the callee is preserved as-is and moved to the caller.
        updatedNodes.push(node);

        break;
      }
    }
  });

  // remap the node keys in the from and to properties of
  // edges, and record the set of nodes that are incident
  // to each param, return, inlet and outlet.

  const calleeParamIncident = new Map<string, Set<Edge>>(); // param pid -> set of edges incident to the param
  const calleeReturnIncident = new Map<string, Set<Edge>>(); // return pid -> set of edges incident to the return
  const calleeInletIncident = new Map<string, Set<Edge>>(); // inlet pid -> set of edges incident to the inlet
  const calleeOutletIncident = new Map<string, Set<Edge>>(); // outlet pid -> set of edges incident to the outlet

  calleeEdges.forEach((edge) => {
    const { from: fromKey, to: toKey } = edge;

    if (
      (calleeInletPID.has(fromKey) ? 1 : 0) + (calleeOutletPID.has(toKey) ? 1 : 0) >
      1
    ) {
      // if an inlet is connected directly to an outlet, we can't inline this circuit.
      // we could solve this problem by splitting the edge with an echo node, which is
      // how the transpiler resolves this situation.  but in the interest of a kind of
      // purity about bundling and unbundling components, let's not.
      edgeErrors.push({
        key: edge.key,
        msg: 'cannot unbundle circuit with inlet connected directly to outlet',
      });
    }

    if (
      (calleeParamPID.has(fromKey) ? 1 : 0) + (calleeReturnPID.has(toKey) ? 1 : 0) >
      1
    ) {
      // same handling for param connected directly to a return
      edgeErrors.push({
        key: edge.key,
        msg: 'cannot unbundle circuit with param connected directly to return',
      });
    }

    if (calleeParamPID.has(fromKey)) {
      const pid = calleeParamPID.get(fromKey)!;
      if (!calleeParamIncident.has(pid)) calleeParamIncident.set(pid, new Set());
      calleeParamIncident.get(pid)!.add(edge);
    } else if (calleeReturnPID.has(toKey)) {
      const pid = calleeReturnPID.get(toKey)!;
      if (!calleeReturnIncident.has(pid)) calleeReturnIncident.set(pid, new Set());
      calleeReturnIncident.get(pid)!.add(edge);
    } else if (calleeInletPID.has(fromKey)) {
      const pid = calleeInletPID.get(fromKey)!;
      if (!calleeInletIncident.has(pid)) calleeInletIncident.set(pid, new Set());
      calleeInletIncident.get(pid)!.add(edge);
    } else if (calleeOutletPID.has(toKey)) {
      const pid = calleeOutletPID.get(toKey)!;
      if (!calleeOutletIncident.has(pid)) calleeOutletIncident.set(pid, new Set());
      calleeOutletIncident.get(pid)!.add(edge);
    } else {
      // map the original node keys on the edges to their new values
      edge.from = calleeNodeKeys.get(fromKey)!;
      edge.to = calleeNodeKeys.get(toKey)!;

      // generate a new edge key
      edge.key = genEdgeKey();

      // add the edge to callee edges
      updatedEdges.push(edge);
    }
  });

  if (edgeErrors.length > 0) {
    // Type assertion reason: truthy check from line 47
    return { projectErrors: { circuitErrors: { [callee!]: { edgeErrors } } } };
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // if we reach this point, then the required conditions are
  // satisfied for unbundling (inlining) the called circuit.
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // move the nodes and edges of the caller into the
  // updated nodes and edges, eliding  the call node
  // itself and updating edges incident to it
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  callerEdges.forEach((edge) => {
    if (edge.to === callKey) {
      const pid = edge.dst;
      if (calleeParamIncident.has(pid)) {
        calleeParamIncident.get(pid)!.forEach((edge2) => {
          const newedge = {
            key: genEdgeKey(),
            from: edge.from,
            src: edge.src,
            to: calleeNodeKeys.get(edge2.to)!,
            dst: edge2.dst,
          };
          updatedEdges.push(newedge);
        });
      } else if (calleeInletIncident.has(pid)) {
        calleeInletIncident.get(pid)!.forEach((edge2) => {
          const newedge = {
            key: genEdgeKey(),
            from: edge.from,
            src: edge.src,
            to: calleeNodeKeys.get(edge2.to)!,
            dst: edge2.dst,
          };
          updatedEdges.push(newedge);
        });
      }
    } else if (edge.from === callKey) {
      const pid = edge.src;
      if (calleeReturnIncident.has(pid)) {
        calleeReturnIncident.get(pid)!.forEach((edge2) => {
          const newedge = {
            key: genEdgeKey(),
            from: calleeNodeKeys.get(edge2.from)!,
            src: edge2.src,
            to: edge.to,
            dst: edge.dst,
          };
          updatedEdges.push(newedge);
        });
      } else if (calleeOutletIncident.has(pid)) {
        calleeOutletIncident.get(pid)!.forEach((edge2) => {
          const newedge = {
            key: genEdgeKey(),
            from: calleeNodeKeys.get(edge2.from)!,
            src: edge2.src,
            to: edge.to,
            dst: edge.dst,
          };
          updatedEdges.push(newedge);
        });
      }
    } else {
      updatedEdges.push(edge);
    }
  });

  // in copying the caller's nodes, we clear a box around the call
  // node to accomodate the inlined circuit.

  // updatedNodes contains exactly the nodes, copied from the callee,
  // that we need to make room for.
  const { minX, maxX, minY, maxY } = locationExtrema(updatedNodes);

  const width = maxX - minX;
  const height = maxY - minY;

  const halfwidth = Math.round(width * 0.5);
  const halfheight = Math.round(height * 0.5);

  const callxy = callLoc.split(' ');
  const callx = parseInt(callxy[0], 10);
  const cally = parseInt(callxy[1], 10);

  // coordinates relative to caller's diagram of upper-left corner
  // of the box that will hold the inlined circuit, surrounding the
  // location of the original call.
  const cornerX = callx - halfwidth;
  const cornerY = cally - halfheight;

  // updatedNodes contains all the non-port nodes of the callee.
  // normalize updatedNodes so that the upper left corner is (0,0).
  updatedNodes.forEach((node) => {
    const { loc: nodeLoc } = node;
    const xy = nodeLoc.split(' ');
    const x = parseInt(xy[0], 10) - minX + cornerX;
    const y = parseInt(xy[1], 10) - minY + cornerY;

    node.loc = `${x} ${y}`;
  });

  // if the callee is no longer called, delete it from the circuits list.
  if (numberOfCallsToCallee === 1) {
    circuits.splice(calleeIndex, 1);
  }

  // add the caller nodes (other than the call) to updatedNodes
  callerNodes.forEach((node) => {
    const { key } = node;

    if (key !== callKey) {
      const xy = node.loc.split(' ');
      let x = parseInt(xy[0], 10);
      let y = parseInt(xy[1], 10);

      if (x <= callx) x -= halfwidth + 50;
      else x += halfwidth + 50;

      if (y <= cally) y -= halfheight + 50;
      else y += halfheight + 50;

      node.loc = `${x} ${y}`;

      updatedNodes.push(node);
    }
  });

  // replace the nodes and edges of the caller circuit
  callerCircuit.nodes = updatedNodes;
  callerCircuit.edges = updatedEdges;

  return { newProject: project };
};

// this works in terms of model data (Node) rather than go.Node
const locationExtrema = (nodes: Node[]) => {
  if (nodes.length === 0) {
    return { minX: 0, maxX: 0, minY: 0, maxY: 0 };
  }

  let minX = +1e32;
  let maxX = -1e32;
  let minY = +1e32;
  let maxY = -1e32;

  nodes.forEach((node) => {
    const { loc } = node;

    const xy = loc.split(' ');
    const x = parseInt(xy[0], 10);
    const y = parseInt(xy[1], 10);

    if (x < minX) minX = x;
    if (x > maxX) maxX = x;
    if (y < minY) minY = y;
    if (y > maxY) maxY = y;
  });

  return { minX, maxX, minY, maxY };
};
