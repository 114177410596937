import React, { ReactNode } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';

import { Header } from '../Header';

type PageLayoutType = {
  children: ReactNode;
  headerContent?: ReactNode;
  isEditorPage?: boolean;
};

export const PageLayout = (props: PageLayoutType) => {
  const { children, headerContent, isEditorPage } = props;

  const backgroundColor = useColorModeValue('lightblue', 'whiteAlpha.200');

  return (
    <Flex height="100vh" direction="column" backgroundColor={backgroundColor}>
      <Header isEditorPage={isEditorPage}>{headerContent}</Header>

      <Flex as="main" flex={1} flexDirection="column" overflow="hidden">
        {children}
      </Flex>
    </Flex>
  );
};
