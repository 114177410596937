import * as go from 'gojs';
import { TRANSACTIONS } from 'services/GoJS/const';
import { getActiveModel } from 'services/GoJS/dom';
// eslint-disable-next-line import/no-cycle
import { ProjectService } from 'services/ProjectService';
import { CallGraph } from 'types/project';
import { CIRCUIT_PANEL_CATEGORIES, Node } from 'types/transparency';
// eslint-disable-next-line import/no-cycle
import { getUserDefinedTypeFromPorts } from './transparency/types';
import { port2callSitePID } from './port';

/**
 * Comparator function for sorting nodes by their loc property.
 */
const compareNodeLoc = (a: go.ObjectData, b: go.ObjectData, index: number) => {
  // loc is a pair of space-separated numbers ([0] = x dimension, [1] = y dimension)
  const locA = a.loc.split(' ')[index];
  const locB = b.loc.split(' ')[index];

  return locA - locB;
};
const compareNodeLocX = (a: go.ObjectData, b: go.ObjectData) => compareNodeLoc(a, b, 0);
const compareNodeLocY = (a: go.ObjectData, b: go.ObjectData) => compareNodeLoc(a, b, 1);

/**
 * when entering a diagram, we build key -> caller PID
 * when leaving, we build caller PID -> key (ports will have been renamed).
 * when updating a call, we compose PID -> key -> PID to get a map from
 * new names to original names of the ports.
 */
export const buildKeyPidMaps = (model: go.GraphLinksModel, entering: boolean) => {
  const Key2PID = new Map<number, string>(); // Key to caller PID
  const PID2Key = new Map<string, number>(); // caller PID to Key

  model.nodeDataArray.forEach((data) => {
    switch (data.cat) {
      case 'inlet':
      case 'outlet':
      case 'fioutlet':
      case 'chooseoutlet':
      case 'param':
      case 'return': {
        const pid = port2callSitePID(data);
        Key2PID.set(data.key, pid);
        PID2Key.set(pid, data.key);
        break;
      }

      default:
        break;
    }
  });

  let { _oldKey2PID, _newKey2PID, _oldPID2Key, _newPID2Key } = model.modelData;

  if (entering || _oldKey2PID === undefined || _oldPID2Key === undefined) {
    _oldKey2PID = Key2PID;
    _oldPID2Key = PID2Key;
  }

  if (!entering) {
    _newKey2PID = Key2PID;
    _newPID2Key = PID2Key;
  }

  return {
    _oldKey2PID,
    _newKey2PID,
    _oldPID2Key,
    _newPID2Key,
  };
};

/**
 * Updates modelData of a diagram model to reflect its inlets, outlets, and params,
 * which may have changed (added, removed, renamed, reordered).
 *
 * This function mutates the input diagram model.
 *
 * @param calleeModel GraphLinksModel to be updated (NOTE: this will be mutated)
 */
export const syncPush = (calleeModel: go.GraphLinksModel) => {
  const { nodeDataArray: nodes } = calleeModel;

  const inlets: go.ObjectData[] = [];
  const outlets: go.ObjectData[] = [];
  const params: go.ObjectData[] = [];
  const returns: go.ObjectData[] = [];

  nodes.forEach((node) => {
    switch (node.cat) {
      case 'inlet':
        inlets.push(node);
        break;

      case 'outlet':
      case 'fioutlet':
      case 'chooseoutlet':
        outlets.push(node);
        break;

      case 'param':
        params.push(node);
        break;

      case 'return':
        returns.push(node);
        break;

      default:
        break;
    }
  });

  // Sort port arrays then map to port IDs
  // Inlets and outlets are sorted by vertical position (top to bottom)
  const inletPortIds = inlets.sort(compareNodeLocY).map(port2callSitePID);
  const outletPortIds = outlets.sort(compareNodeLocY).map(port2callSitePID);
  const paramPortIds = params.sort(compareNodeLocX).map(port2callSitePID);
  const returnPortIds = returns.sort(compareNodeLocX).map(port2callSitePID);

  const { modelData } = calleeModel;

  modelData.inlets = inletPortIds;
  modelData.outlets = outletPortIds;
  modelData.params = paramPortIds;
  modelData.returns = returnPortIds;

  // update Key2PID and PID2Key maps
  return buildKeyPidMaps(calleeModel, false);
};

export const nullifyCall = (model: go.Model, data: go.ObjectData) => {
  model.setDataProperty(data, 'inports', []);
  model.setDataProperty(data, 'outports', []);
  model.setDataProperty(data, 'parports', []);
  model.setDataProperty(data, 'name', '');
};

const syncCallPorts = (
  model: go.Model,
  data: go.ObjectData,
  inlets: string[],
  outlets: string[],
  params: string[],
  returns: string[],
  newPID2Key: Map<string, number>,
  oldKey2PID: Map<number, string>
) => {
  const newPID2OldPID = (newPid: string) => {
    if (newPID2Key === undefined || oldKey2PID === undefined) return newPid;

    const key = newPID2Key.get(newPid);
    return key !== undefined && oldKey2PID.has(key) ? oldKey2PID.get(key)! : newPid;
  };

  // Default port arrays to empty array in case of undefined
  const { inports = [], outports = [], parports = [], argports = [] } = data as Node;

  const inPorts = inlets.map((pid) => ({
    pid,
    type: getUserDefinedTypeFromPorts(inports, newPID2OldPID(pid)),
  }));
  const outPorts = outlets.map((pid) => ({
    pid,
    type: getUserDefinedTypeFromPorts(outports, newPID2OldPID(pid)),
  }));
  const parPorts = params.map((pid) => ({
    pid,
    type: getUserDefinedTypeFromPorts(parports, newPID2OldPID(pid)),
  }));
  const argPorts = returns.map((pid) => ({
    pid,
    type: getUserDefinedTypeFromPorts(argports, newPID2OldPID(pid)),
  }));

  model.setDataProperty(data, 'inports', inPorts);
  model.setDataProperty(data, 'outports', outPorts);
  model.setDataProperty(data, 'parports', parPorts);
  model.setDataProperty(data, 'argports', argPorts);
};

/**
 * Visits a caller circuit and updates them to reflect new inlets, outlets,
 * and params.
 *
 * When this function is called, data.name may or may not be equal to name.
 * This is because we sometimes call it while a Binding is being evaluated,
 * that transfers an edited name to the model (data.name).
 *
 * @param data - Node data corresponding to a key on the callerModel
 * @param callerModel - A caller of the active circuit
 * @param name Name of the circuit being called
 */
export const syncPull = (data: go.ObjectData, callerModel: go.Model, name: string) => {
  if (!CIRCUIT_PANEL_CATEGORIES.includes(data.cat)) return;

  if (!name) {
    nullifyCall(callerModel, data);
    return;
  }

  const remoteModel = ProjectService.getDiagramModelByName(name);

  if (!remoteModel) {
    nullifyCall(callerModel, data);
    return;
  }

  const { inlets, outlets, params, returns, _newPID2Key, _oldKey2PID } =
    remoteModel.modelData;

  syncCallPorts(
    callerModel,
    data,
    inlets,
    outlets,
    params,
    returns,
    _newPID2Key,
    _oldKey2PID
  );
};

export const sync = (callGraph: CallGraph) => {
  const calleeModel = getActiveModel();

  // it is possible for sync to be invoked when there is no active model
  if (!calleeModel) return;

  const { name: calleeName } = calleeModel.modelData;

  // Update model for the active diagram model.
  // returns an object that contains oldPID2Key, newKey2PID,
  // oldKey2PID and new PID2Key maps. assign those into the
  // callee model.
  Object.assign(calleeModel.modelData, syncPush(calleeModel));

  const { _oldPID2Key, _newKey2PID } = calleeModel.modelData;

  const oldPID2newPID = (oldpid: string) => {
    const key = _oldPID2Key.get(oldpid);
    return _newKey2PID.has(key) ? _newKey2PID.get(key) : oldpid;
  };

  // Update callers of the active diagram model
  const callers = callGraph.get(calleeName);
  callers?.forEach((keys, callerName) => {
    const callerModel = ProjectService.getDiagramModelByName(callerName);

    if (callerModel) {
      callerModel.startTransaction(TRANSACTIONS.syncPull);

      keys.forEach((key) => {
        const data = callerModel.findNodeDataForKey(key);
        if (data) syncPull(data, callerModel, calleeName);
      });

      callerModel.linkDataArray.forEach((edge) => {
        if (keys.has(edge.to)) {
          callerModel.setDataProperty(edge, 'dst', oldPID2newPID(edge.dst));
        } else if (keys.has(edge.from)) {
          callerModel.setDataProperty(edge, 'src', oldPID2newPID(edge.src));
        }
      });

      callerModel.commitTransaction(TRANSACTIONS.syncPull);
    }
  });
};
