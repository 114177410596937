import { useCallback, useEffect, useState } from 'react';
import { getJWTAuthBearer } from 'services/TokenService';

export type SessionConnectHandler = (ev: Event) => void;
export type SessionMessageHandler = (ev: MessageEvent<any>) => void;
export type SessionDisconnectHandler = (ev: CloseEvent) => void;
export type SessionSendHandler = (args: Message) => void;

export type WsConnectParam = {
  endpoint: string;
  params?: Record<string, string>;
};

export type SessionHook = {
  isConnected: boolean;
  isConnecting: boolean;
  isError: boolean;
  connect: (data: WsConnectParam) => void;
  sendMessage: (data: Message) => void;
  close: () => void;
};

export type Message = Record<string, any> | string;

export const useSession = (onMessage: SessionMessageHandler): SessionHook => {
  const [session, setSession] = useState<WebSocket>();
  const [isError, setError] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const onOpen = useCallback(() => {
    setIsConnected(true);
    setIsConnecting(false);
  }, []);

  const onClose = useCallback(() => {
    setSession(undefined);
    setIsConnected(false);
  }, []);

  const onError = useCallback((e) => {
    console.error('error', e);
    setError(true);
    setIsConnected(false);
    setIsConnecting(false);
  }, []);

  useEffect(() => {
    if (!session) return;
    session.addEventListener('open', onOpen);
    session.addEventListener('message', onMessage);
    session.addEventListener('close', onClose);
    session.addEventListener('error', onError);
    return () => {
      session.removeEventListener('open', onOpen);
      session.removeEventListener('message', onMessage);
      session.removeEventListener('close', onClose);
      session.removeEventListener('error', onError);
    };
  }, [session, onOpen, onClose, onError, onMessage]);

  const connect = useCallback(({ endpoint, params }: WsConnectParam) => {
    setIsConnecting(true);
    setError(false);
    try {
      const strParams = Object.entries(params ?? {}).reduce(
        (acc, [key, value], idx) => `${acc}${idx === 0 ? '?' : '&'}${key}=${value}`,
        ''
      );
      const ws = new WebSocket(endpoint + strParams, [
        encodeURIComponent(getJWTAuthBearer()),
      ]);
      setSession(ws);
    } catch (e) {
      console.error('connect-error', e);
      setError(true);
      setIsConnecting(false);
    }
  }, []);

  const sendMessage = useCallback(
    (data: Message) => {
      session?.send(typeof data === 'string' ? data : JSON.stringify(data));
    },
    [session]
  );

  // ping server every 5 seconds
  useEffect(() => {
    if (isConnected) {
      const ping = () => sendMessage('ping');

      const pingInterval = setInterval(() => {
        ping();
      }, 1000 * 5);

      return () => clearInterval(pingInterval);
    }
  }, [sendMessage, isConnected]);

  const close = useCallback(() => {
    if (session && session.readyState === session.OPEN) session.close();
  }, [session]);

  return { isConnected, isConnecting, isError, connect, sendMessage, close };
};
