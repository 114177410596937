import * as go from 'gojs';
import { TRANSACTIONS } from './const';

export const ALIGN_DIRECTIONS = ['left', 'right', 'top', 'bottom'];
export type AlignDirection = typeof ALIGN_DIRECTIONS[number];
type AlignFunction = (selection: go.Set<go.Part>) => void;

export const alignSelection = (
  diagram: go.Diagram,
  alignDirection: AlignDirection,
  minimumSelection = 2
) => {
  const { selection } = diagram;

  if (!canAlignSelection(selection, minimumSelection)) {
    throw new Error(`Selection must contain at least ${minimumSelection} nodes.`);
  }

  diagram.startTransaction(TRANSACTIONS.align);
  ALIGN_FUNCTION_MAP[alignDirection](selection);
  diagram.commitTransaction(TRANSACTIONS.align);
};

const canAlignSelection = (selection: go.Set<go.Part>, minimumSelection: number) => {
  let count = 0;

  selection.each((part) => {
    if (part instanceof go.Node) {
      count++;
    }
  });

  return count >= minimumSelection;
};

const alignLeft = (selection: go.Set<go.Part>) => {
  let minPosition = Infinity;

  selection.each((current) => {
    if (current instanceof go.Node) {
      minPosition = Math.min(current.position.x, minPosition);
    }
  });
  selection.each((current) => {
    if (current instanceof go.Node) {
      current.move(new go.Point(minPosition, current.position.y));
    }
  });
};

const alignRight = (selection: go.Set<go.Part>) => {
  let maxPosition = -Infinity;

  selection.each((current) => {
    if (current instanceof go.Node) {
      const rightSideLoc = current.actualBounds.x + current.actualBounds.width;
      maxPosition = Math.max(rightSideLoc, maxPosition);
    }
  });
  selection.each((current) => {
    if (current instanceof go.Node) {
      current.move(
        new go.Point(maxPosition - current.actualBounds.width, current.position.y)
      );
    }
  });
};

const alignTop = (selection: go.Set<go.Part>) => {
  let minPosition = Infinity;

  selection.each((current) => {
    if (current instanceof go.Node) {
      minPosition = Math.min(current.position.y, minPosition);
    }
  });
  selection.each((current) => {
    if (current instanceof go.Node) {
      current.move(new go.Point(current.position.x, minPosition));
    }
  });
};

const alignBottom = (selection: go.Set<go.Part>) => {
  let maxPosition = -Infinity;

  selection.each((current) => {
    if (current instanceof go.Node) {
      const bottomSideLoc = current.actualBounds.y + current.actualBounds.height;
      maxPosition = Math.max(bottomSideLoc, maxPosition);
    }
  });
  selection.each((current) => {
    if (current instanceof go.Node) {
      current.move(
        new go.Point(current.actualBounds.x, maxPosition - current.actualBounds.height)
      );
    }
  });
};

const ALIGN_FUNCTION_MAP: Record<AlignDirection, AlignFunction> = {
  left: alignLeft,
  right: alignRight,
  top: alignTop,
  bottom: alignBottom,
};
