import * as go from 'gojs';
import { z } from 'zod';
import { isInConstArray } from 'utils/string';

/**
 * ----------------------------------------------------------------------------
 * DICTIONARY TYPES
 * ----------------------------------------------------------------------------
 */

const nodeDictionaryEntrySchema = z.object({
  import: z.string().optional(),
  menu: z.string(),
  internaldoc: z.string().optional(),
  externaldoc: z.string().optional(),
  icon: z.string().optional(),
  verb: z.string().optional(),
  role: z.string().optional(),
  params: z.array(z.string()),
  argports: z.array(z.string()).optional(),
});

export type NodeDictionaryEntry = z.infer<typeof nodeDictionaryEntrySchema>;

export const PORT_TYPES = ['inports', 'outports', 'parports', 'argports'] as const;
const portTypeSchema = z.enum(PORT_TYPES);
export type PortType = z.infer<typeof portTypeSchema>;
const dynPortsSchema = z.record(portTypeSchema, z.string());
export type DynPorts = z.infer<typeof dynPortsSchema>;

export const tempDictionaryEntrySchema = z.object({
  comment: z.string(),
  menu: z.string(),
  internaldoc: z.string().optional(),
  externaldoc: z.string().optional(),
  icon: z.string().optional(),
  angle: z.number().optional(), // rotation angle in degrees
  color: z.string().optional(), // fill color
  params: z.array(z.string()).optional(),
  argports: z.array(z.string()).optional(),
  dynports: z.array(dynPortsSchema).optional(),
});

export type TempDictionaryEntry = z.infer<typeof tempDictionaryEntrySchema>;

/*
type ResourceRole =
  | 'resource_def'
  | 'resource_ref'
  | 'resource_special_def'
  | 'resource_special_ref';
*/

const cfnPortSchema = z.object({
  name: z.string(),
  type: z.string(),
});

export const cfnDictionaryEntrySchema = z.object({
  import: z.string(),
  menu: z.string(),
  internaldoc: z.string().optional(),
  externaldoc: z.string().optional(),
  icon: z.string(),
  role: z.string(),
  spec_type: z.string().optional(),
  output_type: z.string().optional(),
  params: z.array(cfnPortSchema).optional(),
  outports: z.array(cfnPortSchema).optional(),
  argports: z.array(cfnPortSchema).optional(),
});

export type CfnDictionaryEntry = z.infer<typeof cfnDictionaryEntrySchema>;

const combinedNodeDictionaryEntrySchema = z.union([
  nodeDictionaryEntrySchema,
  tempDictionaryEntrySchema,
  cfnDictionaryEntrySchema,
]);
export type CombinedNodeDictionaryEntry = z.infer<
  typeof combinedNodeDictionaryEntrySchema
>;

const typeDictionaryEntrySchema = z.object({
  import: z.string().optional(),
  metaid: z.string(),
  jsonid: z.string(),
  congid: z.string(),
});
export type TypeDictionaryEntry = z.infer<typeof typeDictionaryEntrySchema>;

// Node/type dictionaries
export const nodeDictionarySchema = z.record(nodeDictionaryEntrySchema);
export type NodeDictionary = z.infer<typeof nodeDictionarySchema>;
const tempDictionarySchema = z.record(tempDictionaryEntrySchema);
export type TempDictionary = z.infer<typeof tempDictionarySchema>;
const cfnDictionarySchema = z.record(cfnDictionaryEntrySchema);
export type CfnDictionary = z.infer<typeof cfnDictionarySchema>;
export const typeDictionarySchema = z.record(typeDictionaryEntrySchema);
export type TypeDictionary = z.infer<typeof typeDictionarySchema>;

const combinedNodeDictionarySchema = z.record(combinedNodeDictionaryEntrySchema);
export type CombinedNodeDictionary = z.infer<typeof combinedNodeDictionarySchema>;

// Component metadata dictionary
const producerParamSchema = z.object({
  description: z.string(),
  type: z.string().optional(),
  defaultValue: z.string().optional(),
  allowedValues: z.array(z.string()).optional(),
  dependsOn: z.string().optional(),
});
export type ProducerParamData = z.infer<typeof producerParamSchema>;

export const producerMetadataSchema = z.object({
  docURL: z.string().optional(),
  type: z.string(),
  params: z.record(producerParamSchema).optional(),
});
export type ProducerMetadata = z.infer<typeof producerMetadataSchema>;

const producerDictionarySchema = z.record(producerMetadataSchema);
export type ProducerDictionary = z.infer<typeof producerDictionarySchema>;

const awsServiceSchema = z.object({
  apis: z.array(z.string()).optional(),
  vpcEndpoints: z
    .array(
      z.object({
        name: z.string(),
        endpointType: z.string(),
      })
    )
    .optional(),
});

const awsServiceDictionarySchema = z.record(awsServiceSchema);
export type AwsServiceDictionary = z.infer<typeof awsServiceDictionarySchema>;

/**
 * ----------------------------------------------------------------------------
 * ERRORS
 * ----------------------------------------------------------------------------
 */
export type ErrorMessage = {
  key: number;
  msg: string;
};

export type CircuitError = {
  nodeErrors?: ErrorMessage[];
  edgeErrors?: ErrorMessage[];
  circErrors?: string[];
};

export type ProjectError = {
  circuitErrors?: Record<string, CircuitError>;
  projectErrors?: string[];
  illFormed?: boolean;
};

export const MESSAGE_TYPES = ['errors', 'warnings', 'notices'] as const;
const messageTypeSchema = z.enum(MESSAGE_TYPES);
export type MessageType = z.infer<typeof messageTypeSchema>;
export type ProjectMessages = Partial<Record<MessageType, ProjectError>>;

/**
 * ----------------------------------------------------------------------------
 * CIRCUITS
 * ----------------------------------------------------------------------------
 */

const messageSchema = z.object({
  type: messageTypeSchema,
  text: z.string(),
});

export type Message = z.infer<typeof messageSchema>;

const portSchema = z.object({
  pid: z.string(),
  type: z.string(),
});

export type Port = z.infer<typeof portSchema>;

const nodeSchema = z.object({
  key: z.number(),
  cat: z.string(),
  loc: z.string(),
  queue: z.number().optional(),
  name: z.string().optional(), // call nodes
  text: z.string().optional(), // arg/once/val/expr/comment nodes
  size: z.string().optional(), // comment/call nodes
  color: z.string().optional(), // comment nodes
  inports: z.array(portSchema).optional(),
  outports: z.array(portSchema).optional(),
  parports: z.array(portSchema).optional(),
  argports: z.array(portSchema).optional(),
  _message: messageSchema.optional(), // Errors/warnings/notices
  // Outdated fields
  jsontype: z.string().optional(),
  args: z.union([z.array(z.string()), z.record(z.string())]).optional(),
  callargs: z.record(z.string()).optional(),
});

export type Node = z.infer<typeof nodeSchema>;

const edgeSchema = z.object({
  key: z.number(),
  from: z.number(),
  to: z.number(),
  src: z.string(), // port pid for special nodes, param for basic node
  dst: z.string(), // port pid for special nodes, param for basic node
  type: z.string().optional(), // user-assigned type
  depth: z.number().optional(),
  temp: z.union([z.number(), z.string()]).optional(), // hack for working around GoJS link template limitations
  _message: messageSchema.optional(), // Errors/warnings/notices
});

export type Edge = z.infer<typeof edgeSchema>;

// Type guard
export const isEdge = (obj: go.ObjectData): obj is Edge => {
  const edge = obj as Edge;

  return (
    edge.from !== undefined &&
    edge.to !== undefined &&
    edge.src !== undefined &&
    edge.dst !== undefined
  );
};

export const TEXT_PANEL_CATEGORIES = ['text', 'javascript', 'python'] as const;
export type TextCategory = typeof TEXT_PANEL_CATEGORIES[number];

// Type guard
export const isTextCategory = (value: string): value is TextCategory => {
  return isInConstArray(value, TEXT_PANEL_CATEGORIES);
};

export const CIRCUIT_PANEL_CATEGORIES = ['circuit', 'infrastructure'] as const;
export type CircuitCategory = typeof CIRCUIT_PANEL_CATEGORIES[number];

export const INLET_OUTLET_NODES = [
  'inlet',
  'outlet',
  'fioutlet',
  'chooseoutlet',
] as const;
export const RENAMEABLE_NODES = [...INLET_OUTLET_NODES, 'return', 'param'] as const;
export type RenameableNode = typeof RENAMEABLE_NODES[number];

export const PANEL_CATEGORIES = [
  ...CIRCUIT_PANEL_CATEGORIES,
  ...TEXT_PANEL_CATEGORIES,
] as const;
const panelCategorySchema = z.enum(PANEL_CATEGORIES);
export type PanelCategory = z.infer<typeof panelCategorySchema>;

// meta_type (project.t)
const circuitMetaSchema = z.object({
  version: z.number(),
  threads: z.number().optional(),
  hidden: z.boolean().optional(),
  expanded: z.boolean().optional(),
  producer: z.string().optional(),
  params: z.record(z.string()).optional(),
  prefix: z.string().optional(),
});

export type CircuitMeta = z.infer<typeof circuitMetaSchema>;

export const coreModelSchema = z.object({
  name: z.string(),
  type: panelCategorySchema,
  text: z.string().optional(),
  meta: circuitMetaSchema.optional(),
  nodes: z.array(nodeSchema).optional(),
  edges: z.array(edgeSchema).optional(),
  nextNodeKey: z.number().optional(),
  nextEdgeKey: z.number().optional(),
});

// circuit_type (project.t)
export type CoreModel = z.infer<typeof coreModelSchema>;
