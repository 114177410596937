import { z } from 'zod';
import { EditorView } from '@codemirror/view';
import {
  CoreModel,
  coreModelSchema,
  nodeDictionarySchema,
  typeDictionarySchema,
} from './transparency';

// Outside of the web/editor code, the project representation does not
// contain the name, since the name is external to the project itself
// (like a file name vs the file contents).
export const storageProjectSchema = z.object({
  description: z.string().optional(),
  circuits: z.array(coreModelSchema),
  nodeDictionary: nodeDictionarySchema.optional(),
  typeDictionary: typeDictionarySchema.optional(),
});
export type StorageProject = z.infer<typeof storageProjectSchema>;
export type StorageFolder = undefined;
export const projectSchema = storageProjectSchema.extend({
  name: z.string(),
});
export type Project = z.infer<typeof projectSchema>;

// Representation of project text panels used for 'ingest' operation
export type ProjectText = {
  circuits: CoreModel[];
};

export type TextModel = CoreModel & { codeMirror?: EditorView };
// Map from `modelName` (string) to text panels CoreModels and CodeMirror instances
export type TextModelMap = Map<string, TextModel>;

// Map from caller name to set of caller keys
export type CallerKeyMap = Map<string, Set<number>>;
// Map from callee name to CallerNameMap
export type CallGraph = Map<string, CallerKeyMap>;

// Map from caller to set of its callees
export type CallerMap = Map<string, Set<string>>;
