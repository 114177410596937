import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDescription,
  Badge,
  Button,
  Divider,
  Flex,
  HStack,
  Link,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useDeleteStackMutation, useGetStacksDataQuery } from 'hooks/useConnectedModeApi';
import { getErrorMessage } from 'utils/error';
import {
  displayStackStatus,
  getStackConsoleLink,
  isStackActive,
} from 'hooks/useConnectedModeApi/util';
import { STACK_STATUS_PROGRESS, STACK_STATUS_SUCCESS } from 'services/api/ConnectedMode';
import { isInConstArray } from 'utils/string';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CenterSpinner } from 'components/CenterSpinner';
import { AlertWrapper } from 'components/Chakra/AlertWrapper';

type StacksDisplayProps = {
  accountId: string;
  stackArns: string[];
};

export const StacksDisplay = (props: StacksDisplayProps) => {
  const { accountId, stackArns } = props;

  const toast = useToast();

  const {
    data: stacks,
    isLoading,
    isError,
  } = useGetStacksDataQuery({ accountId, stackArns });

  const { mutate: deleteStackMutate, isLoading: isDeleteStackLoading } =
    useDeleteStackMutation();

  const handleDeleteStack = (stackId: string) => {
    deleteStackMutate(
      { stackArn: stackId, accountId },
      {
        onSuccess: () => {
          toast({
            title: `Initiated stack deletion for ${stackId}`,
            description: 'Stack deletion is in progress, this process may take a while',
            status: 'success',
            isClosable: true,
          });
        },
        onError: (e) => {
          toast({
            title: `Unable to initiate stack deletion for ${stackId}`,
            description: getErrorMessage(e),
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  if (isLoading)
    return (
      <CenterSpinner>
        <Text>Getting stacks details</Text>
      </CenterSpinner>
    );

  if (isError)
    return (
      <AlertWrapper>
        <AlertDescription>
          An unexpected error has occurred, please try again later
        </AlertDescription>
      </AlertWrapper>
    );

  if (!stacks.length)
    return (
      <Flex justifyContent="center">
        <Text>No stacks have been deployed</Text>
      </Flex>
    );

  return (
    <Accordion defaultIndex={[0]} allowMultiple={true}>
      {stacks.map(
        ({
          StackId,
          StackName,
          StackStatus,
          StackStatusReason,
          CreationTime,
          Parameters,
          Outputs,
        }) => (
          <AccordionItem key={StackId}>
            <h2>
              <AccordionButton>
                <Flex justifyContent="space-between" width="100%" align="center">
                  <HStack>
                    <AccordionIcon />
                    <Text>{StackName}</Text>
                  </HStack>
                  <Badge
                    colorScheme={
                      StackStatus === 'DELETE_COMPLETE'
                        ? undefined
                        : isInConstArray(StackStatus, STACK_STATUS_SUCCESS)
                        ? 'green'
                        : isInConstArray(StackStatus, STACK_STATUS_PROGRESS)
                        ? 'orange'
                        : 'red'
                    }
                    variant="outline"
                  >
                    {displayStackStatus(StackStatus)}
                  </Badge>
                </Flex>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Stack ml={10} fontSize="sm" spacing={2}>
                <Flex justifyContent="end">
                  <Link
                    color="teal"
                    href={getStackConsoleLink(StackId)}
                    isExternal={true}
                  >
                    View in CloudFormation console{' '}
                    <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                </Flex>
                <Divider />
                <Flex justifyContent="space-between" align="center">
                  <Text fontSize="md">Stack Identifier</Text>
                  <Text
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    pl={50}
                  >
                    {StackId}
                  </Text>
                </Flex>
                <Divider />
                {StackStatusReason && (
                  <>
                    <Flex justifyContent="space-between" align="center">
                      <Text fontSize="md">Stack status reason</Text>
                      <Text>{StackStatusReason}</Text>
                    </Flex>
                    <Divider />
                  </>
                )}
                {CreationTime && (
                  <>
                    <Flex justifyContent="space-between" align="center">
                      <Text fontSize="md">Creation time</Text>
                      <Text>{CreationTime}</Text>
                    </Flex>
                    <Divider />
                  </>
                )}
                <Flex justifyContent="space-between" align="center">
                  <Text fontSize="md">Parameters</Text>
                  {!Parameters.length && <Text>None</Text>}
                </Flex>
                {Parameters.length && (
                  <Table size="sm" variant="unstyled">
                    <Thead>
                      <Tr>
                        <Th>key</Th>
                        <Th>value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Parameters.map(({ ParameterKey, ParameterValue }) => (
                        <Tr key={ParameterKey}>
                          <Td>{ParameterKey}</Td>
                          <Td>{ParameterValue}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
                <Divider />
                <Flex justifyContent="space-between" align="center">
                  <Text fontSize="md">Outputs</Text>
                  {!Outputs.length && <Text>None</Text>}
                </Flex>
                {Outputs.length && (
                  <Table size="sm" variant="unstyled">
                    <Thead>
                      <Tr>
                        <Th>key</Th>
                        <Th>description</Th>
                        <Th>value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Outputs.map(({ OutputKey, Description, OutputValue }) => (
                        <Tr key={OutputKey}>
                          <Td>{OutputKey}</Td>
                          <Td>{Description || 'None'}</Td>
                          <Td>{OutputValue}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}

                {isStackActive(StackStatus) && (
                  <>
                    <Divider />
                    <Flex justifyContent="end">
                      <Button
                        colorScheme="red"
                        onClick={() => handleDeleteStack(StackId)}
                        isLoading={isDeleteStackLoading}
                      >
                        Delete stack
                      </Button>
                    </Flex>
                  </>
                )}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        )
      )}
    </Accordion>
  );
};
