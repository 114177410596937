import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ProjectMessages } from 'types/transparency';

export type ProjectContextValues = {
  projectRenderFlag: number;
  notifyProjectChange: () => void;
  callgraphRenderFlag: number;
  notifyCallgraphChange: () => void;
  projectIsLoading: boolean;
  setProjectIsLoading: Dispatch<SetStateAction<boolean>>;
  projectMessages: ProjectMessages | undefined;
  setProjectMessages: (projectMessages?: ProjectMessages) => void;
};

const ProjectContext = createContext<ProjectContextValues | undefined>(undefined);
ProjectContext.displayName = 'ProjectContext';

type ProjectContextProviderProps = {
  children: ReactNode;
};

export const ProjectContextProvider = ({ children }: ProjectContextProviderProps) => {
  // Dummy counter that is used to notify React of changes to the project
  const [projectRenderFlag, setProjectRenderFlag] = useState(0);
  const notifyProjectChange = useCallback(
    () => setProjectRenderFlag((prev) => prev + 1),
    []
  );

  // Dummy counter that is used to notify React of changes to the callgraph
  const [callgraphRenderFlag, setCallgraphRenderFlag] = useState(0);
  const notifyCallgraphChange = useCallback(() => {
    setCallgraphRenderFlag((prev) => prev + 1);
  }, []);

  const [projectIsLoading, setProjectIsLoading] = useState(false);
  const [projectMessages, setProjectMessages] = useState<ProjectMessages>();

  const values = useMemo(
    () => ({
      projectRenderFlag,
      notifyProjectChange,
      callgraphRenderFlag,
      notifyCallgraphChange,
      projectIsLoading,
      setProjectIsLoading,
      projectMessages,
      setProjectMessages,
    }),
    [
      projectRenderFlag,
      notifyProjectChange, // memoized with useCallback
      callgraphRenderFlag,
      notifyCallgraphChange, // memoized with useCallback
      projectMessages,
      projectIsLoading,
    ]
  );

  return <ProjectContext.Provider value={values}>{children}</ProjectContext.Provider>;
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);

  if (context === undefined) {
    throw new Error('useProjectContext must be used within a ProjectContextProvider');
  }

  return context;
};
