import React, {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import * as go from 'gojs';
import { useProjectContext } from 'context/useProjectContext';
import { addCallTemplateLazily } from 'services/GoJS';
import { ProjectService } from 'services/ProjectService';
import { buildKeyPidMaps, sync } from 'utils/sync';
import { CoreModel } from 'types/transparency';

import { panelHistoryReducer, LinkedPanel } from './panelHistory';

type DiagramContextValues = {
  activeLinkedPanel?: LinkedPanel;
  activeDiagram?: go.Diagram;
  activeTextModel?: CoreModel;
  setActivePanel: (name: string) => void;
  setPreviousPanelActive: () => void;
  setNextPanelActive: () => void;
  panelHistoryDelete: (name: string) => void;
  panelHistoryRename: (name: string, newName: string) => void;
  panelHistoryReset: () => void;
};

const DiagramContext = createContext<DiagramContextValues | undefined>(undefined);
DiagramContext.displayName = 'DiagramContext';

type DiagramContextProviderProps = {
  children: ReactNode;
};

const EMPTY_PANEL_HISTORY = {
  head: undefined,
  tail: undefined,
  active: undefined,
};

export const DiagramContextProvider = (props: DiagramContextProviderProps) => {
  const { children } = props;

  const [panelHistoryState, panelHistoryDispatch] = useReducer(
    panelHistoryReducer,
    EMPTY_PANEL_HISTORY
  );

  const { active: activeLinkedPanel } = panelHistoryState;
  const { name: currentName } = activeLinkedPanel ?? { name: undefined };

  const { projectRenderFlag } = useProjectContext();

  const { activeDiagram, activeTextModel } = useMemo(
    () => ({
      activeDiagram: ProjectService.getDiagramByName(activeLinkedPanel?.name ?? ''),
      activeTextModel: ProjectService.getTextCoreModelByName(
        activeLinkedPanel?.name ?? ''
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectRenderFlag, activeLinkedPanel?.name]
  );

  // perform any side effects before dispatching

  const setActivePanel = useCallback(
    (name: string) => {
      syncAndUpdateModel(name);
      if (currentName && name !== currentName) clearDivDiagram(currentName);
      panelHistoryDispatch({ type: 'SET_ACTIVE', name });
    },
    [currentName]
  );

  const setPreviousPanelActive = useCallback(() => {
    const name = activeLinkedPanel?.prev?.name;
    if (!name) return;

    syncAndUpdateModel(name);
    if (currentName) clearDivDiagram(currentName);
    panelHistoryDispatch({ type: 'PREV' });
  }, [activeLinkedPanel, currentName]);

  const setNextPanelActive = useCallback(() => {
    const name = activeLinkedPanel?.next?.name;
    if (!name) return;

    syncAndUpdateModel(name);
    if (currentName) clearDivDiagram(currentName);
    panelHistoryDispatch({ type: 'NEXT' });
  }, [activeLinkedPanel, currentName]);

  const panelHistoryDelete = useCallback((name: string) => {
    panelHistoryDispatch({ type: 'DELETE', name });
  }, []);

  const panelHistoryRename = useCallback((name: string, newName: string) => {
    panelHistoryDispatch({ type: 'RENAME', name, newName });
  }, []);

  const panelHistoryReset = useCallback(() => {
    panelHistoryDispatch({ type: 'RESET' });
  }, []);

  // set active to root if no active panel
  useEffect(() => {
    if (!panelHistoryState.active) {
      const rootPanelName = ProjectService.getRootPanelName();
      if (rootPanelName) setActivePanel(rootPanelName);
    }
  }, [panelHistoryState, setActivePanel]);

  // Reattach the setActivePanel callback to the call template whenever the setActivePanel is remounted
  useEffect(() => {
    addCallTemplateLazily(setActivePanel);
  }, [setActivePanel]);

  const value: DiagramContextValues = {
    activeLinkedPanel,
    activeDiagram,
    activeTextModel,
    setActivePanel,
    setPreviousPanelActive,
    setNextPanelActive,
    panelHistoryDelete,
    panelHistoryRename,
    panelHistoryReset,
  };

  return <DiagramContext.Provider value={value}>{children}</DiagramContext.Provider>;
};

export const useDiagramContext = () => {
  const context = useContext(DiagramContext);

  if (context === undefined) {
    throw new Error('useDiagramContext must be used within a DiagramContextProvider');
  }

  return context;
};

const syncAndUpdateModel = (name: string) => {
  const selectedDiagramModel = ProjectService.getDiagramModelByName(name);

  if (selectedDiagramModel) {
    // Sync before changing the panel
    sync(ProjectService.getCallGraph());
    // Update key/PID maps for the diagram we are entering
    Object.assign(
      selectedDiagramModel.modelData,
      buildKeyPidMaps(selectedDiagramModel, true)
    );
  }
};

const clearDivDiagram = (name: string) => {
  const diagram = ProjectService.getDiagramByName(name);
  if (!diagram) return;
  diagram.currentTool.stopTool();
  diagram.div = null;
};
