import React, { ReactNode, useCallback, useEffect } from 'react';

import { useUserContext } from 'context/useUserContext';
import { cloudStorageInstance } from 'services/api/CloudStorage';
import { cgDebugInstance } from 'services/api/Debugger';
import { paymentAxiosInstance } from 'services/api/Payment';
import { transpilerInstance } from 'services/api/Transpiler';
import { getRegionFromLocalStorage } from 'utils/region';
import { API_TYPE, getRegionalApiUrl } from 'utils/url';
import { connectedModeAxiosInstance } from 'services/api/ConnectedMode';

type NoRegionInterceptorProps = {
  children: ReactNode;
};

export const AxiosRegionInterceptor = ({ children }: NoRegionInterceptorProps) => {
  const { openLoginRedirectModal, region, setIsAuthed } = useUserContext();

  const getBaseUrlOrOpenModal = useCallback(
    (api: API_TYPE) => {
      const url = getRegionalApiUrl(api, getRegionFromLocalStorage());

      if (!url) {
        setIsAuthed(false);
        openLoginRedirectModal();
        throw new Error('No region found');
      }

      return url;
    },
    [openLoginRedirectModal, setIsAuthed]
  );

  // Add interceptors to the axios instances
  useEffect(() => {
    const cloudStorageInterceptor = cloudStorageInstance.interceptors.request.use(
      (config) => {
        config.baseURL = `${getBaseUrlOrOpenModal('STORAGE')}/storage`;
        return config;
      }
    );

    const transpilerInterceptor = transpilerInstance.interceptors.request.use(
      (config) => {
        config.baseURL = getBaseUrlOrOpenModal('TRANSPILE');
        return config;
      }
    );

    const debuggerInterceptor = cgDebugInstance.interceptors.request.use((config) => {
      config.baseURL = getBaseUrlOrOpenModal('DEBUG');
      return config;
    });

    const paymentInterceptor = paymentAxiosInstance.interceptors.request.use((config) => {
      config.baseURL = `${getBaseUrlOrOpenModal('PAYMENT')}/private`;
      return config;
    });

    const connectedModeInterceptor = connectedModeAxiosInstance.interceptors.request.use(
      (config) => {
        config.baseURL = `${getBaseUrlOrOpenModal('CONNECTED_MODE')}`;
        return config;
      }
    );

    return () => {
      cloudStorageInstance.interceptors.request.eject(cloudStorageInterceptor);
      transpilerInstance.interceptors.request.eject(transpilerInterceptor);
      cgDebugInstance.interceptors.request.eject(debuggerInterceptor);
      paymentAxiosInstance.interceptors.request.eject(paymentInterceptor);
      connectedModeAxiosInstance.interceptors.request.eject(connectedModeInterceptor);
    };
  }, [
    region,
    getBaseUrlOrOpenModal, // memoized
  ]);

  return <>{children}</>;
};
