import React, { useEffect, useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from 'components/Chakra/Modal';
import { ProjectService } from 'services/ProjectService';
import { StorageServiceClass } from 'services/StorageService';

export const ErrorOverlay = () => {
  const [isStashSuccessful, setIsStashSuccessful] = useState(false);

  useEffect(() => {
    try {
      if (!ProjectService.isEmpty()) {
        StorageServiceClass.saveProjectLocalStorage(ProjectService.getProject());
        setIsStashSuccessful(true);
      }
    } catch {
      setIsStashSuccessful(false);
    }
  }, []);

  return (
    <Modal
      isOpen={true}
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
      isCentered={true}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Something went wrong</ModalHeader>

        <ModalBody>
          {isStashSuccessful
            ? 'Your current project has automatically been saved in your browser. '
            : ''}
          Please refresh the page to continue using the editor.
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
