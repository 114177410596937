import React, { ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useUserContext } from 'context/useUserContext';

type PrivateRouteProps = {
  children?: ReactNode;
};

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { children } = props;

  const location = useLocation();

  const { isLoggedIn, isCoreographIframe } = useUserContext();

  const canAccessRoute = isLoggedIn || isCoreographIframe;

  if (!canAccessRoute) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Supports using this component both as a wrapper and
  // as a react-router layout route
  return children ? <>{children}</> : <Outlet />;
};
