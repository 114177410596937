import * as go from 'gojs';
import { CoreModel, PanelCategory } from 'types/transparency';

/**
 * ----------------------------------------------------------------------------
 * CORE MODEL
 * ----------------------------------------------------------------------------
 */

export const isValidModel = (coreModel: any): coreModel is CoreModel => {
  if (coreModel == null) return false;

  const { name, type, nodes, edges } = coreModel;

  // prettier-ignore
  return (typeof name === 'string' && name.length > 0)
      && (typeof type === 'string' && type.length > 0)
      && (Array.isArray(nodes))
      && (Array.isArray(edges))
};

export const makeBaseModel = (
  name = 'new',
  type: PanelCategory = 'infrastructure'
): CoreModel => ({
  name,
  type,
  // TODO: Get runtime version
  meta: { version: 2 },
  nodes: [],
  edges: [],
});

export const copyModelData = (obj: go.ObjectData) => {
  const newobj = JSON.parse(JSON.stringify(obj));
  delete newobj.__gohashid;
  delete newobj.key;
  return newobj;
};
