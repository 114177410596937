import React, { useState } from 'react';
import {
  useConnectedModeQuery,
  useRegisterConnectedModeMutation,
} from 'hooks/useConnectedModeApi';
import {
  AlertDescription,
  Badge,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { z } from 'zod';
import { useUserContext } from 'context/useUserContext';
import { DashboardPageCard } from 'components/DashboardPageCard';
import { CenterSpinner } from 'components/CenterSpinner';
import { AlertWrapper } from 'components/Chakra/AlertWrapper';
import { getErrorMessage } from 'utils/error';
import { getLocalAwsRegion } from 'utils/region';
import { StacksDisplay } from './StacksDisplay';
import { PendingVerificationContent } from './PendingVerificationContent';

const ConnectionsPage = () => {
  const { openLoginRedirectModal, setIsAuthed } = useUserContext();
  const toast = useToast();

  const {
    data: connectedModeData = [],
    isLoading: isConnectedModeLoading,
    isError: isConnectedModeError,
  } = useConnectedModeQuery();

  const [parentRegisterData, setParentRegisterData] = useState<{
    accountId?: string;
    registerLink?: string;
  }>({
    accountId: undefined,
    registerLink: undefined,
  });

  const { mutate: registerMutate, isLoading: isRegisterLoading } =
    useRegisterConnectedModeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(z.object({ accountId: z.string().length(12) })),
  });

  const handleRegister = ({ accountId }: { accountId: string }) => {
    const localAwsRegion = getLocalAwsRegion();
    if (!localAwsRegion) {
      setIsAuthed(false);
      openLoginRedirectModal();
      return;
    }

    registerMutate(
      { accountId, region: localAwsRegion },
      {
        onSuccess: (data) => {
          toast({
            title: `Started registration for account '${accountId}'`,
            description:
              'Deploy the setup CloudFormation stack in this AWS account to complete the registration process',
            status: 'success',
            isClosable: true,
          });
          setParentRegisterData(data);
        },
        onError: (e) =>
          toast({
            title: `Unable to start registration for account '${accountId}'`,
            description: getErrorMessage(e),
            status: 'error',
            isClosable: true,
          }),
      }
    );
  };

  if (isConnectedModeLoading)
    return (
      <DashboardPageCard heading="AWS Connections" colSpan={2}>
        <CenterSpinner>
          <Text>Getting Connected Mode details</Text>
        </CenterSpinner>
      </DashboardPageCard>
    );

  if (isConnectedModeError)
    return (
      <DashboardPageCard heading="AWS Connections" colSpan={2}>
        <AlertWrapper>
          <AlertDescription>
            Unable to get Connected Mode details, please try again later
          </AlertDescription>
        </AlertWrapper>
      </DashboardPageCard>
    );

  return (
    <>
      {connectedModeData.map(({ accountId, verified, stackArns }) => (
        <DashboardPageCard
          key={accountId}
          colSpan={2}
          heading={
            <Flex alignItems="center">
              AWS Account {accountId}
              <Badge
                colorScheme={verified === 'TRUE' ? 'green' : 'orange'}
                fontSize="md"
                ml={2}
                pt={0.5}
              >
                {verified === 'TRUE' ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  'Pending verification'
                )}
              </Badge>
            </Flex>
          }
        >
          {verified !== 'TRUE' ? (
            <PendingVerificationContent
              accountId={accountId}
              parentRegisterData={parentRegisterData}
            />
          ) : !stackArns.length ? (
            <Center height="100%">
              <Text>No stacks deployed</Text>
            </Center>
          ) : (
            <StacksDisplay accountId={accountId} stackArns={stackArns} />
          )}
        </DashboardPageCard>
      ))}

      <DashboardPageCard heading="Add a Connection">
        <form onSubmit={handleSubmit(handleRegister)}>
          <FormControl isInvalid={errors.accountId}>
            <Stack>
              <FormLabel mb={0} htmlFor="accountId" fontSize="sm">
                AWS account ID
              </FormLabel>
              <Flex>
                <Input
                  id="accountId"
                  placeholder="12-digit number"
                  mr={4}
                  {...register('accountId')}
                />
                <Button type="submit" colorScheme="blue" isLoading={isRegisterLoading}>
                  Register
                </Button>
              </Flex>
              <FormHelperText>
                Enter the 12-digit AWS account ID to establish a connection.
              </FormHelperText>
              <FormErrorMessage>{errors.accountId?.message}</FormErrorMessage>
            </Stack>
          </FormControl>
        </form>
      </DashboardPageCard>
    </>
  );
};

export default ConnectionsPage;
