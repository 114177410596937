import axios from 'axios';

import { StorageProject } from 'types/project';
import { CoreModel } from 'types/transparency';

export type ExamplePathMap = Record<string, string>;

export const getExamplePaths = async () => {
  const { data } = await axios.get<ExamplePathMap>('/example/index.json');

  return data;
};

export const getExample = async (examplePath: string) => {
  const { data } = await axios.get<StorageProject | CoreModel[]>(`/${examplePath}`);

  return data;
};
