import { PREFIX_SLOTNAME, PREFIX_TEXTTYPE, PREFIX_TYPENAME } from 'utils/regex';

/**
 * ----------------------------------------------------------------------------
 * Decode a typeid into a Transparency source code type.
 * Freestanding; does not require a type dictionary.
 * ----------------------------------------------------------------------------
 */
export const decodeType = (t: string) => {
  const S = t.split('&');
  const J: string[] = [];

  // for each component of the split, decode it and and push onto J
  for (let i = 0; i < S.length; i++) {
    const A: string[] = [];
    const s = S[i];
    const c = s.charAt(0);

    if (c === 'T' || c === 'v') A.push('<');

    decodeType0(s, 0, A);

    if (c === 'T' || c === 'v') A.push('>');

    J.push(A.join(''));
  }

  return J.join(' & ');
};

const decodeType0 = (t: string, i: number, A: string[]): number => {
  const c = t.charAt(i);

  switch (t.charAt(i)) {
    case 'v':
      return i + 1;

    case 's':
      A.push('string');
      return i + 1;
    case 'y':
      A.push('symbol');
      return i + 1;
    case 'r':
      A.push('regex');
      return i + 1;
    case 'p':
      A.push('match');
      return i + 1;
    case 'a':
      A.push('int8');
      return i + 1;
    case 'b':
      A.push('int16');
      return i + 1;
    case 'c':
      A.push('int32');
      return i + 1;
    case 'd':
      A.push('int');
      return i + 1;
    case 'e':
      A.push('uint8');
      return i + 1;
    case 'f':
      A.push('uint16');
      return i + 1;
    case 'g':
      A.push('uint32');
      return i + 1;
    case 'h':
      A.push('uint');
      return i + 1;
    case 'i':
      A.push('single');
      return i + 1;
    case 'j':
      A.push('double');
      return i + 1;
    case 'u':
      A.push('char');
      return i + 1;
    case 't':
      A.push('bool');
      return i + 1;
    case 'B':
      A.push('bitset');
      return i + 1;
    case 'I':
      A.push('idxset');
      return i + 1;
    case 'D':
      A.push('device');
      return i + 1;
    case 'U':
      A.push('buffer');
      return i + 1;
    case 'E':
      A.push('stream');
      return i + 1;

    case 'q': {
      A.push('shared ');
      return decodeType0(t, i + 1, A);
    }
    case 'k': {
      A.push('const ');
      return decodeType0(t, i + 1, A);
    }

    case 'V': {
      A.push('vector<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'L': {
      A.push('list<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'R': {
      A.push('ordset<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'S': {
      A.push('set<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'Z': {
      A.push('table<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'W': {
      A.push('wire<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'n': {
      A.push('in<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'o': {
      A.push('out<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'X': {
      A.push('trigger in<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'Y': {
      A.push('trigger out<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'J': {
      A.push('idxmap<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'K': {
      A.push('deque<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'P': {
      A.push('box<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }
    case 'Q': {
      A.push('pqueue<');
      const j = decodeType0(t, i + 1, A);
      A.push('>');
      return j;
    }

    case 'N': {
      A.push('ordmap<');
      const j = decodeType0(t, i + 1, A);
      A.push('>to<');
      const k = decodeType0(t, j, A);
      A.push('>');
      return k;
    }
    case 'M': {
      A.push('map<');
      const j = decodeType0(t, i + 1, A);
      A.push('>to<');
      const k = decodeType0(t, j, A);
      A.push('>');
      return k;
    }
    case 'F': {
      A.push('<');
      const j = decodeType0(t, i + 1, A);
      A.push('> <- <');
      const k = decodeType0(t, j, A);
      A.push('>');
      return k;
    }

    case 'A': {
      A.push('matrix{');
      const d = t.charAt(i + 1);
      A.push(d);
      A.push('}<');
      const j = decodeType0(t, i + 2, A);
      A.push('>');
      return j;
    }

    case 'T': {
      const s = t.substring(i);
      const m = /^T([0-9]+)/.exec(s)![1];
      const n = parseInt(m, 10);
      let j = i + 1 + m.length;

      for (let iter = 0; iter < n; ++iter) {
        if (iter > 0) A.push(', ');

        // look for a slot name in {}s
        let slotname = '';
        const match = PREFIX_SLOTNAME.exec(t.substring(j));
        if (match) {
          j += match[0].length;
          // eslint-disable-next-line prefer-destructuring
          slotname = match[1];
        }

        const char = t.charAt(j);
        if (char === 'T' || char === 'v') A.push('<');
        j = decodeType0(t, j, A);
        if (char === 'T' || char === 'v') A.push('>');

        if (slotname) A.push(` ${slotname}`);
      }
      return j;
    }

    case 'O': {
      A.push('[]');
      return i + 1;
    }

    case '<': {
      const s = t.substring(i);
      const m = PREFIX_TYPENAME.exec(s)![1];
      // Decoded type name is not surrounded by <>s
      A.push(m);
      return i + m.length + '<>'.length;
    }

    case '(': {
      const s = t.substring(i);
      // Includes parentheses
      const m = PREFIX_TEXTTYPE.exec(s)![0];
      A.push(m);
      return i + m.length;
    }

    case '?': {
      const s = t.substring(i);
      const m = /^\?[0-9]*/.exec(s)![0]; // necessarily matches
      A.push(m);
      return i + m.length;
    }

    default: {
      A.push(c);
      return i + 1;
    }
  }
};
