import { getRegionSubdomain } from './region';

export const DOCUMENTATION_HOME = `${process.env.REACT_APP_DOCUMENTATION_URL}/index.html`;

export const isLocalhost = () =>
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

export const isStaging = () => window.location.hostname === 'staging.coreograph.com';

// TODO: possibly drive URL logic from NODE_ENV rather than localhost check
// const isDevEnvironment = process.env.NODE_ENV === 'development';

const getHostname = () => `${window.location.protocol}//${window.location.hostname}`;

export const getEditorUrl = () => {
  if (isLocalhost()) {
    return `${window.location.protocol}//${window.location.host}`;
  }

  return getHostname();
};

export const isInFrame = () => {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
};

export const isCoreographSite = (hostname: string) => {
  const siteArray = ['https://coreograph.com/'];
  if (process.env.REACT_APP_DOCUMENTATION_URL) {
    siteArray.push(process.env.REACT_APP_DOCUMENTATION_URL);
  }

  return siteArray.includes(hostname);
};

export type API_TYPE =
  | 'IDENTITY'
  | 'STORAGE'
  | 'TRANSPILE'
  | 'DEBUG'
  | 'PAYMENT'
  | 'CONNECTED_MODE';

export const getRegionalApiUrl = (api: API_TYPE, region: string | undefined) => {
  const baseUrl = process.env[`REACT_APP_${api}_URL`];
  // Should not happen
  if (!baseUrl) throw new Error('Missing env variable for URL');

  const subdomain = getRegionSubdomain(region);
  if (!subdomain) return undefined;

  const url = new URL(baseUrl);
  url.hostname = `${subdomain}.${url.hostname}`;

  return `${url.protocol}//${url.hostname}`;
};
