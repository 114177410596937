import React, { ReactNode, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faBell,
  // faCog,
  faEllipsisV,
  faMoon,
  faQuestionCircle,
  faSun,
  faUser,
  faSignIn,
  faFlag,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorMode,
  useToast,
} from '@chakra-ui/react';

import { ReactComponent as Icon } from 'assets/coreograph-icon.svg';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from 'components/Chakra/AlertDialog';
import { useUserContext } from 'context/useUserContext';
import { useScreenSize } from 'hooks/useScreenSize';
import { useToggle } from 'hooks/useToggle';
import { ProjectService } from 'services/ProjectService';
import { StorageServiceClass } from 'services/StorageService';
import { getErrorMessage } from 'utils/error';
import { getRegionName } from 'utils/region';
import { SHOULD_SHOW_PREVIEW_MESSAGE } from 'utils/string';
import { DOCUMENTATION_HOME } from 'utils/url';
import { SAVE_FAILED_TOAST } from 'theme/components/Toast';

import { theme } from 'theme';

export const HEADER_HEIGHT = '53px';

type HeaderProps = {
  children?: ReactNode;
  isEditorPage?: boolean;
};

export const Header = (props: HeaderProps) => {
  const { children, isEditorPage } = props;

  /** USER STATE */
  const { signOut, isAuthed, isCoreographIframe, email, region } = useUserContext();

  /** A11Y/VIEWPORT STATE */
  const { isMobile } = useScreenSize();

  /** UI STATE - Sign out */
  const [isSignOutDialogOpen, , openSignOutDialog, closeSignOutDialog] = useToggle(false);
  const closeRef = useRef(null);

  /** CHAKRA COLOR MODE STATE */
  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const toast = useToast();

  const handleColorModeToggle = () => {
    if (isEditorPage) {
      // Should stash current project in localStorage before changing color mode,
      // since doing so reloads the diagrams/project.
      const currentProject = ProjectService.getProject();
      try {
        StorageServiceClass.saveProjectLocalStorage(currentProject);
      } catch (error: unknown) {
        toast({
          ...SAVE_FAILED_TOAST,
          description: getErrorMessage(error),
        });
      }
    }

    toggleColorMode();
  };

  const openIssuesPage = () => {
    window.open(process.env.REACT_APP_REPORT_ISSUE_URL, '_blank');
  };

  const openDocumentationSite = () => {
    window.open(DOCUMENTATION_HOME, '_blank');
  };

  return (
    <Flex
      as="header"
      height={HEADER_HEIGHT}
      alignItems="center"
      justifyContent="space-between"
      paddingLeft={6}
      paddingRight={6}
    >
      <Flex>
        <IconButton
          as={Link}
          to="/dashboard"
          icon={
            <Icon title="Coreograph Icon" height="36px" width="36px" cursor="pointer" />
          }
          aria-label="Coreograph"
          backgroundColor="transparent"
        />
        {SHOULD_SHOW_PREVIEW_MESSAGE && (
          <Badge colorScheme="blue" marginLeft={1} alignSelf="flex-start">
            Preview
          </Badge>
        )}
      </Flex>

      {children}

      {isMobile ? (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faEllipsisV} />}
            size="sm"
            variant="circle"
            aria-label="More"
          />
          <MenuList zIndex="sticky">
            <MenuItem
              icon={
                <FontAwesomeIcon
                  icon={faFlag}
                  color={theme.colors.yellow}
                  onClick={openIssuesPage}
                />
              }
            >
              Report an issue
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={faQuestionCircle} color={theme.colors.teal} />}
              onClick={openDocumentationSite}
            >
              Help
            </MenuItem>
            <MenuItem
              icon={<FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />}
              onClick={handleColorModeToggle}
            >
              Toggle dark mode
            </MenuItem>
            {!isCoreographIframe && isAuthed && (
              <MenuItem
                icon={<FontAwesomeIcon icon={faUser} />}
                onClick={openSignOutDialog}
              >
                User
              </MenuItem>
            )}
            {!isCoreographIframe && !isAuthed && (
              <MenuItem icon={<FontAwesomeIcon icon={faSignIn} />} onClick={signOut}>
                Log in
              </MenuItem>
            )}
            {/* <MenuItem>Settings</MenuItem> */}
            {/* <MenuItem>Notifications</MenuItem> */}
          </MenuList>
        </Menu>
      ) : (
        <ButtonGroup size="sm" variant="circle" spacing={2}>
          <Tooltip label="Report an issue">
            <IconButton
              color={theme.colors.yellow}
              icon={<FontAwesomeIcon icon={faFlag} />}
              aria-label="Report an issue"
              onClick={openIssuesPage}
            />
          </Tooltip>

          <Tooltip label="View documentation">
            <IconButton
              color={theme.colors.teal}
              icon={<FontAwesomeIcon icon={faQuestionCircle} />}
              aria-label="Help"
              onClick={openDocumentationSite}
            />
          </Tooltip>

          <Tooltip label="Toggle dark mode">
            <IconButton
              icon={<FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />}
              aria-label="Toggle dark mode"
              onClick={handleColorModeToggle}
            />
          </Tooltip>

          {!isCoreographIframe && isAuthed && (
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <IconButton icon={<FontAwesomeIcon icon={faUser} />} aria-label="User" />
              </PopoverTrigger>

              <PopoverContent rootProps={{ zIndex: 'popover' }}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader fontWeight="bold">Signed in</PopoverHeader>

                <PopoverBody>
                  {!!email && <Text>User: {email}</Text>}
                  <Text marginBottom={2}>Region: {getRegionName(region)}</Text>
                  <ButtonGroup>
                    <Button as={Link} to="/account">
                      View account
                    </Button>
                    <Button onClick={signOut} colorScheme="red">
                      Sign out
                    </Button>
                  </ButtonGroup>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
          {/* <IconButton icon={<FontAwesomeIcon icon={faCog} />} aria-label="Settings" /> */}

          {/* <IconButton
            icon={<FontAwesomeIcon icon={faBell} />}
            aria-label="Notifications"
          /> */}
          {!isCoreographIframe && !isAuthed && (
            <Button colorScheme="blue" variant="solid" onClick={signOut}>
              Log in
            </Button>
          )}
        </ButtonGroup>
      )}

      {/* ALERT DIALOG */}
      <AlertDialog
        isOpen={isSignOutDialogOpen}
        leastDestructiveRef={closeRef}
        onClose={closeSignOutDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Signed in</AlertDialogHeader>

            <AlertDialogBody>
              {!!email && <Text>User: {email}</Text>}
              <Button as={Link} to="/account">
                View account
              </Button>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={closeRef} onClick={closeSignOutDialog}>
                Close
              </Button>
              <Button onClick={signOut} colorScheme="red" marginLeft={3}>
                Sign out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
