import axios from 'axios';

export const getErrorMessage = (
  error: unknown,
  defaultMessage = 'There was an error'
) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 403) return 'Your session has expired.';
    const message =
      typeof error.response?.data === 'string' ? error.response.data : undefined;
    return message || error.response?.data.message || error.message || defaultMessage;
  }
  if (isQuotaExceededError(error)) {
    return 'Automatic saving failed because the current project is too large.';
  }
  return error instanceof Error ? error.message : defaultMessage;
};

export const isForbiddenError = (error: unknown) => {
  return axios.isAxiosError(error) && error.response?.status === 403;
};

export const isQuotaExceededError = (error: unknown) => {
  // Reference: https://mmazzarolo.com/blog/2022-06-25-local-storage-status/
  return (
    error instanceof DOMException &&
    // everything except Firefox
    (error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === 'QuotaExceededError' ||
      // Firefox
      error.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  );
};
