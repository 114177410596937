import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUserContext } from 'context/useUserContext';
import { storageProjectSchema } from 'types/project';
import { getProjectFromModels } from 'utils/project';
import { z } from 'zod';
import { createAxiosInstance, onRequestError } from '../utils';

const DEBUGGER = 'debugger';

export const cgDebugInstance = createAxiosInstance({
  isProtected: true,
});

const userDebugInstance = createAxiosInstance({
  isProtected: true,
});

export const debuggerDataSchema = z.object({ endpoint: z.string(), uuid: z.string() });

export type DebuggerData = z.infer<typeof debuggerDataSchema>;

export const debuggerRegister = async (debuggerData: DebuggerData) => {
  await cgDebugInstance.request({
    data: debuggerData,
    params: { act: 'register' },
  });
};

export const useDebuggerRegisterMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();
  return useMutation({
    mutationFn: (payload: DebuggerData) => debuggerRegister(payload),
    onError: (e) => onRequestError(setIsAuthed, e),
    onSuccess: () => {
      queryClient.invalidateQueries([DEBUGGER]);
    },
  });
};

export const debuggerInstancesList = async () => {
  const { data } = await cgDebugInstance.request({
    params: { act: 'list' },
  });

  return z.array(debuggerDataSchema).parse(data);
};

export const useDebuggerInstancesList = () => {
  const { setIsAuthed } = useUserContext();
  return useQuery({
    queryKey: [DEBUGGER],
    queryFn: () => debuggerInstancesList(),
    onError: (e) => onRequestError(setIsAuthed, e),
  });
};

const debuggerInstanceDataSchema = z
  .object({ uri: z.string(), project: z.string() })
  .transform(({ uri, project }) => ({
    uri,
    project: getProjectFromModels(storageProjectSchema.parse(JSON.parse(project))),
  }));

export const getDebuggerInstanceData = async ({ uuid, endpoint }: DebuggerData) => {
  const { data } = await userDebugInstance.request({
    data: { uuid },
    baseURL: endpoint,
  });

  return debuggerInstanceDataSchema.parse(data);
};
