import { z } from 'zod';

import { Location, NavigateFunction, Params } from 'react-router-dom';
import { ProjectService } from 'services/ProjectService';
import { StorageServiceClass } from 'services/StorageService';

export type RoutePath =
  | '/login'
  | '/register'
  | '/reset-password'
  | '/confirm-password'
  | '/verify-email'
  | '/dashboard'
  | '/account'
  | '/project'
  | '/';

export const saveProjectAndRedirect = (
  pathName: RoutePath,
  location: Location,
  navigate: NavigateFunction
) => {
  // Save current project (if non-empty) to localStorage if calling from /project
  if (location.pathname === '/project' && !ProjectService.isEmpty()) {
    const currentProject = ProjectService.getProject();
    StorageServiceClass.saveProjectLocalStorage(currentProject);
  }
  navigate(pathName);
};

/**
 * Helper for react-router useMatches.
 *
 * Based off https://github.com/remix-run/react-router/discussions/9534#discussioncomment-4136166
 */
type Match<HandleObject> = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: HandleObject;
};

export const getHandleFilter = <T extends z.AnyZodObject>(schema: T) => {
  type Schema = z.infer<typeof schema>;

  return (route: { handle: unknown } | Match<Schema>): route is Match<Schema> => {
    return schema.safeParse(route.handle).success;
  };
};
