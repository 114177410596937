import { useRef, useEffect, useState, useCallback } from 'react';
import { WsConnectParam, useSession } from '.';

type UseDualSessionProps = {
  onMessage: (e: MessageEvent) => void;
  connectInterval?: number;
  timeoutInterval?: number;
};

type UseDualSessionReturn = {
  connect: (data: WsConnectParam) => void;
  close: () => void;
  isConnected: boolean;
  isError: boolean;
  isConnecting: boolean;
};

export const useDualSession = ({
  // max ws connection is 2 hours in ms
  connectInterval = 60 * 90 * 1000,
  // in seconds
  timeoutInterval = 15,
  onMessage,
}: UseDualSessionProps): UseDualSessionReturn => {
  const [connectData, setConnectData] = useState<WsConnectParam>();
  // maintain timer for checking if websocket connection is responsive
  const [wsTimeout, setWsTimeout] = useState(timeoutInterval);
  const [timeoutError, setTimeoutError] = useState(false);

  const _onMessage = (e: MessageEvent) => {
    if (e.data === 'ping') {
      setWsTimeout(timeoutInterval);
      return;
    }
    onMessage(e);
  };

  const {
    isConnected: isConnected1,
    isConnecting: isConnecting1,
    isError: isError1,
    connect: connect1,
    close: close1,
  } = useSession(_onMessage);

  const {
    isConnected: connected2,
    isConnecting: connecting2,
    isError: error2,
    connect: connect2,
    close: close2,
  } = useSession(_onMessage);

  const isConnected = isConnected1 || connected2;
  const isError = isError1 || error2 || timeoutError;
  const isConnecting = isConnecting1 || connecting2;

  const nestedTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (!connectData) return;
    connect1(connectData);

    const connectTimeout1 = setTimeout(() => {
      connect2(connectData);
    }, connectInterval);

    const connectInterval1 = setInterval(() => {
      connect1(connectData);

      nestedTimeoutRef.current = setTimeout(() => {
        connect2(connectData);
      }, connectInterval);
    }, connectInterval * 2);

    return () => {
      clearTimeout(connectTimeout1);
      clearInterval(connectInterval1);
      if (nestedTimeoutRef.current) clearTimeout(nestedTimeoutRef.current);
    };
  }, [connect1, connect2, connectData, connectInterval]);

  useEffect(() => {
    const pingInterval = setInterval(() => {
      if (isConnected) setWsTimeout((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(pingInterval);
  }, [setWsTimeout, isConnected]);

  const close = useCallback(() => {
    setConnectData(undefined);
    setWsTimeout(timeoutInterval);
    close1();
    close2();
  }, [close1, close2, timeoutInterval]);

  useEffect(() => {
    if (wsTimeout === 0) {
      setTimeoutError(true);
      close();
    }
  }, [wsTimeout, close]);

  const connect = useCallback((data: WsConnectParam) => {
    setTimeoutError(false);
    setConnectData(data);
  }, []);

  return { connect, close, isConnected, isError, isConnecting };
};
