export const parseBooleanString = (booleanString: string): boolean | undefined => {
  try {
    const boolean = JSON.parse(booleanString);
    return boolean;
  } catch {
    return undefined;
  }
};

export const SHOULD_SHOW_PREVIEW_MESSAGE = parseBooleanString(
  process.env.REACT_APP_SHOW_PREVIEW_MESSAGE ?? ''
);

export const getNounArticle = (noun: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.includes(noun[0]) ? 'an' : 'a';
};

export const capitalize = (word: string) => {
  return `${word[0].toUpperCase()}${word.slice(1)}`;
};

export const isInConstArray = <T extends string>(
  value: string,
  array: Readonly<T[]>
): value is T => {
  return array.some((node) => node === value);
};

export const getCurrencyString = (value: number, options?: Intl.NumberFormatOptions) => {
  const { style = 'currency', currency = 'USD', ...other } = options ?? {};

  return new Intl.NumberFormat('en-US', { style, currency, ...other }).format(value);
};
