import * as go from 'gojs';

// eslint-disable-next-line import/no-cycle
import { ProjectService } from 'services/ProjectService';
import { Port, PortType, PORT_TYPES, TypeDictionaryEntry } from 'types/transparency';
import { BASE_TYPE_DICTIONARY } from 'utils/transparency/dictionaries';
import {
  ALL_TYPE_PARAMS,
  ALPHANUM,
  PREFIX_DECINT,
  OUTLINE,
  PREFIX_SLOTNAME,
  TYPED_ID,
  PREFIX_TYPENAME,
  PREFIX_TEXTTYPE,
} from '../regex';

const PACKAGE_PREFIX = '$';
const SCOPE_QUALIFIER = '::';

/**
 * ----------------------------------------------------------------------------
 * NODE CATEGORIES
 *
 * Our node categories have lexical conventions, but are also catalogued in
 * dictionaries. We use the lexical conventions to do the gross categorization,
 * as it handles more gracefully the situation in which an ID should be in a
 * dictionary but is not.
 * ----------------------------------------------------------------------------
 */
export const isBasicCategory = (category: string) => TYPED_ID.test(category);

export const isSpecialCategory = (category: string) => ALPHANUM.test(category);

export const isResourceCategory = (category: string) => OUTLINE.test(category);

// Extract everything up to type separator
export const getNameFromTypedId = (typedId: string) => {
  const m = TYPED_ID.exec(typedId);
  return m ? m[1] : typedId;
};

// Extract everything following the type separator
export const getTypeFromTypedId = (typedId: string) => {
  const m = TYPED_ID.exec(typedId);
  return m ? m[2] : '?';
};

// Remove package (...$) prefixes from name, which must have had its
// typed suffix stripped i.e. it is the output of getNameFromTypedId()
const removePackagePrefixFromName = (name: string) => {
  const lastSeparator = name.lastIndexOf(PACKAGE_PREFIX);
  return lastSeparator < 0 ? name : name.substring(lastSeparator + 1);
};

export const getFinalIdentifier = (typedId: string) => {
  const match = TYPED_ID.exec(typedId);

  if (match) {
    // If id is a typed id, strip package (...$) prefixes from it
    const name = match[1];
    return removePackagePrefixFromName(name);
  }

  // If id is not a typed id, strip its scope qualifiers (...::)
  const lastSeparator = typedId.lastIndexOf(SCOPE_QUALIFIER);
  return lastSeparator < 0
    ? typedId
    : typedId.substring(lastSeparator + SCOPE_QUALIFIER.length);
};

export const isPortType = (type: string) => /^[WXYno]/.test(type);
export const isInPortType = (type: string) => /^[WXn]/.test(type);
export const isOutPortType = (type: string) => /^[WYo]/.test(type);
export const isTrigPortType = (type: string) => /^[XY]/.test(type);

export const typeDefinesPortCategory = (type: string) => !/^[W]/.test(type);

export const isWireCarriedType = (type: string) => {
  switch (type[0]) {
    case 'X':
    case 'Y':
    case 'n':
    case 'o':
    case 'W': {
      return true;
    }
    case 'P': {
      switch (type[1]) {
        case 'n':
        case 'o': {
          return true;
        }
        default: {
          return false;
        }
      }
    }
    default: {
      return false;
    }
  }
};

export const getCarrierType = (type: string) => {
  const c = type.charAt(0);
  switch (c) {
    case 'X':
    case 'Y':
    case 'n':
    case 'o':
    case 'W': {
      return c;
    }
    case 'P': {
      switch (type.charAt(1)) {
        case 'n':
          return 'Pn';
        case 'o':
          return 'Po';
        default:
          return '';
      }
    }
    default: {
      return type;
    }
  }
};

export const getContentType = (type: string) => {
  switch (type.charAt(0)) {
    case 'X':
    case 'Y':
    case 'n':
    case 'o':
    case 'W': {
      return type.substring(1);
    }
    case 'P': {
      switch (type.charAt(1)) {
        case 'n':
        case 'o': {
          return type.substring(2);
        }
        default: {
          return type;
        }
      }
    }
    default: {
      return type;
    }
  }
};

const getContextFreeType = (type: string) => {
  return type.replace(ALL_TYPE_PARAMS, '?');
};

export const getContextFreeTypeFromTypedId = (typedId: string) => {
  return getContextFreeType(getTypeFromTypedId(typedId));
};

export const getContentTypeFromTypedId = (typedId: string) => {
  return getContentType(getTypeFromTypedId(typedId));
};

export const getContextFreeContentTypeFromTypedId = (typedId: string) => {
  return getContextFreeType(getContentType(getTypeFromTypedId(typedId)));
};

export const getUserDefinedTypeFromPorts = (ports: Port[], portId: string) => {
  for (let i = 0; i < ports.length; i++) {
    const { pid, type } = ports[i];
    if (pid === portId) return type;
  }

  return '?';
};

export const getUserDefinedType = (
  data: go.ObjectData,
  portId: string,
  portType: PortType
) =>
  data[portType]
    ? getUserDefinedTypeFromPorts(data[portType], portId)
    : getContextFreeContentTypeFromTypedId(portId);

const getUserInType = (data: go.ObjectData, portId: string) =>
  getUserDefinedType(data, portId, 'inports');
const getUserOutType = (data: go.ObjectData, portId: string) =>
  getUserDefinedType(data, portId, 'outports');
const getUserParType = (data: go.ObjectData, portId: string) =>
  getUserDefinedType(data, portId, 'parports');
const getUserArgType = (data: go.ObjectData, portId: string) =>
  getUserDefinedType(data, portId, 'argports');

// inport or parport
export const getUserInputType = (data: go.ObjectData, portId: string) =>
  isDynamicPort(portId) ? getUserInType(data, portId) : getUserParType(data, portId);

// output or argport
export const getUserOutputType = (data: go.ObjectData, portId: string) =>
  isDynamicPort(portId) ? getUserOutType(data, portId) : getUserArgType(data, portId);

export const isDynamicPort = (pid: string) => isPortType(getTypeFromTypedId(pid));
export const isStaticPort = (pid: string) => !isPortType(getTypeFromTypedId(pid));

/**
 * If `t` is a typename (<identifier>), then expand it to its definition
 * in the type dictionary, and repeat. This assumes that the dictionary
 * does not have any vacuous self-loops of typenames.
 */
const expandType = (t: string) => {
  let expandedType = t;

  while (expandedType.charAt(0) === '<') {
    const typedef = expandTypeName(expandedType);
    if (typedef) expandedType = typedef;
    // Leave expandedType in the form <typename> if there is no definition of typename
    else break;
  }

  return expandedType;
};

/**
 * ----------------------------------------------------------------------------
 * TYPE NAME
 * ----------------------------------------------------------------------------
 */
const getTypeDictionaryData = (typeName: string): TypeDictionaryEntry | undefined => {
  return (
    BASE_TYPE_DICTIONARY[typeName] ?? ProjectService.getProjectTypeDictionary()[typeName]
  );
};

const expandTypeName = (type: string) => {
  const typeName = PREFIX_TYPENAME.exec(type)![1];

  const typeEntry = getTypeDictionaryData(typeName);
  if (!typeEntry) return '';

  return typeEntry.jsonid || typeEntry.metaid;
};

const getCongId = (type: string) => {
  const typeName = PREFIX_TYPENAME.exec(type)![1];

  const typeEntry = getTypeDictionaryData(typeName);
  return typeEntry?.congid ?? '';
};

/**
 * ----------------------------------------------------------------------------
 * TYPE MAP
 *
 * We form port types by setting one or more port types (in response to relinking
 * for example), then creating a type map that maps type parameters to context-free
 * types, then applying this type map to each port pid to generate its type.
 * ----------------------------------------------------------------------------
 */

export const createNodeTypeMap = (data: go.ObjectData) => {
  const typeMap: Record<string, string> = {};

  PORT_TYPES.forEach((ports) => {
    if (data[ports]) addPortsToTypeMap(data[ports], typeMap);
  });

  return typeMap;
};

/**
 * Produces a context-free map iff port types are context-free,
 * as they must be. Type parameters only occur within port.pid and
 * types derived directly from it.
 */
const addPortsToTypeMap = (ports: Port[], typeMap: Record<string, string>) => {
  ports.forEach(({ pid, type }) => {
    const base = getContentTypeFromTypedId(pid);
    const join = joinType(getContextFreeType(base), type);
    const newt = isWellDefined(join) ? join : type;

    addTypeToTypeMap(base, newt, typeMap);
  });

  return typeMap;
};

/**
 * Walk base and term char-by-char, verifying similarity as we go.
 * When we reach a a ?n in base, extract the corresponding term in 'term'
 * and add ?n -> extracted to the typeMap if n is non-empty.
 *
 * Assumption: base and term have a well-defined join, which basically means
 * that they are compatible structurally.
 *
 * @param base Content type
 * @param term Derived from base by applying term substitutions for ?n terms within base.
 * @param typeMap
 */
export const addTypeToTypeMap = (
  base: string,
  term: string,
  typeMap: Record<string, string>
) => {
  const n = base.length;
  let i = 0;
  let j = 0;

  while (i < n) {
    let baseChar = base.charAt(i);
    let termChar = term.charAt(j);

    // skip slot names
    if (baseChar === '{') {
      const substring = base.substring(i);
      const m = PREFIX_SLOTNAME.exec(substring)![0];
      i += m.length;
      baseChar = base.charAt(i);
    }

    if (termChar === '{') {
      const substring = term.substring(j);
      const m = PREFIX_SLOTNAME.exec(substring)![0];
      j += m.length;
      termChar = term.charAt(j);
    }

    // skip qualifiers -- type maps do not contain qualifiers in outermost position
    if (baseChar === 'q' || baseChar === 'k') {
      i++;
      baseChar = base.charAt(i);
    }

    if (termChar === 'q' || termChar === 'k') {
      j++;
      termChar = term.charAt(j);
    }

    if (baseChar === '?') {
      const baseSubstring = base.substring(i);
      const m = /^\?[0-9]*/.exec(baseSubstring)![0]; // necessarily matches
      const k = extractType(term, j);

      // Only if digit string is non-empty
      if (m.length > 1) {
        typeMap[m] = term.substring(j, k);
      }

      i += m.length;
      j = k;
    } else {
      if (baseChar !== termChar)
        throw new Error(
          `internal: input correspondence failure in addTypeToTypeMap: ${baseChar} <-> ${termChar}`
        );
      i++;
      j++;
    }
  }
};

/**
 *
 *  use typemap to rewrite type.  results in a context-free term.
 *  this is the canonical way to turn a term with type parameters
 *  into one that is free of type parameters.  it does not depend
 *  on whether a particular type  parameter is defined in typemap
 *  or not.
 *
 */
export const mapType = (typeMap: Record<string, string>, type: string) => {
  let rewrittenType = type;

  Object.keys(typeMap).forEach((key) => {
    rewrittenType = rewrittenType.replaceAll(key, typeMap[key]);
  });

  // the result must be context-free even if type contains
  // type parameters that are not bound in typeMap.
  rewrittenType = getContextFreeType(rewrittenType);

  return rewrittenType;
};

/**
 * ----------------------------------------------------------------------------
 * DECOMPOSE ENCODED TYPES
 * ----------------------------------------------------------------------------
 */

/**
 * Decompose an encoded type into types and names. The type is either a
 * tuple type ('T'), in which case it may have {}-wrapped slot names,
 * or not, in which case the result will be a single type with no name.
 *
 * We represent no slot name as an empty string. This is for use when
 * changing the type of a tuple or detuple node.
 *
 * When shouldFlatten is true, this function decomposes through all outer
 * tuple layers, for pack and unpack special nodes.
 */
export const decomposeJSONType = (s: string, shouldFlatten: boolean) => {
  const t = expandType(s);

  if (t.charAt(0) === 'T') {
    const types: string[] = [];
    const names: string[] = [];

    const m = /^T([0-9]+)/.exec(t)![1];
    const n = parseInt(m, 10);

    let j = 1 + m.length;
    for (let i = 0; i < n; i++) {
      let name = '';
      const matchResults = PREFIX_SLOTNAME.exec(t.substring(j));

      if (matchResults) {
        j += matchResults[0].length;
        // eslint-disable-next-line prefer-destructuring
        name = matchResults[1];
      }

      const k = extractType(t, j);
      const type = t.substring(j, k);

      j = k;

      if (shouldFlatten) {
        // Call recursively to decompose the slot type
        const { types: recTypes, names: recNames } = decomposeJSONType(type, true);

        if (recTypes.length !== recNames.length)
          throw new Error(
            'internal: recTypes and recNames lengths disagree in decomposeJSONtype'
          );

        if (recTypes.length > 1) {
          const prefix = `${name ?? i.toString()}.`;
          const prefixedRecNames = recNames.map((recName) => prefix + recName);
          types.push(...recTypes);
          names.push(...prefixedRecNames);
        } else {
          types.push(type);
          names.push(name);
        }
      } else {
        types.push(type);
        names.push(name);
      }
    }

    if (types.length !== names.length)
      throw new Error('internal: types and names lengths disagree in decomposeJSONtype');

    return { types, names };
  }

  // Return a single unnamed slot, so that tuple/detuple
  // nodes get an intuitive treatment in the degenerate case.
  return { types: [s], names: [''] };
};

/**
 * Extracts an entire type from within an encoded type ID.
 *
 * @param typeId Encoded type ID
 * @param index
 * @returns Position just past the end of the extracted type
 */
export const extractType = (typeId: string, index: number): number => {
  switch (typeId.charAt(index)) {
    case 'q':
    case 'k':
    case 'V':
    case 'L':
    case 'R':
    case 'S':
    case 'Z':
    case 'W':
    case 'n':
    case 'o':
    case 'X':
    case 'Y':
    case 'J':
    case 'K':
    case 'P':
    case 'Q': {
      return extractType(typeId, index + 1);
    }

    case 'N':
    case 'M':
    case 'F': {
      return extractType(typeId, extractType(typeId, index + 1));
    }

    case 'A': {
      return extractType(typeId, index + 2); // skip dims
    }

    case 'T': {
      const substring = typeId.substring(index);
      const m = /^T([0-9]+)/.exec(substring)![1];
      const n = parseInt(m, 10);
      let j = index + 1 + m.length;

      for (let i = 0; i < n; ++i) {
        j = extractType(typeId, j);
      }
      return j;
    }

    case 'm': {
      const substring = typeId.substring(index);
      const m = /^m([0-9]+)/.exec(substring)![1];
      return index + 1 + m.length;
    }

    case '<': {
      // consume up to and including the closing >
      const substring = typeId.substring(index);
      const m = PREFIX_TYPENAME.exec(substring)![1];
      return index + m.length + 2;
    }

    case '(': {
      // consume up to and including the closing )
      const substring = typeId.substring(index);
      const m = PREFIX_TEXTTYPE.exec(substring)![1];
      return index + m.length + 2;
    }

    case '{': {
      // skip over the {...} pair and consume the sequel type
      const substring = typeId.substring(index);
      const m = PREFIX_SLOTNAME.exec(substring)![1];
      return extractType(typeId, index + m.length + 2);
    }

    case '?': {
      const substring = typeId.substring(index);
      const m = /^\?[0-9]*/.exec(substring)![0]; // necessarily matches
      return index + m.length;
    }

    default: {
      // simple type described by a single letter
      return index + 1;
    }
  }
};

/**
 * ----------------------------------------------------------------------------
 * Join types
 * ----------------------------------------------------------------------------
 */
export const isWellDefined = (id: string) => id.indexOf('&') < 0;

export const joinType = (a: string, b: string) => {
  // a and b are context-free
  if (ALL_TYPE_PARAMS.test(a))
    throw new Error(`internal: type param in argument a to joinType: ${a} ${b}`);
  if (ALL_TYPE_PARAMS.test(b))
    throw new Error(`internal: type param in argument b to joinType: ${a} ${b}`);

  if (a === b) return a;

  if (a.charAt(0) === '?') return b;

  if (b.charAt(0) === '?') return a;

  if (!isWellDefined(a) || !isWellDefined(b)) {
    // One of a or b has alternation (&). Produce a '&' form with no
    // duplicates. This treatment makes it irrelevant what order the '&'
    // alternatives have in a and b.
    const U = new Set<string>();
    a.split('&').forEach((s) => U.add(s));
    b.split('&').forEach((s) => U.add(s));

    const A: string[] = [];
    U.forEach((s) => A.push(s));

    return A.join('&');
  }

  const joinBuf: string[] = [];

  // joinType0 returns i and j reflective of what it consumed of a and b.
  const [i, j] = joinType0(a, b, 0, 0, joinBuf);

  if (i < 0 || j < 0) {
    // Return a string indicating the overconstraint. A simple concatenation
    // is ok here because we treated the case in which a or b contains
    // a '&' above.
    return `${a}&${b}`;
  }

  // If the routine succeeds (join exists), it should consume inputs
  // a and b in their entirety. This is a strong integrity check of
  // joinType0 and the well-formedness of the type inputs.
  if (i !== a.length || j !== b.length)
    throw new Error(
      `unconsumed input in joinType ${a} ${b} ${i} ${j} ${a.length} ${b.length}`
    );

  return joinBuf.join('');
};

export const joinType0 = (
  a: string,
  b: string,
  aIndex: number,
  bIndex: number,
  joinBuf: string[]
): [number, number] => {
  // Treat the cases in which the leading character of a or b is {, ?, or <.

  let i = aIndex;
  let j = bIndex;

  // If there are slot names {..} leading a or b, skip them. They have no
  // impact on the join operation, and the type terms that follow them are
  // treated as though the slot names were not present.

  if (a.charAt(i) === '{') {
    const s = a.substring(i);
    const m = PREFIX_SLOTNAME.exec(s)![0];
    i += m.length;
  }

  if (b.charAt(j) === '{') {
    const s = b.substring(j);
    const m = PREFIX_SLOTNAME.exec(s)![0];
    j += m.length;
  }

  // Having skipped any slot names, extract the two leading characters
  const charA = a.charAt(i);
  const charB = b.charAt(j);

  // treat the case of type parameters

  if (charA === '?') {
    const j1 = extractType(b, j);
    joinBuf.push(b.substring(j, j1));
    return [i + 1, j1];
  }

  if (charB === '?') {
    const i1 = extractType(a, i);
    joinBuf.push(a.substring(i, i1));
    return [i1, j + 1];
  }

  // When joinType encounters an embedded <typename> in one input, it expands to
  // its congid, joins with the corresponding term in the other input, and if
  // the result is well-defined, returns <typename> as the result. This gets us
  // the precision of congid and the expressive intuition of typename.

  if (charA === '<') {
    const i1 = extractType(a, i);
    const j1 = extractType(b, j);
    const typeid = a.substring(i, i1);
    const image = b.substring(j, j1);
    const congid = getCongId(typeid);
    const join = joinType(congid, image);
    // congId projects all object types to 'O', meaning join of object
    // types always succeeds over congruence ids.  for our purposes we
    // require strong equality of object types.  we use type name equality
    // as a proxy for object type equality, even though it is too strong.
    const joinSucceeds = isWellDefined(join) && (join !== 'O' || typeid === image);
    joinBuf.push(joinSucceeds ? typeid : `${typeid}&${image}`);
    return [i1, j1];
  }

  if (charB === '<') {
    const i1 = extractType(a, i);
    const j1 = extractType(b, j);
    const typeid = b.substring(j, j1);
    const image = a.substring(i, i1);
    const congid = getCongId(b.substring(j));
    const join = joinType(congid, image);
    // see comment above about congId and object types
    const joinSucceeds = isWellDefined(join) && (join !== 'O' || typeid === image);
    joinBuf.push(joinSucceeds ? typeid : `${typeid}&${image}`);
    return [i1, j1];
  }

  if (charA === '(' && charB === '(') {
    const i1 = extractType(a, i);
    const j1 = extractType(b, j);
    const s1 = a.substring(i, i1); // surrounded by ()'s
    const s2 = b.substring(j, j1); // surrounded by ()'s
    const t1 = PREFIX_TEXTTYPE.exec(s1)![1];
    const t2 = PREFIX_TEXTTYPE.exec(s2)![1];
    // the join of text types is the more specific of the two types
    joinBuf.push(t2.indexOf(t1) === 0 ? s2 : t1.indexOf(t2) === 0 ? s1 : `${s1}&${s2}`);
    return [i1, j1];
  }

  // The sequel can succeed only where the leading characters of a and b are equal.
  if (charA !== charB) return [-1, -1];

  // Emit the leading type character
  joinBuf.push(charA);

  // charA === charB
  switch (charA) {
    case 'q':
    case 'k':
    case 'V':
    case 'L':
    case 'R':
    case 'S':
    case 'Z':
    case 'W':
    case 'n':
    case 'o':
    case 'X':
    case 'Y':
    case 'J':
    case 'K':
    case 'P':
    case 'Q': {
      return joinType0(a, b, i + 1, j + 1, joinBuf);
    }

    case 'N':
    case 'M':
    case 'F': {
      const [i1, j1] = joinType0(a, b, i + 1, j + 1, joinBuf);
      return i1 < 0 || j1 < 0 ? [-1, -1] : joinType0(a, b, i1, j1, joinBuf);
    }

    case 'A': {
      if (a[i + 1] !== b[j + 1]) return [-1, -1];

      joinBuf.push(a.charAt(i + 1)); // dims

      return joinType0(a, b, i + 2, j + 2, joinBuf);
    }

    case 'T': {
      const mA = PREFIX_DECINT.exec(a.substring(i + 1))![0];
      const mB = PREFIX_DECINT.exec(b.substring(j + 1))![0];

      if (mA !== mB) return [-1, -1];

      joinBuf.push(mA);

      i = i + 1 + mA.length;
      j = j + 1 + mB.length;

      const n = parseInt(mA, 10);
      for (let k = 0; k < n; ++k) {
        [i, j] = joinType0(a, b, i, j, joinBuf);
        if (i < 0 || j < 0) return [-1, -1]; // return early if a slot join fails
      }
      return [i, j];
    }

    case 'm': {
      // type memoization.  require that these be identical.

      const mA = PREFIX_DECINT.exec(a.substring(i + 1))![0];
      const mB = PREFIX_DECINT.exec(b.substring(j + 1))![0];

      if (mA !== mB) return [-1, -1];

      joinBuf.push(mA);

      i = i + 1 + mA.length;
      j = j + 1 + mB.length;

      return [i, j];
    }

    case '{':
    case '?':
    case '<':
    case '(': {
      // We treated slot names, type params, and type names above; this should not happen
      throw new Error(`unexpected meta character in joinType0: ${charA}`);
    }

    default: {
      // Simple type described by a single letter
      return [i + 1, j + 1];
    }
  }
};
