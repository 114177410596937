export const Tabs = {
  variants: {
    highlight: ({ colorMode }: { colorMode: string }) => {
      const isDarkMode = colorMode === 'dark';

      return {
        tab: {
          color: isDarkMode ? 'white' : 'gray.400',
          _selected: {
            backgroundColor: isDarkMode ? 'blue.200' : 'blue.500',
            color: isDarkMode ? 'gray.700' : 'white',
            outline: 'none',
          },
        },
        tablist: {
          borderBottom: '4px solid',
          borderBottomColor: isDarkMode ? 'blue.200' : 'blue.500',
        },
      };
    },
  },
};
