export type LinkedPanel = {
  name: string;
  next?: LinkedPanel | undefined;
  prev?: LinkedPanel | undefined;
};

export type PanelHistoryAction =
  | { type: 'SET_ACTIVE'; name: string }
  | { type: 'NEXT' }
  | { type: 'PREV' }
  | { type: 'DELETE'; name: string }
  | { type: 'RENAME'; name: string; newName: string }
  | { type: 'RESET' };

type PanelHistoryState = {
  head?: LinkedPanel;
  tail?: LinkedPanel;
  active?: LinkedPanel;
};

export const panelHistoryReducer = (
  state: PanelHistoryState,
  action: PanelHistoryAction
) => {
  switch (action.type) {
    case 'SET_ACTIVE': {
      const { name } = action;

      // check for duplicate active panel selection
      if (name === state.active?.name) return state;

      const clonedState = cloneHistory(state);
      let { tail, active } = clonedState;

      // initialize list if undefined
      if (!clonedState.head || !tail || !active) {
        const panel: LinkedPanel = { name };
        return { head: panel, tail: panel, active: panel };
      }

      // appends to the list from the active panel, not the tail.
      const panel: LinkedPanel = { name, prev: active };
      active.next = panel;
      tail = panel;
      active = panel;
      return { ...clonedState, tail, active };
    }
    case 'PREV': {
      const clonedState = cloneHistory(state);
      let { active } = clonedState;

      if (active?.prev?.name !== undefined) active = active.prev;

      return { ...clonedState, active };
    }
    case 'NEXT': {
      const clonedState = cloneHistory(state);
      let { active } = clonedState;

      if (active?.next?.name !== undefined) active = active.next;

      return { ...clonedState, active };
    }
    case 'DELETE': {
      const clonedState = cloneHistory(state);
      let { head, tail, active } = clonedState;

      const { name } = action;

      let current = head;
      while (current !== undefined) {
        if (current.name === name) {
          // handle deletion of active panel
          if (active?.name === name) {
            active = active.prev ?? active.next;
          }
          // current is head
          if (!current.prev) {
            head = head?.next;
            if (head) head.prev = undefined;
          }
          // current is tail
          if (!current.next) {
            tail = tail?.prev;
            if (tail) tail.next = undefined;
          }
          // current not tail or head
          if (current.prev && current.next) {
            current.prev.next = current.next;
            current.next.prev = current.prev;
          }
        }
        if (current.next === undefined) {
          break;
        }
        current = current.next;
      }

      return { head, tail, active };
    }
    case 'RENAME': {
      const clonedState = cloneHistory(state);
      const { head } = clonedState;

      const { name: currentName, newName } = action;

      let current = head;
      while (current !== undefined) {
        if (current.name === currentName) {
          current.name = newName;
        }

        if (current.next === undefined) break;

        current = current.next;
      }
      return clonedState;
    }
    // resetting does not automatically reset the active panel
    case 'RESET': {
      return { head: undefined, tail: undefined, active: undefined };
    }
    default: {
      return state;
    }
  }
};

// returns a deep copy of panelHistory
const cloneHistory = (history: PanelHistoryState) => {
  const { head, tail, active } = history;
  if (!head || !tail || !active) return history;

  const cloneHead: LinkedPanel = { name: head.name };
  const newHistory: PanelHistoryState = {
    head: cloneHead,
    tail: cloneHead,
    active: cloneHead,
  };

  let current = head;
  while (current !== undefined) {
    if (current.next === undefined) break;
    current = current.next;

    const panel: LinkedPanel = { name: current.name, prev: newHistory.tail };
    newHistory.tail!.next = panel;
    newHistory.tail = panel;

    if (current === active) newHistory.active = panel;
  }
  return newHistory;
};
