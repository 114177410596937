import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUserContext } from 'context/useUserContext';
import {
  cancelConnectedModeRegistration,
  createStack,
  deleteStack,
  getConnectedModeData,
  GetConnectedModeDataResponse,
  getStacksData,
  GetStacksDataPayload,
  registerConnectedMode,
  updateStack,
  validateTemplateStack,
} from 'services/api/ConnectedMode';
import { onRequestError } from 'services/api/utils';

export const CONNECTED_MODE = 'connectedMode' as const;

export const useConnectedModeQuery = () => {
  return useQuery({
    queryKey: [CONNECTED_MODE],
    queryFn: getConnectedModeData,
    refetchInterval: 15000,
    retry: 3,
  });
};

export const useGetStacksDataQuery = (data: GetStacksDataPayload) => {
  return useQuery({
    queryKey: [CONNECTED_MODE, data],
    queryFn: () => getStacksData(data),
    refetchInterval: 15000,
    retry: 3,
  });
};

export const useRegisterConnectedModeMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: registerConnectedMode,
    onSuccess: (registerLink, { accountId }) => {
      queryClient.invalidateQueries([CONNECTED_MODE]);
      return {
        registerLink,
        accountId,
      };
    },
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const useCancelConnectedModeRegistrationMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: cancelConnectedModeRegistration,
    onMutate: async (accountIdToDelete: string) => {
      await queryClient.cancelQueries({ queryKey: [CONNECTED_MODE] });
      const prev = queryClient.getQueryData<GetConnectedModeDataResponse>([
        CONNECTED_MODE,
      ]);
      queryClient.setQueryData<GetConnectedModeDataResponse>([CONNECTED_MODE], (old) =>
        old ? old.filter(({ accountId }) => accountId !== accountIdToDelete) : old
      );
      return { prev };
    },
    onError: (e, _, context) => {
      queryClient.setQueryData([CONNECTED_MODE], context?.prev);
      onRequestError(setIsAuthed, e);
    },
    onSettled: () => {
      queryClient.invalidateQueries([CONNECTED_MODE]);
    },
  });
};

export const useValidateStackMutation = () => {
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: validateTemplateStack,
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const useCreateStackMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: createStack,
    onSuccess: () => {
      queryClient.invalidateQueries([CONNECTED_MODE]);
    },
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const useUpdateStackMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: updateStack,
    onSuccess: () => {
      queryClient.invalidateQueries([CONNECTED_MODE]);
    },
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};

export const useDeleteStackMutation = () => {
  const queryClient = useQueryClient();
  const { setIsAuthed } = useUserContext();

  return useMutation({
    mutationFn: deleteStack,
    onSuccess: () => {
      queryClient.invalidateQueries([CONNECTED_MODE]);
    },
    onError: (e) => {
      onRequestError(setIsAuthed, e);
    },
  });
};
