import * as go from 'gojs';
import {
  RESOURCE_DICTIONARY,
  SPECIAL_NODE_DICTIONARY,
} from 'utils/transparency/dictionaries';
import { encodeType } from 'utils/transparency/encode';
// eslint-disable-next-line import/no-cycle
import {
  getContentType,
  getContextFreeTypeFromTypedId,
  isInPortType,
  isOutPortType,
  isBasicCategory,
  isSpecialCategory,
  isResourceCategory,
  isPortType,
} from 'utils/transparency/types';
import { Node, Port } from 'types/transparency';

// eslint-disable-next-line import/no-cycle
import { roundAndStringifyPoint } from './templates';
import { TRANSACTIONS } from './const';
// eslint-disable-next-line import/no-cycle
import { addTemplateLazily } from './template-map';

/**
 * Gets the point on the diagram corresponding to the latest input.
 * If the diagram has no lastInput event, return the top-left point
 * of the diagram.
 *
 * @param diagram
 * @returns GoJS Point
 */
const getLastInputPoint = (diagram: go.Diagram) => {
  const {
    event,
    documentPoint: { x, y },
  } = diagram.lastInput;

  // If the last input event is null and its x/y coordinates are at origin,
  // the user has not interacted with the diagram yet, and the function
  // should return the top-left corner of the diagram.
  const shouldUseTopLeft = event === null && x === 0 && y === 0;

  const lastInputPoint = shouldUseTopLeft
    ? diagram.viewportBounds.position
    : diagram.lastInput.documentPoint.copy();

  return lastInputPoint;
};

/**
 * Add an (x, y) offset to the last input point on the diagram,
 * and save this computed point as the last input event's document point.
 *
 * @param diagram
 * @param x
 * @param y
 */
export const getLastInputPointAndAddOffset = (diagram: go.Diagram, x = 10, y = 10) => {
  const point = getLastInputPoint(diagram);
  point.offset(x, y);

  diagram.lastInput.documentPoint = point;

  return point;
};

export const addNode = (diagram: go.Diagram, category: string, newPoint?: go.Point) => {
  // Use newPoint if it is defined; compute point otherwise
  const point = newPoint ?? getLastInputPointAndAddOffset(diagram);
  const stringifiedPoint = roundAndStringifyPoint(point);

  // add category to node template maps if it is missing
  addTemplateLazily(category);

  diagram.startTransaction(TRANSACTIONS.addNode);

  if (isBasicCategory(category)) {
    addBasicNode(diagram, category, stringifiedPoint);
  } else if (isSpecialCategory(category)) {
    addSpecialNode(diagram, category, stringifiedPoint);
  } else if (isResourceCategory(category)) {
    addResourceNode(diagram, category, stringifiedPoint);
  }

  return diagram.commitTransaction(TRANSACTIONS.addNode);
};

const addBasicNode = (diagram: go.Diagram, category: string, point: string) => {
  diagram.model.addNodeData({ cat: category, loc: point });
};

/**
 * All special nodes have inports, outports, and parports in the model data,
 * where they have a corresponding parameter type. Like basic nodes, special nodes
 * use a params property in the dictionary to specify their ports and parameters,
 * which we use to create the port arrays.
 */
const addSpecialNode = (
  diagram: go.Diagram,
  category: string,
  point: string,
  additionalData?: Pick<Node, 'name'>
) => {
  const inports: Port[] = [];
  const outports: Port[] = [];
  const parports: Port[] = [];
  const argports: Port[] = [];

  // If there is no such special node category, we allow it to be
  // added anyway, and it will get a default visual treatment that
  // will indicate a problem to the user. That would be an internal
  // error of course, but we must still indicate it to the user.

  if (category in SPECIAL_NODE_DICTIONARY) {
    // Categories in SPECIAL_NODE_DICTIONARY include inlet, outlet, and call nodes.
    // They can have a bigger variety of port types than basic nodes.
    const metadata = SPECIAL_NODE_DICTIONARY[category];

    metadata.params?.forEach((param) => {
      const contextFreeType = getContextFreeTypeFromTypedId(param);
      const contentType = getContentType(contextFreeType);

      const isWire = contextFreeType.charAt(0) === 'W';

      if (isWire) {
        // Wire types are expected for inlet and outlet special nodes
        if (/outlet$/.test(category)) {
          inports.push({ pid: param, type: contentType });
        } else if (/inlet$/.test(category)) {
          outports.push({ pid: param, type: contentType });
        }
      } else if (isInPortType(contextFreeType)) {
        inports.push({ pid: param, type: contentType });
      } else if (isOutPortType(contextFreeType)) {
        outports.push({ pid: param, type: contentType });
      } else {
        if (isPortType(contextFreeType))
          throw new Error(
            `internal: unexpected parameter port type in addSpecialNode: ${category} ${param} ${contextFreeType}`
          );
        parports.push({ pid: param, type: contextFreeType });
      }
    });

    metadata.argports?.forEach((param) => {
      const contextFreeType = getContextFreeTypeFromTypedId(param);
      if (isPortType(contextFreeType))
        throw new Error(
          `internal: unexpected argument port type in addSpecialNode: ${category} ${param} ${contextFreeType}`
        );
      argports.push({ pid: param, type: contextFreeType });
    });
  }

  const data: Omit<Node, 'key'> = {
    cat: category,
    loc: point,
    inports,
    outports,
    parports,
    ...(argports.length > 0 && { argports }),
    ...(additionalData !== undefined && additionalData),
  };

  diagram.model.addNodeData(data);
};

/**
 * Resource nodes have something in common with both basic and special nodes.
 * Their behavior is specified completely in the cfn dictionary.
 */
const addResourceNode = (diagram: go.Diagram, category: string, point: string) => {
  const inports: Port[] = [];
  const outports: Port[] = [];
  const parports: Port[] = [];
  const argports: Port[] = [];

  if (category in RESOURCE_DICTIONARY) {
    const metadata = RESOURCE_DICTIONARY[category];

    try {
      switch (metadata.role) {
        case 'resource_def': {
          // Resource definition nodes have 2 param ports for user logical id and resource spec,
          // and one output port for final logical id. All of the types entering and exiting
          // the resource definition node are CFN types.
          if (metadata.spec_type === undefined) throw new Error('Missing spec_type');

          console.assert(!('params' in metadata));
          console.assert(!('argports' in metadata));

          parports.push({ pid: 'ID$(cfnId)', type: '(cfnId)' }); // a text type
          parports.push({ pid: 'Specification$(cfnSpec)', type: '(cfnSpec)' }); // a text type
          argports.push({ pid: 'LogicalID$(cfnIdLogical)', type: '(cfnIdLogical)' }); // a text type

          break;
        }
        case 'resource_ref': {
          // resource reference nodes have 1 param port for logical id
          // and 2 output ports for resource outputs (static and dynamic)
          if (metadata.output_type === undefined) throw new Error('Missing output_type');

          console.assert(!('params' in metadata));
          console.assert(!('argports' in metadata));

          const outputType = metadata.output_type;
          const encodedOutput = encodeType(outputType);

          parports.push({ pid: 'LogicalID$(cfnIdLogical)', type: '(cfnIdLogical)' }); // a text type
          outports.push({ pid: `Resource$Y${encodedOutput}`, type: encodedOutput }); // a Transparency type
          argports.push({ pid: `resource$${encodedOutput}`, type: encodedOutput }); // a Transparency type

          break;
        }
        case 'resource_special_def':
        case 'resource_special_ref': {
          metadata.params?.forEach(({ type, name }) => {
            const encodedType = encodeType(type);
            parports.push({ pid: `${name}$${encodedType}`, type: encodedType });
          });

          metadata.outports?.forEach(({ type, name }) => {
            const encodedType = encodeType(type);
            outports.push({ pid: `${name}$Y${encodedType}`, type: encodedType });
          });

          metadata.argports?.forEach(({ type, name }) => {
            const encodedType = encodeType(type);
            argports.push({ pid: `${name}$${encodedType}`, type: encodedType });
          });

          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error(
        `Resource definition for category '${category}' is malformed: ${error}`
      );
    }

    const data = {
      cat: category,
      loc: point,
      inports,
      outports,
      parports,
      ...(argports.length > 0 && { argports }),
    };

    diagram.model.addNodeData(data);
  }
};

/**
 * Adds a named call node to the diagram.
 * @param diagram
 * @param calleeName
 */
export const addCallNode = (
  diagram: go.Diagram,
  circuitType: string,
  calleeName: string
) => {
  if (!['circuit', 'infrastructure'].includes(circuitType)) {
    throw new Error('Invalid circuit type');
  }

  const point = getLastInputPointAndAddOffset(diagram);
  const stringifiedPoint = roundAndStringifyPoint(point);

  diagram.startTransaction('add call');

  addSpecialNode(diagram, circuitType, stringifiedPoint, { name: calleeName });

  diagram.commitTransaction('add call');
};
