import { ColorMode } from '@chakra-ui/react';

import { theme } from 'theme';
import { MessageType, PanelCategory } from 'types/transparency';

// Give a deterministic result (same color assignments to same string every time).

// Separate type ids that are very near in spelling by big distance in rgb.
export const djb2color = (str: string, begin: number, colorMode: ColorMode) => {
  const len = str.length;
  if (len === begin) return 'gray';

  let hash = 5381;
  for (let i = begin; i < len; i++) {
    // using '-' instead of '+' below gives nicer colors.  lol.
    hash = (hash << 5) - hash + str.charCodeAt(i);
  }

  // the hash code is  only half the battle.  we must try  to send values that
  // are proximate in hash space, to colors that are distant in rgb.  we would
  // especially like that adjacent integer hash values result in different rgb
  // components being emphasized.  the output  is restricted to the 'web-safe'
  // colors, i.e., the colors in which  each component is restricted to 0..15,
  // as these colors are uniformly distributed.   we avoid colors that are too
  // dark, since we use a black background for the circuit editor.

  const x = (hash & 0xf00) >>> 8;
  const y = (hash & 0x0f0) >>> 4;
  const z = (hash & 0x00f) >>> 0;

  let r: number;
  let g: number;
  let b: number;

  switch ((hash & 0x7fffffff) % 6) {
    case 0:
      r = x;
      b = y;
      g = z;
      break;

    case 1:
      r = x;
      g = y;
      b = z;
      break;

    case 2:
      b = x;
      r = y;
      g = z;
      break;

    case 3:
      b = x;
      g = y;
      r = z;
      break;

    case 4:
      g = x;
      b = y;
      r = z;
      break;

    case 5:
    default:
      g = x;
      r = y;
      b = z;
      break;
  }

  if (colorMode === 'dark') {
    // stay away from very dark colors
    r = Math.trunc(r * 0.75 + 4);
    g = Math.trunc(g * 0.75 + 4);
    b = Math.trunc(b * 0.75 + 4);
  } else {
    r = Math.trunc(r * 0.75);
    g = Math.trunc(g * 0.75);
    b = Math.trunc(b * 0.75);
  }

  const hexStrings = [r, g, b].map((val) => {
    const valString = val.toString(16);
    return valString.substring(valString.length - 1);
  });

  return `#${hexStrings.join('')}`;
};

export const getCircuitColor = (circuitType: PanelCategory): string => {
  switch (circuitType) {
    case 'infrastructure':
      return theme.colors.chakrablue[400];
    case 'circuit':
      return theme.colors.chakrateal[300];
    case 'text':
      return '#8080FF';
    case 'javascript':
      return '#f7df1e';
    case 'python':
      return '#306998';
    default:
      return 'white';
  }
};

const NODE_ERROR_STROKE = 'red';
const NODE_WARNING_STROKE = theme.colors.yellow;
const NODE_NOTICE_STROKE = theme.colors.teal;
export const getStrokeColorForMessage = (messageType: MessageType) => {
  switch (messageType) {
    case 'errors':
      return NODE_ERROR_STROKE;
    case 'warnings':
      return NODE_WARNING_STROKE;
    case 'notices':
      return NODE_NOTICE_STROKE;
    default:
      break;
  }
};
