import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useToast } from '@chakra-ui/react';

import { LoginRedirectModal } from 'components/LoginRedirectModal';
import { useToggle } from 'hooks/useToggle';
import { logInRequest, LoginRequestData, postLogout } from 'services/api/Identity';
import { ProjectService } from 'services/ProjectService';
import * as LocalStorage from 'services/storage/LocalStorage';
import { StorageServiceClass } from 'services/StorageService';
import { getCognitoAttribute } from 'services/TokenService';
import { EditorMode } from 'types';
import { getRegionFromLocalStorage } from 'utils/region';
import { isCoreographSite, isInFrame } from 'utils/url';
import { getErrorMessage, isForbiddenError } from 'utils/error';
import { SAVE_FAILED_TOAST } from 'theme/components/Toast';
import { useQueryClient } from '@tanstack/react-query';

export type UserContextValues = {
  editorMode: EditorMode;
  email?: string;
  region?: string;
  saveUserRegion: (region: string) => void;
  isLoggedIn: boolean;
  isAuthed: boolean;
  isCoreographIframe: boolean;
  signIn: (data: LoginRequestData, region: string) => Promise<void>;
  signOut: () => Promise<void>;
  setIsAuthed: Dispatch<SetStateAction<boolean>>;
  isLoginRedirectModalOpen: boolean;
  openLoginRedirectModal: () => void;
  closeLoginRedirectModal: () => void;
  handleTokenExpiration: (error: unknown) => void;
};

const UserContext = createContext<UserContextValues | undefined>(undefined);
UserContext.displayName = 'UserContext';

type UserContextProviderProps = {
  children: ReactNode;
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!LocalStorage.get('refreshToken'));
  const [isAuthed, setIsAuthed] = useState(true);
  const [email, setEmail] = useState<string | undefined>(getCognitoAttribute('email'));

  const [region, setRegion] = useState<string | undefined>(getRegionFromLocalStorage());
  const saveUserRegion = (userRegion: string) => {
    LocalStorage.set('region', userRegion);
    setRegion(userRegion);
  };

  const toast = useToast();

  const queryClient = useQueryClient();

  const [isLoginRedirectModalOpen, , openLoginRedirectModal, closeLoginRedirectModal] =
    useToggle();

  // Flag for whether the editor is iframed by a Coreograph/documentation site
  const isCoreographIframe = isInFrame() && isCoreographSite(document.referrer);

  const editorMode = 'disconnected';

  const signIn = useCallback(
    async (data: LoginRequestData, userRegion: string) => {
      await logInRequest(data, userRegion);
      saveUserRegion(userRegion);
      setIsLoggedIn(true);
      setIsAuthed(true);
      setEmail(data.username);
      queryClient.clear();
    },
    [queryClient]
  );

  const signOut = useCallback(async () => {
    queryClient.clear();
    if (!ProjectService.isEmpty()) {
      // Save current project to localStorage before going to login page.
      const currentProject = ProjectService.getProject();

      try {
        StorageServiceClass.saveProjectLocalStorage(currentProject);
      } catch (error: unknown) {
        toast({
          ...SAVE_FAILED_TOAST,
          description: getErrorMessage(error),
        });
      }
    }

    await postLogout();

    setIsLoggedIn(false);
  }, [toast, queryClient]);

  const handleTokenExpiration = useCallback(
    (error: unknown) => {
      if (isForbiddenError(error)) {
        setIsAuthed(false);
        openLoginRedirectModal();
      }
    },
    [openLoginRedirectModal, setIsAuthed]
  );

  const value: UserContextValues = useMemo(
    () => ({
      editorMode,
      email,
      region,
      saveUserRegion,
      isLoggedIn,
      isAuthed,
      isCoreographIframe,
      signIn,
      signOut,
      setIsAuthed,
      isLoginRedirectModalOpen,
      openLoginRedirectModal,
      closeLoginRedirectModal,
      handleTokenExpiration,
    }),
    [
      email,
      region,
      isLoggedIn,
      isAuthed,
      isCoreographIframe,
      signIn, // memoized
      signOut, // memoized
      isLoginRedirectModalOpen,
      openLoginRedirectModal, // memoized
      closeLoginRedirectModal, // memoized
      handleTokenExpiration, // memoized
    ]
  );

  return (
    <UserContext.Provider value={value}>
      {children}

      <LoginRedirectModal
        isOpen={isLoginRedirectModalOpen}
        onClose={closeLoginRedirectModal}
        signOut={signOut}
      />
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
};
